import { FormEvent, FormEventType } from './form.event';

export class AddFormEvent extends FormEvent {
  type: FormEventType = FormEventType.Completed;
  articleId: string;
  parentGroupId: string;
  selectedModifierItemMap: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
  selectedSubArticleMap: Map<number, Map<string, Map<string, boolean>>> = new Map<number, Map<string, Map<string, boolean>>>();
  quantity = 1;
}
