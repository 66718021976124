export class VoucherItemMembershipPriceApiModel {
  isAppliedOnOrder: boolean;

  membershipLevel: number;

  membershipCardTypeId: string;

  membershipItemPrice: number;

  membershipValue: number;
}
