import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrderStateService } from './order.state.service';
import { ErrorHelper, LoadingService } from '@dinein-lib/shared';
import { CateringAggregationBuilder, CateringAggregationViewModel, CateringModel, OrderDataProvider } from '@dinein-lib/restapi-plugin';
import { NavigationService } from './navigation/navigation.service';

export class CustomState {
  public constructor(public id: string) {}
  expand = false;
  //[key:string]: boolean
}

@Injectable({
  providedIn: 'root',
})
export class SearcherService {
  public properties: CustomState[] = [];
  private groups = new BehaviorSubject<CateringAggregationViewModel>(null);
  public groupsChanges = this.groups.asObservable();

  private query = new BehaviorSubject<string>(null);
  queryChanges = this.query.asObservable();

  constructor(
    private orderDataProvider: OrderDataProvider,
    private loadingService: LoadingService,
    private orderStateService: OrderStateService,
    private navigationService: NavigationService
  ) {
    //this.query.subscribe(this.recentSearches)
    (window as any).subject = this.groups;

    orderStateService.state$.subscribe((state) => {
      if (!state) {
        this.properties.forEach((p) => (p.expand = false));
      }
    });
  }

  search(query: string = null) {
    this.loadingService.showLoader();
    this.query.next(query);

    return this.loadData(query).subscribe({
      next: (model) => {
        if (model) {
          this.groups.next(model);
        }
      },
      error: (error) => {
        console.error(error);
        const code = ErrorHelper.getCode(error);
        if (code) {
          this.navigationService.goToMessagePage(code);
        }
      },
      complete: () => {
        this.loadingService.hideLoader();
      },
    });
  }

  getArticle(id: string) {
    return this.getOrderFb().pipe(
      map((catering: CateringModel) => {
        catering.articles = catering.articles.filter((o) => !id || o.id === id);
        return new CateringAggregationBuilder(catering).build();
      })
    );
  }

  private loadData(query: string = null): Observable<CateringAggregationViewModel> {
    return this.getOrderFb().pipe(
      map((catering: CateringModel) => {
        catering.articles = catering.articles.filter((o) => !query || !query.length || o.name.toLowerCase().includes(query.toLowerCase()));
        return new CateringAggregationBuilder(catering).build();
      })
    );
  }

  private getOrderFb() {
    const state = this.orderStateService.getState();
    return this.orderDataProvider.getOrderFb(state.cinemaId, state.orderId);
  }
}
