export class CardTypeBatchApiModel {
  id: string;

  name: string;

  graphic: string;

  unlimited: boolean;

  canBeIssued: boolean;

  paymentValue: number;
}
