import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS } from './injection.tokens';
import { IRestApiOptions } from './interfaces/restapi-options';
import { SocialLoginModule } from './modules/auth/sociallogin.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SocialLoginModule],
  exports: [SocialLoginModule],
})
export class ApiPluginModule {
  constructor(@Optional() @SkipSelf() parentModule: ApiPluginModule, private injector: Injector) {
    if (parentModule) {
      throw new Error('ApiPluginModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(options?: IRestApiOptions, providers: Provider[] = []): ModuleWithProviders<ApiPluginModule> {
    return {
      ngModule: ApiPluginModule,
      providers: [
        {
          provide: POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS,
          useValue: options,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true,
        },
        ...[providers],
      ],
    };
  }
}
