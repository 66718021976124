import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { tap, timer } from 'rxjs';
import { Router } from '@angular/router';
import { OrderStateService } from '../../services/order.state.service';
import { blockBack } from '../../app.functions';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { NavigationService } from '../../services/navigation/navigation.service';

@UntilDestroy()
@Component({
  template: '',
})
export class SimpleCountdownComponent implements OnInit {
  orderTtl: DateTime;
  orderTtlSeconds: number;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected orderStateService: OrderStateService,
    protected navigationService: NavigationService,
    protected router: Router
  ) {}

  ngOnInit(): void {
    timer(0, 1000)
      .pipe(
        tap(() => {
          this.setTime();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.orderStateService.state$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.orderTtl = s?.order?.status != 4 ? s?.order?.dateTimeToLive : null;
      this.setTime();
    });
  }

  setTime() {
    const seconds = this.orderTtl?.toSeconds() - DateTime.now().toSeconds();
    const ots = !isNaN(seconds) && seconds >= 0 ? seconds : null;
    this.orderTtlSeconds = ots;

    if (ots === null) {
      this.createNewOrder();
    }
  }

  createNewOrder() {
    this.orderStateService.cleanState(true);
    blockBack();

    this.navigationService.goToMessagePage(103);
  }
}
