import { AbstractViewModel } from '../../../abstract.view.model';
import { ScreeningItemMembershipPriceApiModel } from '../../../api-model/order/screening-item/screening-item-membership-price.api.model';

export class ScreeningItemMembershipPriceViewModel extends AbstractViewModel<ScreeningItemMembershipPriceApiModel> {
  public isAppliedOnOrder: boolean;
  public membershipLevel: number;
  public membershipCardTypeId: string;
  public membershipTicketPrice: number;
  public membershipPrice: number;

  constructor(apiModel: ScreeningItemMembershipPriceApiModel = new ScreeningItemMembershipPriceApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.isAppliedOnOrder = this.apiModel.isAppliedOnOrder;
    this.membershipLevel = this.apiModel.membershipLevel;
    this.membershipCardTypeId = this.apiModel.membershipCardTypeId;
    this.membershipTicketPrice = this.apiModel.membershipTicketPrice;
    this.membershipPrice = this.apiModel.membershipPrice;
  }

  toApiModel(): ScreeningItemMembershipPriceApiModel {
    return undefined;
  }
}
