import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { concat, filter, first, interval } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { MobileDeviceService } from './mobile-device.service';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AppInstallUpdateService {
  public dialogIsOpen = false;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    appRef: ApplicationRef,
    private readonly updates: SwUpdate,
    private notificationService: NotificationService,
    private mobileDeviceService: MobileDeviceService,
    private translateService: TranslateService
  ) {
    const checkNewVersionInterval = this.environment.app.checkNewVersionInterval;
    console.log('checkNewVersionInterval:', checkNewVersionInterval);
    if (checkNewVersionInterval > 0 && this.updates.isEnabled) {
      //this.appIsStable = appRef.isStable.pipe(first((isStable) => isStable === true));

      const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
      const checkInterval$ = interval(1000 * checkNewVersionInterval);
      const onceAppIsStable$ = concat(appIsStable$, checkInterval$);

      onceAppIsStable$.subscribe(() => {
        this.updates.checkForUpdate().then(() => console.log('checking for updates'));
      });
    }

    updates.unrecoverable.subscribe((event) => {
      this.promptUser(event.reason);
    });
  }

  init() {
    const showInstallPrompt = this.environment.app?.showInstallPrompt ?? false;
    console.log('showInstallPrompt:', showInstallPrompt);
    if (showInstallPrompt) {
      this.updates.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'NO_NEW_VERSION_DETECTED')).subscribe(() => {
        this.setAppInstallNotification();
      });
    }

    const showUpdatePrompt = this.environment.app?.showUpdatePrompt ?? false;
    console.log('showUpdatePrompt:', showUpdatePrompt);
    this.updates.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')).subscribe(() => {
      if (showUpdatePrompt) {
        this.promptUser();
      } else {
        this.updateApp();
      }
    });
  }

  setAppInstallNotification() {
    const isIos = this.mobileDeviceService.isIos();
    const isInStandaloneMode = this.mobileDeviceService.isInStandaloneMode();
    console.log('isIos:', isIos, 'isInStandaloneMode:', isInStandaloneMode);

    if (isIos && !isInStandaloneMode) {
      this.notificationService.showIosInstall();
    }
  }

  private promptUser(reason?: string): void {
    this.dialogIsOpen = true;
    this.notificationService.openDialog(reason, () => {
      this.updateApp();
      this.dialogIsOpen = false;
    });
  }

  private updateApp() {
    this.updates.activateUpdate().then(() => {
      //this.orderStateService.cleanStateAll();
      document.location.reload();
    });
  }
}
