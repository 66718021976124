import { AbstractViewModel } from '../../abstract.view.model';
import { SeatApiModel } from '../../api-model/screening-details/seat.api.model';

export class SeatViewModel extends AbstractViewModel<SeatApiModel> {
  id: string;
  rowSymbol: string;
  seatSymbol: string;

  constructor(apiModel: SeatApiModel = new SeatApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.rowSymbol = this.apiModel.rowSymbol;
    this.seatSymbol = this.apiModel.seatSymbol;
  }

  toApiModel(): SeatApiModel {
    return undefined;
  }
}
