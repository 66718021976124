import { Component, ChangeDetectorRef, OnInit, HostListener, OnDestroy, Inject } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '@dinein-lib/shared';
import { ENVIRONMENT_TOKEN } from './injection.tokens';
import { OrderStateService } from './services/order.state.service';
import { storageKey } from './app.consts';
import { DateTime } from 'luxon';
import { navbarScroll } from './app.functions';

@Component({
  template: '',
})
export class AppComponent implements OnInit, OnDestroy {
  env: any;
  title: string;
  currentApplicationVersion: string;

  mobileQuery: MediaQueryList;
  footerContent = '';
  currentYear = DateTime.local().year;

  orientationLandscape = false;
  private _mobileQueryListener: () => void;

  subscription = new Subscription();
  translationLoaded = false;

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    //console.log('window.orientation', window.orientation)
    this.orientationLandscape = window.orientation !== 0;
  }

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private translateService: TranslateService,
    private orderStateService: OrderStateService,
    private loadingService: LoadingService
  ) {
    this.env = environment;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    translateService.setDefaultLang(environment.globalize.language.default);
    const lang = sessionStorage.getItem(storageKey.lang);
    this.useLanguage(environment.globalize.language.default || lang);
    this.setPreferredTheme(environment.theme);

    this.title = environment.title;
    this.currentApplicationVersion = environment.appVersion;
  }

  ngOnInit() {
    this.subscription.add(
      this.orderStateService.orderExpired.subscribe((date) => {
        if (!date) {
          return;
        }

        this.loadingService.hideLoader();
      })
    );

    this.orderStateService.checkOrderIsExpired();
    this.orderStateService.retrieveUserToken();

    window.addEventListener('scroll', function () {
      navbarScroll();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async useLanguage(locale: string) {
    console.log('#useLanguage:', locale);
    // await the http request for the translation file
    await this.translateService
      .use(locale)
      .toPromise()
      .then(() => {
        sessionStorage.setItem(storageKey.lang, locale);
      });
    this.translationLoaded = true;
  }

  setPreferredTheme(envTheme) {
    const theme = sessionStorage.getItem(storageKey.theme) ?? envTheme;
    const body = document.body;
    if (!body.classList.contains(theme)) {
      body.classList.add(theme);
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
