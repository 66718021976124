import { Injectable } from '@angular/core';
import { Observable, iif, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { OrderHttpService } from '../http/order.http.service';
import { OrderFbItemHttpService } from '../http/order-fbitem.http.service';
import { OrderViewModel } from '../models/view-model/order/order.view.model';
import { FbItemViewModel } from '../models/view-model/order/fb-item/fb-item.view.model';
import { CateringModel } from '../models/catering.model';
import { forkJoin } from 'rxjs';
import { OrderPaymentRequestModel } from '../models/order-payment.request.model';
import { PaymentViewModel } from '../models/view-model/sales-document/payment.view.model';
import { PaymentMethodViewModel } from '../models/view-model/order/payment-method/payment-method.view.model';
import { PaymentProviderConfigRequestModel } from '../models/request/payment-provider-config.request.model';
import { PaymentConfigViewModel } from '../models/view-model/payment/config/payment.config.view.model';
import { PaymentProviderPayMethodRequestModel } from '../models/request/payment-provider-pay-method.request.model';
import { PaymentMethodResponseModel } from '../models/response/payment-method.response.model';
import { PaymentMethodApiModel } from '../models/api-model/order/payment-method/payment-method.api.model';
import { UpdateOrderRequest } from '../models/update-order-request.model';
import { PaymentProviderPayMethodViewModel } from '../models/view-model/payment-provider-pay-method.view.model';
import { PaymentProviderPayMethodApiModel } from '../models/api-model/payment-provider-pay-method.response.model';
import { storageKey } from '../globals';
import { SeatRequestRequestModel } from '../models/api-model/seat/seat.request.model';

@Injectable({
  providedIn: 'root',
})
export class OrderDataProvider {
  constructor(private httpService: OrderHttpService, private orderFbItemHttpService: OrderFbItemHttpService) {}

  getOrder(cinemaId: string, orderId: string): Observable<OrderViewModel> {
    return this.httpService.getOrder(cinemaId, orderId).pipe(map((apiModel) => new OrderViewModel(apiModel)));
  }

  postOrderClose(cinemaId: string, orderId: string) {
    return this.httpService.postOrderClose(cinemaId, orderId);
  }

  postOrder(cinemaId: string, sourceOrderId: string = null): Observable<OrderViewModel> {
    return this.httpService.postOrder(cinemaId, sourceOrderId).pipe(map((apiModel) => new OrderViewModel(apiModel)));
  }

  putOrder(cinemaId: string, orderId: string, orderState: UpdateOrderRequest): Observable<OrderViewModel> {
    return this.httpService.putOrder(cinemaId, orderId, orderState).pipe(map((apiModel) => new OrderViewModel(apiModel)));
  }

  deleteOrder(cinemaId: string, orderId: string): Observable<void> {
    return this.httpService.deleteOrder(cinemaId, orderId).pipe(map(() => null));
  }

  silentDeleteOrder(cinemaId: string, order: OrderViewModel): boolean {
    return this.httpService.silentDeleteOrder(cinemaId, order.id);
  }

  /**
   * Patches catering items into order
   */
  patchCateringItems(cinemaId: string, items: Array<FbItemViewModel>, order: OrderViewModel | null = null): Observable<OrderViewModel> {
    if (order === null) {
      return this.postOrder(cinemaId).pipe(
        mergeMap((o) => {
          return this.orderFbItemHttpService
            .put(
              cinemaId,
              o.id,
              items.map((item) => item.toApiModel())
            )
            .pipe(map((res) => new OrderViewModel(res)));
        })
      );
    }

    return this.orderFbItemHttpService
      .put(
        cinemaId,
        order.id,
        items.map((item) => item.toApiModel())
      )
      .pipe(map((res) => new OrderViewModel(res)));
  }

  /**
   * Post catering item into order
   */
  postCateringItem(cinemaId: string, orderId: string, item: FbItemViewModel, seatContext?: any): Observable<OrderViewModel> {
    const patchOrderFbItemSeat$ = (res: OrderViewModel) => {
      const orderItemId = res.fbItems.find((item) => item.articleId === item.articleId)?.id;
      if (!orderItemId) {
        return of(res);
      }

      let request = { orderItemId: orderItemId } as SeatRequestRequestModel;
      if (seatContext) {
        request = Object.assign({ orderItemId: orderItemId }, seatContext);
      }

      console.log(request);
      return this.patchOrderFbItemSeat(cinemaId, orderId, [request]).pipe(map(() => res));
    };

    return this.orderFbItemHttpService.post(cinemaId, orderId, item.toApiModel()).pipe(
      map((res) => new OrderViewModel(res)),
      switchMap((res) => iif(() => seatContext !== null, patchOrderFbItemSeat$(res), of(res)))
    );
  }

  /**
   * Patch catering item into order
   */
  patchCateringItem(cinemaId: string, orderId: string, basketItemId: string, item: FbItemViewModel): Observable<OrderViewModel> {
    return this.orderFbItemHttpService.patchItem(cinemaId, orderId, basketItemId, item.toApiModel()).pipe(map((res) => new OrderViewModel(res)));
  }

  patchQuantityCateringItem(cinemaId: string, orderId: string, basketItemId: string, quantity: number): Observable<OrderViewModel> {
    return this.orderFbItemHttpService.patchQuantityItem(cinemaId, orderId, basketItemId, quantity).pipe(map((res) => new OrderViewModel(res)));
  }

  deleteCateringItem(cinemaId: string, orderId: string, itemId: string) {
    return this.orderFbItemHttpService.delete(cinemaId, orderId, itemId).pipe(map((res) => new OrderViewModel(res)));
  }

  getOrderPaymentMethod(cinemaId: string, orderId: string) {
    return this.httpService.getOrderPaymentMethod(cinemaId, orderId).pipe(
      map((paymentMethodResponseModels: Array<PaymentMethodResponseModel>) => {
        return paymentMethodResponseModels.map((paymentMethodResponseModel) => new PaymentMethodViewModel(paymentMethodResponseModel as PaymentMethodApiModel));
      })
    );
  }

  getOrderPayment(orderPaymentRequest: OrderPaymentRequestModel) {
    return this.httpService.getOrderPayment(orderPaymentRequest).pipe(map((response) => new PaymentViewModel(response)));
  }

  getPaymentProviderConfig(request: PaymentProviderConfigRequestModel): Observable<PaymentConfigViewModel> {
    return this.httpService.getPaymentProviderConfig(request).pipe(map((x) => new PaymentConfigViewModel(x)));
  }

  getPaymentProviderPayMethodCollection(request: PaymentProviderPayMethodRequestModel): Observable<Array<PaymentProviderPayMethodViewModel>> {
    return this.httpService
      .getPaymentProviderPayMethodCollection(request)
      .pipe(map((models: PaymentProviderPayMethodApiModel[]) => models.map((model) => new PaymentProviderPayMethodViewModel(model))));
  }

  /**
   * Gets available catering for order
   */
  getOrderFb(cinemaId: string, orderId: string = null, screenGroupId: string = null): Observable<CateringModel> {
    return forkJoin({
      nutritionals: this.httpService.getFbNutritionalInfo(cinemaId),
      catering: this.httpService.getOrderFb(cinemaId, orderId, screenGroupId),
    }).pipe(map((x) => new CateringModel(x.catering, x.nutritionals)));
  }

  public deleteOrderItem(cinemaId: string, orderId: string, itemId: string): Observable<OrderViewModel> {
    return this.httpService.deleteOrderItem(cinemaId, orderId, itemId).pipe(map((x) => new OrderViewModel(x)));
  }

  putOrderAgreementss(cinemaId: string, orderId: string, agreements: string[]) {
    return this.httpService.putOrderAgreements(cinemaId, orderId, agreements);
  }

  public deleteOrderPayment(request: PaymentProviderPayMethodRequestModel) {
    return this.httpService.deleteOrderPayment(request);
  }

  putTable(cinemaId: string, orderId: string, tableId: string) {
    return this.httpService.putTable(cinemaId, orderId, tableId).pipe(
      tap((table) => {
        sessionStorage.setItem(storageKey.table, JSON.stringify(table));
      })
    );
  }

  patchOrderFbItemSeat(cinemaId: string, orderId: string, body: SeatRequestRequestModel[]) {
    return this.httpService.patchOrderFbItemSeat(cinemaId, orderId, body);
  }
}
