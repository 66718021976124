import { VoucherPositionApiModel } from './voucher-position.api.model';

export class VoucherApiModel {
  voucherName: string;

  voucherType: string;

  voucherDescription: string;

  voucherExpiryDate: string;

  voucherNote: string;

  voucherNumber: string;

  batchVouchersID: string;

  flgVoucherType: boolean;

  flgActive: boolean;

  flgHidden: boolean;

  voucherUsesNumber: number;

  numberPerScreening: number;

  numberPerDay: number;

  numberPerWeek: number;

  numberPerMonth: number;

  usesLeft: number;

  usesPerScreeningLeft: number;

  usesPerDayLeft: number;

  usesPerWeekLeft: number;

  usesPerMonthLeft: number;

  flgRealizationDay: boolean;

  flgValidityDay: boolean;

  realizationHoursBeforeScreening: number;

  flgProjectsSpecial: boolean;

  flgMultiuse: boolean;

  voucherGroupId: string;

  voucherGroupName: string;

  positions: VoucherPositionApiModel[] = [];
}
