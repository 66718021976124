import { ScreenColApiModel } from './screen-col.api.model';
import { ScreenRowApiModel } from './screen-row.api.model';
import { ScreenElementApiModel } from './screen-element.api.model';
import { SeatBlockedApiModel } from './seat/seat-blocked.api.model';
import { SeatGroupApiModel } from './seat/seat-group.api.model';
import { SeatApiModel } from './seat/seat.api.model';

export class ScreenApiModel {
  id: string;

  name: string;

  type: string;

  feature: string;

  number: number;

  rows: ScreenRowApiModel[];

  cols: ScreenColApiModel[];

  seats: SeatApiModel[];

  groups: SeatGroupApiModel[];

  defaultSeatDescription: string;

  defaultSeatGroupName: string;

  blockedList: SeatBlockedApiModel[];

  screenGroupId: string;

  ffaNumber: string;

  proCinema: string;

  screenElements: ScreenElementApiModel[];
}
