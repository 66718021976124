import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, iif, Observable, of, switchMap, tap } from 'rxjs';
import { OrderStateModel } from '../models/order.state.model';
import { DateTime } from 'luxon';
import { PaymentMethodModel } from '../models/payment-method.model';
import { LocationEnum } from '../models/location.enum';
import { storageKey } from '../app.consts';
import { PaymentProviderPayMethodRequestModel } from '../models/request/payment-provider-pay-method.request.model';
import { NotificationService } from './notification.service';
import { Params, Router } from '@angular/router';
import { NavigationService } from './navigation/navigation.service';
import {
  AuthStateService,
  BaseDataProvider,
  CardViewModel,
  CinemaViewModel,
  DineinDataProvider,
  OrderDataProvider,
  OrderHttpService,
  OrderViewModel,
  ScreeningDetailsViewModel,
  TableViewModel,
} from '@dinein-lib/restapi-plugin';
import { ErrorHelper, ProviderEnum } from '@dinein-lib/shared';

export class InitialParams {
  public tableId: string = null;
  public seatId: string = null;

  public hostedPaymentStatus: string = null;

  public constructor(public cinemaId?: string) {}

  public set(params?: Params) {
    if (params) {
      this.tableId = params['tableId'];
      this.seatId = params['seatId'];

      this.hostedPaymentStatus = params['HostedPaymentStatus'];
    }

    return this;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderStateService {
  private model: OrderStateModel = new OrderStateModel();

  private state = new BehaviorSubject<OrderStateModel>(new OrderStateModel());
  state$: Observable<OrderStateModel> = this.state.asObservable();

  voucher$ = new BehaviorSubject<string[]>(null);
  membershipcard$ = new BehaviorSubject<CardViewModel>(null);

  private orderExpirationDate = new BehaviorSubject<DateTime>(null);
  orderExpired = this.orderExpirationDate.asObservable();
  inProgress = false;

  constructor(
    private orderDataProvider: OrderDataProvider,
    private baseDataProvider: BaseDataProvider,
    private dineindataProvider: DineinDataProvider,
    private authStateService: AuthStateService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    protected router: Router
  ) {
    //this.loadState();
  }

  public orderNotExist(): boolean {
    return !this.model || !this.model.order;
  }

  initByParams(options: InitialParams) {
    this.cleanState(true);

    return of(options).pipe(
      switchMap(() =>
        iif(
          () => options.cinemaId?.length > 0,
          this.baseDataProvider.getCinema(options.cinemaId).pipe(
            tap((cinema) => {
              this.model.cinema = cinema;
              this.storeCinema(cinema);
              this.state.next(this.model);
            })
          ),
          of(null)
        )
      ),
      switchMap(() =>
        iif(
          () => options.seatId?.length > 0,
          this.dineindataProvider.getCinemaSeatScreeningDetails(options.cinemaId, options.seatId).pipe(
            tap((screeningDetails) => {
              console.log(options.seatId, screeningDetails);
              this.storeScreeningInfo(screeningDetails);
              this.storeSeat(options.seatId);
            })
          ),
          of(null)
        )
      ),
      tap(() => {
        if (options.tableId?.length > 0) {
          sessionStorage.setItem(storageKey.tableId, options.tableId);
        }
      })
    );
  }

  loadState() {
    console.log('#loadState');

    const cinemaId = this.getCinema()?.id;
    const orderId = sessionStorage.getItem(storageKey.orderId);
    if (!cinemaId) {
      this.cleanState();
      return;
    }

    forkJoin({
      cinema: this.baseDataProvider.getCinema(cinemaId),
      order: iif(() => orderId !== null, this.orderDataProvider.getOrder(cinemaId, orderId), this.orderDataProvider.postOrder(cinemaId)),
    }).subscribe({
      next: (res) => {
        this.updateOrderState(new OrderStateModel(res.cinema, res.order));
      },
      error: (e) => {
        this.cleanState();
        this.navigationService.goToMessagePage(20000);
      },
    });
  }

  updateOrderState(state: OrderStateModel) {
    this.model = state;
    if (state?.order) {
      sessionStorage.setItem(storageKey.orderId, state.order.id);
    }
    if (state?.cinema) {
      this.storeCinema(state.cinema);
    }

    this.state.next(this.model);
  }

  getState(): OrderStateModel {
    return this.model;
  }

  orderHasUserEmail(): boolean {
    return this.model.order?.userEmail?.length > 0;
  }

  public cleanStateAll() {
    localStorage.removeItem(storageKey.cinema);
    this.cleanState();
  }

  cleanState(silent = false) {
    sessionStorage.removeItem(storageKey.vouchers);
    sessionStorage.removeItem(storageKey.orderId);
    sessionStorage.removeItem(storageKey.embededPaymentUrl);

    this.model = new OrderStateModel();
    if (!silent) {
      this.state.next(this.model);
    }

    OrderHttpService.cacheBuster$.next();
  }

  getSeatId() {
    return sessionStorage.getItem(storageKey.seatId);
  }

  removeSeat() {
    sessionStorage.removeItem(storageKey.seatId);
  }

  public storeSeat(value: string) {
    if (value) {
      sessionStorage.setItem(storageKey.seatId, value);
    }
  }

  getTableId() {
    return sessionStorage.getItem(storageKey.tableId);
  }

  getTable(): TableViewModel {
    return JSON.parse(sessionStorage.getItem(storageKey.table)) as TableViewModel;
  }

  removeTable() {
    sessionStorage.removeItem(storageKey.tableId);
    sessionStorage.removeItem(storageKey.table);
  }

  getOrderId() {
    return this.model && this.model.order ? this.model.order.id : null;
  }

  storeScreeningInfo(value: any) {
    if (value) {
      sessionStorage.setItem(storageKey.screening, JSON.stringify(value));
    }
  }

  getScreeningInfo() {
    const storedData = sessionStorage.getItem(storageKey.screening);
    return storedData ? new ScreeningDetailsViewModel(JSON.parse(storedData)) : null;
  }

  removeScreening() {
    sessionStorage.removeItem(storageKey.screening);
  }

  setVoucher(voucherNumber: string) {
    if (!voucherNumber) {
      return;
    }

    const vouchers = this.getVouchers();
    vouchers.push(voucherNumber);
    sessionStorage.setItem(storageKey.vouchers, vouchers.join(';'));
    this.voucher$.next(vouchers);
  }

  removeVoucher(voucherNumber: string) {
    if (!voucherNumber) {
      return;
    }

    const vouchers = this.getVouchers();
    const index = vouchers.indexOf(voucherNumber);
    if (index > -1) {
      vouchers.splice(index, 1);
    }

    if (vouchers.length) {
      sessionStorage.setItem(storageKey.vouchers, vouchers.join(';'));
      this.voucher$.next(vouchers);
    } else {
      this.removeVouchers();
      this.voucher$.next([]);
    }
  }

  removeVouchers() {
    sessionStorage.removeItem(storageKey.vouchers);
  }

  getVouchers() {
    const vouchersString = sessionStorage.getItem(storageKey.vouchers);
    return vouchersString ? vouchersString.split(';') : [];
  }

  setPaymentMethod(model: PaymentMethodModel) {
    sessionStorage.setItem(storageKey.selectedPaymentMethod, JSON.stringify(model));
  }

  getCinemaId() {
    const cinema = this.getCinema();
    return cinema?.id;
  }

  onError(error) {
    const err = ErrorHelper.getError(error);
    if (err.code === 2) {
      this.removeOrder();
      document.location.reload();
    } else {
      this.notificationService.showError(err?.message);
    }
  }

  setPersonalData(obj: { email: string; phone: string }) {
    localStorage.setItem(storageKey.personalData, JSON.stringify(obj));
  }

  getPersonalData() {
    const data = localStorage.getItem(storageKey.personalData);
    if (data) {
      return JSON.parse(data);
    }

    return { email: '', phone: '' };
  }

  setOrder(order: OrderViewModel) {
    const state = this.model;
    state.order = order;
    this.updateOrderState(state);
  }

  removeOrder() {
    this.model.order = null;
    this.state.next(this.model);
    sessionStorage.removeItem(storageKey.orderId);
    this.removeSeat();
    this.removeTable();
    OrderHttpService.cacheBuster$.next();
  }

  public isOrderStored(): boolean {
    return Boolean(sessionStorage.getItem(storageKey.orderId));
  }

  public isCinemaStored(): boolean {
    return Boolean(localStorage.getItem(storageKey.cinema));
  }

  public isSeatStored(): boolean {
    return Boolean(sessionStorage.getItem(storageKey.seatId));
  }

  public isTableStored(): boolean {
    return Boolean(sessionStorage.getItem(storageKey.tableId));
  }

  checkOrderIsExpired() {
    if (this.model && this.model.order) {
      const now = DateTime.local();
      if (this.model.order.dateTimeToLive < now) {
        this.removeOrder();
        this.orderExpirationDate.next(now);
      }
    }
  }

  public setEmbededPaymentUrl(value: string) {
    sessionStorage.setItem(storageKey.embededPaymentUrl, value);
  }

  public getEmbededPaymentUrl() {
    return sessionStorage.getItem(storageKey.embededPaymentUrl);
  }

  removeEmbededPaymentUrl() {
    sessionStorage.removeItem(storageKey.embededPaymentUrl);
  }

  public getStoredUserLocation(): LocationEnum {
    if (this.isCinemaStored()) {
      if (this.isSeatStored()) {
        return LocationEnum.SEAT;
      }

      return LocationEnum.CINEMA;
    }

    return LocationEnum.UNDEFINED;
  }

  public storeCinema(value: CinemaViewModel) {
    if (value) {
      localStorage.setItem(storageKey.cinema, JSON.stringify(value));
    }
  }

  public getCinema(): CinemaViewModel {
    return JSON.parse(localStorage.getItem(storageKey.cinema));
  }

  hasExternalPayments() {
    return this.model.order?.hasExternalPayments();
  }

  clearExternalPayments(provider: ProviderEnum): Observable<OrderViewModel> {
    return this.orderDataProvider.deleteOrderPayment(new PaymentProviderPayMethodRequestModel(this.model.cinemaId, this.model.orderId, provider)).pipe(
      switchMap(() => this.orderDataProvider.getOrder(this.model?.cinemaId, this.model?.orderId)),
      tap({
        next: (o) => {
          this.setOrder(o);
        },
      })
    );
  }

  clearAuthStorage() {
    console.log('#clearAuthStorage');
    localStorage.removeItem(storageKey.token);
  }

  retrieveUserToken() {
    this.authStateService.retrieveUserToken();
  }

  setMembershipCard(card: CardViewModel) {
    this.membershipcard$.next(card);
  }
  getMembershipCard(): Observable<CardViewModel> {
    return this.membershipcard$.asObservable();
  }
}
