import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OrderDataProvider } from './order.data-provider';
import { VoucherHttpService } from '../http/voucher.http.service';
import { OrderHttpService } from '../http/order.http.service';
import { VoucherViewModel } from '../models/view-model/voucher/voucher.view.model';
import { VoucherApiModel } from '../models/api-model/voucher/voucher.api.model';

@Injectable({
  providedIn: 'root',
})
export class VoucherDataProvider {
  constructor(private voucherHttpService: VoucherHttpService, private orderDataProvider: OrderDataProvider) {}

  putVoucherToOrder(cinemaId: string, orderId: string, voucherNumber: string) {
    return this.voucherHttpService.putVoucherToOrder(cinemaId, orderId, { voucherNumber }).pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  removeVoucherFromOrder(cinemaId: string, orderId: string, voucherNumber: string) {
    return this.voucherHttpService.removeVoucherFromOrder(cinemaId, orderId, voucherNumber).pipe(
      tap(() => OrderHttpService.cacheBuster$.next()),
      switchMap(() => this.orderDataProvider.getOrder(cinemaId, orderId))
    );
  }

  removeVoucherFromOrderItem(cinemaId: string, orderId: string, itemId: string, voucherNumber: string) {
    return this.voucherHttpService.removeVoucherFromOrderItem(cinemaId, orderId, itemId, voucherNumber);
  }

  assignVoucherToOrderItem(cinemaId: string, orderId: string, itemId: string, voucherNumber: string) {
    return this.voucherHttpService.assignVoucherToOrderItem(cinemaId, orderId, itemId, voucherNumber);
  }

  getInfo(cinemaId: string, voucherNumber: string): Observable<VoucherViewModel> {
    return this.voucherHttpService.getInfo(cinemaId, voucherNumber).pipe(map((result: VoucherApiModel) => new VoucherViewModel(result)));
  }
}
