import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DineinHttpService } from '../http/dinein.http.service';
import { ScreeningDetailsApiModel } from '../models/api-model/screening-details/screening-details.api.model';
import { ScreeningDetailsViewModel } from '../models/view-model/screening-details/screening-details.view.model';

@Injectable({
  providedIn: 'root',
})
export class DineinDataProvider {
  constructor(private httpService: DineinHttpService) {}

  public getCinemaSeatScreeningDetails(cinemaId: string, seatId: string) {
    return this.httpService
      .getCinemaSeatScreeningDetails(cinemaId, seatId)
      .pipe(map((model: ScreeningDetailsApiModel) => new ScreeningDetailsViewModel(model)));
  }
}
