import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { UserHttpService } from '../http/user.http.service';
import { UserLoginResponseModel } from '../models/response/user-login.response.model';
import { UserAuthRequestModel } from '../models/request/user-auth.request.model';
import { UserTokenResponseModel } from '../models/response/user-token.response.model';
import { UserApiModel } from '../models/api-model/user/user.api.model';
import { AccountItemsViewModel } from '../models/view-model/account-items/account-items.view.model';
import { AgreementAggregationViewModel } from '../models/view-model/agreement/agreement-aggregation.view.model';
import { UserAgreementHttpService } from '../http/user.agreement.http.service';
import { AgreementType } from '../models/view-model/agreement/agreement-type.enum';
import { POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS } from '../injection.tokens';
import { IRestApiOptions } from '../interfaces/restapi-options';

@Injectable({
  providedIn: 'root',
})
export class UserDataProvider {
  constructor(
    @Inject(POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS) protected options: IRestApiOptions,
    private userHttpService: UserHttpService,
    private userAgreementHttpService: UserAgreementHttpService
  ) {}

  public get(token: string): Observable<UserApiModel> {
    return this.userHttpService.getCurrent(token);
  }

  public login(request): Observable<UserLoginResponseModel> {
    return this.userHttpService.login(request);
  }

  public loginByToken(request): Observable<UserTokenResponseModel> {
    return this.userHttpService.loginByToken(request);
  }

  public doAuth(authProvider: string, token: string, state: string | null = null, redirectUri: string | null = null): Observable<UserLoginResponseModel> {
    return this.userHttpService.doAuth(authProvider, {
      token: token,
      state: state,
      redirect_uri: redirectUri,
    } as UserAuthRequestModel);
  }

  public doAuthByToken(authProvider: string, token: string, state: string = null): Observable<UserTokenResponseModel> {
    return this.userHttpService.doAuthByToken(authProvider, token, state);
  }

  public getAuthRedirect(authProvider: string, redirectUrl: string): Observable<string> {
    return this.userHttpService.getAuthRedirect(authProvider, redirectUrl);
  }

  public getAccountItemsViaApiModel(): Observable<AccountItemsViewModel> {
    return this.userHttpService.getAccountItemsViaApiModel().pipe(map((result) => new AccountItemsViewModel(result)));
  }

  public agreementGet(agreementType: AgreementType = AgreementType.ALL): Observable<AgreementAggregationViewModel> {
    const source = this.userAgreementHttpService.getUserAgreement();

    if (agreementType === AgreementType.ALL) {
      return source.pipe(map((aggrement) => new AgreementAggregationViewModel(aggrement)));
    }

    return source.pipe(
      map((aggrements) => {
        const orderAgreementID = this.options.environment.order.orderAgreementGroupId;
        console.log('orderAgreementID:', orderAgreementID);

        switch (agreementType) {
          case AgreementType.COMMON:
            aggrements.marketingAgreements = aggrements?.marketingAgreements?.filter((o) => !o.marketingAgreementGroups.includes(orderAgreementID));
            break;
          case AgreementType.ORDER:
            aggrements.marketingAgreements = aggrements?.marketingAgreements?.filter((o) => o.marketingAgreementGroups.includes(orderAgreementID));
            break;
        }

        aggrements.marketingAgreementGroups = aggrements?.marketingAgreementGroups?.filter((g) =>
          aggrements.marketingAgreements.reduce((x, y) => [...x, ...y.marketingAgreementGroups], []).includes(g.id)
        );

        return new AgreementAggregationViewModel(aggrements);
      })
    );
  }
}
