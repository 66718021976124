import { AbstractViewModel } from '../../../abstract.view.model';
import { PaymentConfigApiModel } from '../../../api-model/payment/config/payment.config.api.model';

export class PaymentConfigViewModel extends AbstractViewModel<PaymentConfigApiModel> {
  salesPointId: string;
  postBackUrl: string;
  environment: string;
  merchantId: string;
  googleMerchantId: string;
  merchantName: string;

  constructor(apiModel: PaymentConfigApiModel = new PaymentConfigApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.salesPointId = this.apiModel.salesPointId;
    this.postBackUrl = this.apiModel.postBackUrl;
    this.environment = this.apiModel.environment;
    this.merchantId = this.apiModel.merchantId;
    this.googleMerchantId = this.apiModel.googleMerchantId;
    this.merchantName = this.apiModel.merchantName;
  }

  toApiModel(): PaymentConfigApiModel {
    return undefined;
  }
}
