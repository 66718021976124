<h2 mat-dialog-title>{{'update.title' | translate }}</h2>
<mat-dialog-content class="mat-typography">
    {{'update.ready' | translate }}
    <div>
        {{data.reason}}
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-raised-button color="primary" (click)="onOkClick()"
            cdkFocusInitial>{{'button.ok' | translate }}</button>
</mat-dialog-actions>