import { AbstractViewModel } from '../../abstract.view.model';
import { CardTypeTypeApiModel } from '../../api-model/card/card-type-type.api.model';
import { UserCardTypeTypeViewModel } from '../user-history/card-type/user-card-type-type.view.model';

export class CardTypeTypeViewModel extends AbstractViewModel<CardTypeTypeApiModel> {
  public number: number = null;
  public name: string = null;
  public description: string = null;

  constructor(apiModel: CardTypeTypeApiModel = new CardTypeTypeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.number = this.apiModel.number;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
  }

  toApiModel(): CardTypeTypeApiModel {
    return undefined;
  }

  toUserCardTypeTypeViewModel(extra?: UserCardTypeTypeViewModel): UserCardTypeTypeViewModel {
    const model = Object.assign(extra ?? new UserCardTypeTypeViewModel(), {
      number: this.number,
      name: this.name,
      description: this.description,
    });

    return model;
  }
}
