import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ArticlePriceCalculator } from './calculator/article-price-calculator';
import { DefaultFormBuilder } from './form.builder';
import { AddFormEvent } from './form/event/add-form.event';
import { FormEvent } from './form/event/form.event';
import { ArticleBuilderService, ENVIRONMENT_TOKEN } from '@dinein-lib/core';
import {
  CateringAggregationArticleViewModel,
  CateringAggregationArticleModifierItemViewModel,
  CateringAggregationArticleModifierViewModel,
} from '@dinein-lib/restapi-plugin';

@Component({
  selector: 'app-article-builder',
  templateUrl: './article-builder.component.html',
})
export class ArticleBuilderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public article: CateringAggregationArticleViewModel = null;
  @Output() public formEvent: EventEmitter<FormEvent> = new EventEmitter<FormEvent>();
  @Output() public closeModal: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('articleArea', { read: ViewContainerRef, static: true })
  public articleAreaViewContainer: ViewContainerRef;

  public currency: string;
  public form: FormGroup;
  public formSubmitAttempt = false;
  public totalValue = 0.0;
  public selectedQuantity = 1;
  public selectedModifierItemMap: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
  public selectedSubArticleMap: Map<number, Map<string, Map<string, boolean>>> = new Map<number, Map<string, Map<string, boolean>>>();
  private articlePriceCalculator: ArticlePriceCalculator;
  private selectedModifierItemSubscription: Subscription = Subscription.EMPTY;
  private selectedSubArticleSubscription: Subscription = Subscription.EMPTY;

  currentCheckedValue = null;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private articleBuilderService: ArticleBuilderService, private ren: Renderer2) {
    this.currency = environment.globalize.currency;
  }

  public ngOnInit() {
    this.selectedModifierItemSubscription = this.articleBuilderService.selectedModifierItem$.subscribe((map) => {
      this.selectedModifierItemMap = map;

      this.checkingCompatibility();
    });

    this.selectedSubArticleSubscription = this.articleBuilderService.selectedSubArticleList$.subscribe((map) => {
      this.selectedSubArticleMap = map;
    });

    this.onChangedArticle();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.form = this.getForm();

    this.form.valueChanges.subscribe((x) => {
      this.formSubmitAttempt = false;
    });
  }

  public ngOnDestroy(): void {
    if (this.selectedModifierItemSubscription !== Subscription.EMPTY) {
      this.selectedModifierItemSubscription.unsubscribe();
      this.selectedModifierItemSubscription = Subscription.EMPTY;
    }

    if (this.selectedSubArticleSubscription !== Subscription.EMPTY) {
      this.selectedSubArticleSubscription.unsubscribe();
      this.selectedSubArticleSubscription = Subscription.EMPTY;
    }
  }

  public onSelectSubArticleReplacer(
    subArticle: CateringAggregationArticleViewModel,
    subArticleReplacer: CateringAggregationArticleViewModel,
    index?: number
  ): void {
    this.articleBuilderService.switchSubArticleSelectionState(subArticle, subArticleReplacer, index);
    this.articlePriceCalculator.addSubArticleReplacer(subArticleReplacer, subArticle);

    this.totalValue = this.articlePriceCalculator.getPriceToPay();
  }

  public onSelectModifierItem(
    modifierItem: CateringAggregationArticleModifierItemViewModel,
    modifier: CateringAggregationArticleModifierViewModel,
    fc?: any
  ): void {
    this.articleBuilderService.switchModifierItemSelectionState(modifier, modifierItem, fc);

    this.articlePriceCalculator.addModifierItem(modifierItem, modifier);
    this.totalValue = this.articlePriceCalculator.getPriceToPay();
  }

  public onIncrement(): void {
    this.selectedQuantity++;
  }

  public onDecrement(): void {
    if (this.selectedQuantity > 1) {
      this.selectedQuantity--;
    }
  }

  public onClickedAdd(): void {
    this.formSubmitAttempt = true;

    if (this.form.valid) {
      const modifiersWithRelatedItems = this.article.modifierArticleList.filter((modifier) => {
        return modifier.isRequired && this.isSomethingToDisplay(modifier) && !this.isAnySelected(modifier.id);
      });

      if (modifiersWithRelatedItems.length > 0) {
        modifiersWithRelatedItems.forEach((modifier) => {
          this.form.get(modifier.id).setErrors({ required: true });
        });

        this.navigationTo(modifiersWithRelatedItems[0].id);
        return;
      }

      const completedFormEvent: AddFormEvent = new AddFormEvent();
      completedFormEvent.selectedModifierItemMap = this.selectedModifierItemMap;
      completedFormEvent.selectedSubArticleMap = this.selectedSubArticleMap;
      completedFormEvent.articleId = this.article.id;
      completedFormEvent.parentGroupId = this.article.parentGroup?.id;
      completedFormEvent.quantity = this.selectedQuantity;

      this.formEvent.emit(completedFormEvent);
    } else {
      for (const key in this.form.controls) {
        if (this.form.controls[key].errors) {
          this.navigationTo(key);
          break;
        }
      }
    }
  }

  private navigationTo(id: string): void {
    const nativeElement: HTMLElement = document.getElementById(id);

    setTimeout(() => {
      nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }, 100); // error message must first rendered
  }

  public onButtonCloseClicked(): void {
    this.closeModal.next();
  }

  private getForm(): FormGroup {
    return new DefaultFormBuilder().getForm(this.article);
  }

  public getFormControl(modifierId, index) {
    const formArray = this.form.get(modifierId) as FormArray;
    if (formArray) {
      const fc = formArray.at(index) as FormControl;
      return fc;
    }

    console.error(this.form, modifierId, index);
    return null;
  }

  private onChangedArticle(): void {
    this.formSubmitAttempt = false;
    this.articlePriceCalculator = new ArticlePriceCalculator(this.article);
    this.totalValue = this.articlePriceCalculator.getPriceToPay() * this.selectedQuantity;
    this.form = this.getForm();
    this.articleAreaViewContainer.element.nativeElement.focus();
    this.articleBuilderService.initialize(this.article);
  }

  public isSomethingToDisplay(modifier: CateringAggregationArticleModifierViewModel): boolean {
    for (const modifierItem of modifier.itemCollection) {
      if (this.couldBePrinted(modifierItem.relatedModifiers)) {
        return true;
      }
    }

    return false;
  }

  public checkingCompatibility(): void {
    if (!this.articlePriceCalculator) {
      return;
    }

    for (const modifier of this.article.modifierArticleList) {
      for (const modifierItem of modifier.itemCollection) {
        if (this.isCheckedOption(modifier.id, modifierItem.id) && !this.couldBePrinted(modifierItem.relatedModifiers)) {
          this.selectedModifierItemMap.get(modifier.id).forEach((value, key) => {
            this.selectedModifierItemMap.get(modifier.id).set(key, false);
          });
          this.uncheckAllOptions(modifier);
          this.articlePriceCalculator.resetModifierItems(modifier);

          // TODO: check why it is buggy (kurczak => mocno => wołowina => krwisto => średnio => kurczak)
          // this.selectedModifierItemMap.get(modifier.id).set(modifierItem.id, false);
          // this.articlePriceCalculator.dropModifierItem(modifierItem, modifier);
          // this.markOption(modifier, modifierItem, false);
        }
      }
    }

    this.totalValue = this.articlePriceCalculator.getPriceToPay();
  }

  private markOption(
    modifier: CateringAggregationArticleModifierViewModel,
    modifierItem: CateringAggregationArticleModifierItemViewModel,
    value: boolean
  ): void {
    const formValue = this.form.get(modifier.id).value;
    const foundIndex = formValue.findIndex((modifierItemId) => {
      return modifierItemId === modifierItem.id;
    });

    if (foundIndex > -1) {
      formValue[foundIndex] = value;

      this.form.get(modifier.id).setValue(formValue);
    }
  }

  private uncheckAllOptions(modifier: CateringAggregationArticleModifierViewModel): void {
    this.form.get(modifier.id).setValue(this.form.get(modifier.id).value.map((x) => false));
  }

  public couldBePrinted(ids: Array<CateringAggregationArticleModifierViewModel>): boolean {
    if (!ids || ids.length === 0) {
      return true;
    }

    for (const relatedItem of ids) {
      if (this.isChecked(relatedItem.id)) {
        return true;
      }
    }

    return false;
  }

  public isChecked(id: string): boolean {
    for (const selectedModifier of this.selectedModifierItemMap.values()) {
      for (const selectedModifierItem of selectedModifier) {
        if (selectedModifierItem[0] === id && selectedModifierItem[1]) {
          return true;
        }
      }
    }

    return false;
  }

  public isCheckedOption(modifierId: string, searchingModifierItemId: string): boolean {
    return this.form.get(modifierId).value.find((modifierItemId) => {
      return modifierItemId === searchingModifierItemId;
    });
  }

  public getGroupSubArticleText(subArticle, index = 0): string {
    return `item${index}_${subArticle.id}`;
  }

  public getSubArticleText(subArticle, subArticleReplacer, index = 0): string {
    return `item${index}_${subArticle.id + '-' + subArticleReplacer.id}`;
  }

  public getModifierText(modifier, modifierItem): string {
    return `${modifier.id + '-' + modifierItem.id}`;
  }

  private isAnySelected(id: string): boolean {
    return Array.from(this.selectedModifierItemMap.get(id).values()).find((x) => x === true) !== undefined;
  }

  public isRequired(id: string) {
    const errors = this.form.get(id)?.errors;
    return errors ? errors['required'] : false;
  }

  public get allergens() {
    return this.article.nutritionalInfo && this.article.nutritionalInfo.allergens ? this.article.nutritionalInfo.allergens.join(', ') : '';
  }

  public get nutritionals() {
    return this.article.nutritionalInfo && this.article.nutritionalInfo.nutritionals
      ? this.article.nutritionalInfo.nutritionals.map((o) => `${o.name}: ${o.quantity}`).join(', ')
      : '';
  }

  checkState(el, modifierItem, modifier) {
    setTimeout(() => {
      if (this.currentCheckedValue && this.currentCheckedValue === el.value) {
        el.checked = false;
        this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-program-focused');
        this.currentCheckedValue = null;
        //this.favoriteSeason = null;
      } else {
        this.currentCheckedValue = el.value;
      }

      this.onSelectModifierItem(modifierItem, modifier);
    });
  }
}
