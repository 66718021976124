import { Expose, Transform } from 'class-transformer';
import { DateTime } from 'luxon';

export class FbItemSubArticleApiModel {
  articleId: string;

  quantity: number;

  name: string;

  taxRate: number;

  price: number;

  defaultPriceLevelPrice: number;
}
