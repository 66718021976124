export class CardTypeMembershipUpgradeFeeApiModel {
  feeId: string;

  name: string;

  extensionTime: number;

  extensionType: number;

  feeValue: number;

  upgradeToCardTypeId: string;

  upgradeToCardTypeName: string;
}
