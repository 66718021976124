import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class AgreementApiModel {
  id: string;

  name: string;

  required: boolean;

  validFrom: string;

  validTo: string;

  priority: number;

  contents: string;

  marketingAgreementGroups: string[];
}
