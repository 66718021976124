import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { from, Observable, pipe } from 'rxjs';
import { debounceTime, filter, map, mergeAll, throttleTime } from 'rxjs/operators';

@Component({
  template: '',
})
export class SearchFormComponent implements OnInit {
  searchForm = new FormGroup({
    query: new FormControl(''),
  });

  @Input() query: string | null = '';
  @Output() search = new EventEmitter<{ query: string }>();
  @Output() searchClick = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    this.searchForm.patchValue(
      {
        query: changes['query'].currentValue,
      },
      {
        emitEvent: false /* , onlySelf:true */,
      }
    );
  }

  submitSearch() {
    this.searchClick.next({});
  }

  ngOnInit(): void {
    const filterAndDistinct = (length = 3) =>
      pipe(
        filter((q: string) => q.length >= length || q.length === 0)
        //distinctUntilChanged(),
      );

    const clicks = this.searchClick.pipe(
      map<any, string>(() => this.searchForm.get('query')?.value ?? ''),
      throttleTime(1000)
    );

    const typings = (this.searchForm.get('query')?.valueChanges as Observable<string>).pipe(debounceTime(400));

    from([clicks, typings])
      .pipe(
        mergeAll(),
        filterAndDistinct(3),
        map((query) => ({ query }))
      )
      .subscribe(this.search);
  }

  clear() {
    this.search.emit({ query: '' });
  }
}
