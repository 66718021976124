import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AgreementApiModel } from '../models/api-model/agreement/agreement.api.model';
import { AgreementAggregationApiModel } from '../models/api-model/agreement/agreement-aggregation.api.model';

@Injectable({
  providedIn: 'root',
})
export class UserAgreementHttpService {
  public agreementList: Observable<AgreementApiModel[]>;

  constructor(private http: HttpClient) {}

  getUserAgreement(): Observable<AgreementAggregationApiModel> {
    return this.http.get<AgreementAggregationApiModel>(`/user/agreement`);
  }
}
