<div class="area">
  <div>
    <p>
      {{ article.description }}
    </p>
  </div>
  <div *ngIf="allergens">
    <h5 class="group-label">{{ 'label.allergens' | translate }}</h5>
    <p>
      {{ allergens }}
    </p>
  </div>
  <div *ngIf="nutritionals">
    <h5 class="group-label">{{ 'label.nutritional-info' | translate }}</h5>
    <p>
      {{ nutritionals }}
    </p>
  </div>

  <div class="hrline" *ngIf="article.subArticleList.length > 0 || article.modifierArticleList.length > 0"></div>

  <div class="article-area" #articleArea [ngClass]="{
      'pb-4': article.subArticleList.length > 0 || article.modifierArticleList.length > 0
    }">
    <form [formGroup]="form">
      <div class="modifier-area" id="{{ subArticle.id }}" data-from="subArticle"
           *ngFor="let subArticle of article.subArticleList; let i = index">
        <ng-container
                      *ngTemplateOutlet="subArticleTemplate; context: { subArticle: subArticle, article: article, index: i  }"></ng-container>
        <hr *ngIf="i < article.subArticleList.length - 1 || article.modifierArticleList.length > 0" />
      </div>

      <div class="modifier-area" id="{{ modifier.id }}" data-from="modifier"
           *ngFor="let modifier of article.modifierArticleList; let i = index">
        <ng-container *ngIf="isSomethingToDisplay(modifier)">
          <ng-container
                        *ngTemplateOutlet="modifierTemplate; context: { modifier: modifier, article: article }"></ng-container>
          <hr *ngIf="i < article.modifierArticleList.length - 1" />
        </ng-container>
      </div>
    </form>
  </div>
</div>

<div>
  <div class="d-flex justify-content-center">
    <div class="d-flex flex-row justify-content-between align-items-center w-50">
      <div class="btn btn-link p-3" (click)="onDecrement()" [ngClass]="{ disabled: this.selectedQuantity <= 1 }">
        <i class="fa fa-minus"></i>
      </div>
      <div class="sel-amount">
        <span class="count font-weight-bold">{{ selectedQuantity }}</span>
      </div>
      <div class="btn btn-link p-3" (click)="onIncrement()">
        <i class="fa fa-plus"></i>
      </div>
    </div>
  </div>
  <div class="pt-3">
    <button class="action-button mx-0" mat-raised-button color="primary" (click)="onClickedAdd()">
      <span class="d-flex flex-row justify-content-between">
        <span class="ml-3">
          {{ 'button.add-to-order' | translate }}
        </span>
        <span class="mr-3" *ngIf="totalValue">
          {{ totalValue * selectedQuantity | money }}
        </span>
      </span>
    </button>
  </div>
</div>

<ng-template #subArticleTemplate let-subArticle="subArticle" let-article="article" let-index="index">
  <div class="modifier">
    <h5 class="group-label d-flex justify-content-between"
        *ngIf="subArticle.replacementList.length === 0; else replacementGroup">
      {{ subArticle.name }}
    </h5>
    <ng-template #replacementGroup>

      <ng-container *ngVar="getGroupSubArticleText(subArticle, index) as keyIndex">
        <ng-container *ngVar="getSubArticleText(subArticle, subArticle, index) as subArticleText">

          <!-- <p-radioButton [name]="subArticle.id" [value]="getSubArticleText(subArticle, subArticle)"
                     (change)="onSelectSubArticleReplacer(subArticle, subArticle)"></p-radioButton> -->
          <mat-radio-group class="radio-group" [formControl]="getFormControl(keyIndex, 0)">
            <mat-radio-button (change)="onSelectSubArticleReplacer(subArticle, subArticle, index)" [name]="keyIndex"
                              [id]="subArticleText" [value]="subArticleText" [checked]="true" class="rcb"
                              color="primary">
              <span class="row">
                <span class="col-12">
                  {{ subArticle.name }}
                </span>

                <!-- <span *ngIf="subArticle.description" class="col-12 description">
                                         {{ subArticle.description }}
                                     </span> -->
              </span>
            </mat-radio-button>

            <ng-container *ngFor="let subArticleReplacer of subArticle.replacementList; let ind = index">
              <ng-container *ngVar="getSubArticleText(subArticle, subArticleReplacer, index) as subArticleReplacerText">
                <mat-radio-button (change)="onSelectSubArticleReplacer(subArticle, subArticleReplacer, index)"
                                  [name]="keyIndex" [id]="subArticleReplacerText" [value]="subArticleReplacerText"
                                  class="rcb" color="primary">
                  <span class="row">
                    <span class="col-12">
                      {{ subArticleReplacer.name }}
                    </span>

                    <!-- <span *ngIf="subArticleReplacer.description" class="col-12  description">
           {{ subArticleReplacer.description }}
       </span> -->
                  </span>
                </mat-radio-button>
              </ng-container>

            </ng-container>

          </mat-radio-group>
        </ng-container>

      </ng-container>

    </ng-template>
  </div>
</ng-template>

<ng-template #modifierTemplate let-modifier="modifier" let-article="article">
  <div class="modifier">
    <div class="text-left glabel" [class.has-error]="formSubmitAttempt === true && isRequired(modifier.id)">
      <h5 class="d-inline-block group-label">{{ modifier.name }}</h5>
      <ng-container
                    *ngIf="modifier.isRequired || ((modifier.multiChoiceMin > 0 || modifier.multiChoiceMax > 0) && modifier.multiChoice); else optionalTemplate">
        <span class="required">
          (
          <span *ngIf="modifier.isRequired" [class.error]="formSubmitAttempt === true && isRequired(modifier.id)">
            {{ 'app.components.article-card.required' | translate }}
          </span>
          <span *ngIf="modifier.multiChoice">
            <span *ngIf="modifier.multiChoiceMin > 0">
              <span *ngIf="modifier.isRequired">&nbsp;</span>
              {{ 'app.components.article-card.min' | translate }}
              {{ modifier.multiChoiceMin }}
            </span>
            <span *ngIf="modifier.multiChoiceMax > 0">
              <span *ngIf="modifier.isRequired">&nbsp;</span>
              {{ 'app.components.article-card.max' | translate }}
              {{ modifier.multiChoiceMax }}
            </span>
          </span>
          )
        </span>
      </ng-container>
    </div>

    <ng-container *ngIf="!modifier.multiChoice">
      <mat-radio-group class="radio-group" [formControl]="getFormControl(modifier.id, 0)">
        <ng-container *ngFor="let modifierItem of modifier.itemCollection; let ind = index">
          <ng-container *ngIf="couldBePrinted(modifierItem.relatedModifiers)">
            <mat-radio-button #button (click)="checkState(button, modifierItem, modifier)" [name]="modifier.id"
                              [id]="modifierItem.id" [value]="modifierItem.id" class="rcb" color="primary">
              <ng-container *ngTemplateOutlet="modifierItemTemplate; context: { item: modifierItem }"></ng-container>
            </mat-radio-button>
          </ng-container>
        </ng-container>
      </mat-radio-group>
    </ng-container>

    <ng-container *ngIf="modifier.multiChoice">
      <div *ngFor="let modifierItem of modifier.itemCollection; let ind = index">
        <ng-container *ngIf="couldBePrinted(modifierItem.relatedModifiers)">
          <mat-checkbox [formControl]="getFormControl(modifier.id, ind)" [name]="modifier.id"
                        [id]="getModifierText(modifier, modifierItem)" [value]="getModifierText(modifier, modifierItem)"
                        (change)="onSelectModifierItem(modifierItem, modifier, getFormControl(modifier.id, ind))"
                        class="rcb" color="primary">
            <ng-container *ngTemplateOutlet="modifierItemTemplate; context: { item: modifierItem }"></ng-container>
          </mat-checkbox>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <p *ngIf="formSubmitAttempt === true && isRequired(modifier.id)" class="with-errors" err="error.10030">
    {{ 'app.components.article-card.select-required' | translate }}
  </p>
</ng-template>

<ng-template #modifierItemTemplate let-item="item">
  <span class="row">
    <span class="col-9">
      {{ item.name }}
      <span *ngIf="item.quantity > 1" class="pl-1 quantity">(x{{ item.quantity }})
      </span>
      <!-- <span class="description d-block">
        {{ item.description }}
      </span> -->
    </span>
    <span class="col-3 text-right text-nowrap" *ngIf="item.price">
      {{ currency }} {{ item.price | number : '1.2-2' }}
    </span>
  </span>
</ng-template>

<ng-template #optionalTemplate>
  <span class="optional"> ( {{ 'app.components.article-card.optional' | translate }} ) </span>
</ng-template>