export class PaymentProviderPayMethodApiModel {
  public id: string;

  public type: string;

  public subType: string | null;

  public name: string | null;

  public imageUrl: string | null;

  public minAmount: number | null;

  public maxAmount: number | null;

  public status: string | null;

  public isPreferred: boolean;

  public cardExpirationYear: string | null;

  public cardExpirationMonth: string | null;

  public cardMask: string | null;

  public cardBrand: string | null;

  public canBeSaved: boolean;

  public token: string | null;

  public canBeShown = true;
}
