import { AbstractViewModel } from '../../abstract.view.model';
import { ScreenApiModel } from '../../api-model/screen/screen.api.model';
import { ScreenColViewModel } from './screen-col.view.model';
import { ScreenElementViewModel } from './screen-element.view.model';
import { ScreenRowViewModel } from './screen-row.view.model';
import { SeatBlockedViewModel } from './seat/seat-blocked.view.model';
import { SeatGroupViewModel } from './seat/seat-group.view.model';
import { SeatViewModel } from './seat/seat.view.model';

export class ScreenViewModel extends AbstractViewModel<ScreenApiModel> {
  id: string;
  screenName: string;
  type: string;
  feature: string;
  number: number;
  rows: ScreenRowViewModel[];
  cols: ScreenColViewModel[];
  seats: SeatViewModel[];
  groups: SeatGroupViewModel[];
  defaultSeatDescription: string;
  defaultSeatGroupName: string;
  blockedList: SeatBlockedViewModel[];
  screenGroupId: string;
  ffaNumber: string;
  proCinema: string;
  screenElements: ScreenElementViewModel[];

  constructor(apiModel: ScreenApiModel = new ScreenApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.screenName = this.apiModel.name;
    this.type = this.apiModel.type;
    this.feature = this.apiModel.feature;
    this.number = this.apiModel.number;
    this.rows = this.apiModel.rows ? this.apiModel.rows.map((model) => new ScreenRowViewModel(model)) : [];
    this.cols = this.apiModel.cols ? this.apiModel.cols.map((model) => new ScreenColViewModel(model)) : [];
    this.seats = this.apiModel.seats ? this.apiModel.seats.map((model) => new SeatViewModel(model)) : [];
    this.groups = this.apiModel.groups ? this.apiModel.groups.map((model) => new SeatGroupViewModel(model)) : [];
    this.defaultSeatDescription = this.apiModel.defaultSeatDescription;
    this.defaultSeatGroupName = this.apiModel.defaultSeatGroupName;
    this.blockedList = this.apiModel.blockedList.map((model) => new SeatBlockedViewModel(model));
    this.screenGroupId = this.apiModel.screenGroupId;
    this.ffaNumber = this.apiModel.ffaNumber;
    this.proCinema = this.apiModel.proCinema;
    this.screenElements = this.apiModel.screenElements ? this.apiModel.screenElements.map((model) => new ScreenElementViewModel(model)) : [];

    this.seats.forEach((item) => {
      const sourceSeat = this.seats.find((sourceItem) => sourceItem.id === item.id);
      const row = this.rows.find((row) => sourceSeat.rowId === row.id);
      if (row) {
        item.rowNumber = row.legend;
      }
    });
  }

  toApiModel(): ScreenApiModel {
    return undefined;
  }

  override toString(): string {
    return `${this.screenName}`;
  }
}
