import { AbstractViewModel } from '../../../abstract.view.model';
import { SeatGroupApiModel } from '../../../api-model/screen/seat/seat-group.api.model';
import { ISeatGroupModel } from '../../../interfaces';

export class SeatGroupViewModel extends AbstractViewModel<SeatGroupApiModel> implements ISeatGroupModel {
  seatIds: string[];
  id: string;
  ico: string;
  color: string;
  name: string;
  description: string;
  type: string;

  constructor(apiModel: SeatGroupApiModel = new SeatGroupApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.seatIds = this.apiModel.seatIds;
    this.id = this.apiModel.id;
    this.ico = this.apiModel.ico;
    this.color = this.apiModel.color;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.type = this.apiModel.type;
  }

  toApiModel(): SeatGroupApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}
