import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { PaymentHelperService } from '../../../service/payment-helper.service';
import { PaymentProviderStateService } from '../../../service/payment-provider-state.service';
import { DonePaymentProviderEvent } from '../../event/done-payment-provider.event';
import { ErrorPaymentProviderEvent } from '../../event/error-payment-provider.event';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { PaymentMethodEnum } from '../../model/payment-method.enum';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { OrderStateModel } from '../../../../../models/order.state.model';
import { PaymentProviderConfigRequestModel } from '../../../../../models/request/payment-provider-config.request.model';
import { PaymentProviderPayMethodRequestModel } from '../../../../../models/request/payment-provider-pay-method.request.model';
import { NotSelectedPaymentProviderEvent } from '../../event/not-selected-payment-provider.event';
import { LoadingService } from '../../../../loading/loading.service';
import { OrderDataProvider, PaymentConfigViewModel, PaymentProviderPayMethodViewModel, PaymentViewModel } from '@dinein-lib/restapi-plugin';
import { ENVIRONMENT_TOKEN, OrderStateService } from '@dinein-lib/core';

@Component({
  selector: 'app-payment-provider-datatrans-component',
  templateUrl: './datatrans-payment-provider.component.html',
})
export class DatatransPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  env: any;

  @Output()
  public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public paymentMethod = PaymentMethodEnum;
  public readonly paymentProviderIdentifier: string = 'datatrans';
  private typeToken = 'TOKEN';

  public paymentProviderPayMethodCollection: Array<PaymentProviderPayMethodViewModel> = new Array<PaymentProviderPayMethodViewModel>();
  public paymentProviderConfig: PaymentConfigViewModel;

  public form: FormGroup;
  public cardFormGroup: FormGroup;

  private paymentProviderInitSubscription: Subscription = Subscription.EMPTY;

  private orderState: OrderStateModel;
  private readonly paymentChannel: string | null = null;

  public paymentMethodList: PaymentProviderPayMethodViewModel[] = [];
  public storedPaymentMethodList: PaymentProviderPayMethodViewModel[] = [];
  public selectedPaymentMethod: PaymentProviderPayMethodViewModel;
  public selectedPayMethodTypeCustom: string | null = null;

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private orderDataProvider: OrderDataProvider,
    private orderStateService: OrderStateService,
    private paymentHelperService: PaymentHelperService,
    private route: ActivatedRoute,
    private selectedPaymentTypeService: PaymentProviderStateService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.env = environment;
    this.paymentChannel = environment.payment.channel || 'web';

    this.selectedPaymentTypeService.state$.subscribe((state) => {
      if (state) {
        this.selectedPayMethodTypeCustom = state.type;
      } else {
        this.selectedPayMethodTypeCustom = null;
      }
    });
  }

  public ngOnInit(): void {
    this.initPaymentProviderWidget();
  }

  public ngOnDestroy(): void {
    if (this.paymentProviderInitSubscription !== Subscription.EMPTY) {
      this.paymentProviderInitSubscription.unsubscribe();
      this.paymentProviderInitSubscription = Subscription.EMPTY;
    }
    this.form?.reset();
    this.cardFormGroup?.reset();
  }

  public get isSafari(): boolean {
    return false; //MobileDeviceUtils.isSafari();
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    this.orderState = this.orderStateService.getState();

    //@ts-ignore
    Datatrans.startPayment({
      transactionId: paymentModel.id,
      opened: () => console.log('payment-form opened'),
      loaded: () => console.log('payment-form loaded'),
      closed: () => {
        console.log('payment-form closed');
        this.deleteOrderPayment();
      },
      error: () => {
        console.log('payment-form error');
        this.redirectToPaymentSummary();
      },
    });
  }

  public onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    return new Observable<PaymentPreInitModel>((subscriber) => {
      if (this.selectedPayMethodTypeCustom === null) {
        event.abort = true;

        this.events.emit(new NotSelectedPaymentProviderEvent());
        subscriber.next(event);
        subscriber.complete();
      }
    });
  }

  private initPaymentProviderWidget(): void {
    const state = this.paymentHelperService.getState();
    console.log('#initPaymentProviderWidget');
    const fork = forkJoin([
      this.orderDataProvider.getPaymentProviderConfig(
        new PaymentProviderConfigRequestModel(state.cinemaId, state.orderId, this.paymentProviderIdentifier, this.paymentChannel)
      ),
      this.orderDataProvider.getPaymentProviderPayMethodCollection(
        new PaymentProviderPayMethodRequestModel(state.cinemaId, state.orderId, this.paymentProviderIdentifier, this.paymentChannel)
      ),
    ]);

    this.paymentProviderInitSubscription = fork.subscribe({
      next: ([paymentProviderConfigModel, paymentProviderPayMethodCollection]) => {
        this.paymentProviderConfig = paymentProviderConfigModel;
        this.paymentProviderPayMethodCollection = paymentProviderPayMethodCollection;

        const specialPaymentMethod = paymentProviderPayMethodCollection.find((pm) => pm.id === (this.isSafari ? 'PAY' : 'APL'));
        if (specialPaymentMethod) {
          specialPaymentMethod.canBeShown = false;
        }

        this.paymentMethodList = paymentProviderPayMethodCollection.filter((o) => o.type !== this.typeToken);
        this.storedPaymentMethodList = paymentProviderPayMethodCollection.filter((o) => o.type === this.typeToken);
        if (this.storedPaymentMethodList?.length) {
          this.selectedPaymentMethod = this.storedPaymentMethodList[0];
        }

        // this.payByLinkMethodCollection = this.getPayByLinkPayMethodCollection();
        // this.cardMethodCollection = this.getCardMethodCollection();

        this.events.next(new DonePaymentProviderEvent());
      },
      error: (e: HttpErrorResponse) => this.events.next(new ErrorPaymentProviderEvent(e)),
    });
  }

  public onSelectPaymentMethod(paymentMethod) {
    this.selectPaymentMethod(paymentMethod);
  }

  private selectPaymentMethod(paymentMethod) {
    this.selectedPaymentMethod = paymentMethod;
    document.getElementById('paybutton').innerHTML = '';
  }

  redirectToPaymentSummary() {
    window.history.replaceState({}, '', '/');
    this.router.navigate(['payment'], { queryParams: { params: `${this.orderState.orderId},${this.orderState.cinemaId}` } });
  }

  protected deleteOrderPayment(): void {
    this.loadingService.showLoader();
    this.orderDataProvider
      .deleteOrderPayment(new PaymentProviderPayMethodRequestModel(this.orderState.cinemaId, this.orderState.orderId, 'datatrans', 'web'))
      .subscribe({
        next: (v) => {
          this.loadingService.hideLoader();
          this.selectedPaymentMethod = undefined;
        },
        error: (e) => {
          this.selectedPaymentMethod = undefined;
        },
      });
  }
}
