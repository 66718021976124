import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderPageComponent, CheckListItemModel, OrderPaymentRequestModel } from '@dinein-lib/core';
import { requiredFormArray, ValidatorHelper, MembershipLoginDialogComponent } from '@dinein-lib/shared';
import { of, switchMap } from 'rxjs';

@Component({
  templateUrl: './order.component.html',
})
export class CustomOrderPageComponent extends OrderPageComponent {
  protected override createPersonalForm() {
    const personalData = this.orderStateService.getPersonalData();
    const agreements = new FormArray([], requiredFormArray());

    const checkList = this.orderAgreements.map((a) => {
      const item = new CheckListItemModel(a.id, a.name);
      item.required = a.required;
      item.content = a.contents;
      return item;
    });

    checkList.forEach((c) => {
      agreements.push(new FormControl(c));
    });

    if (checkList.length) {
      this.form = new FormGroup(
        {
          email: new FormControl(personalData.email, [Validators.required, Validators.pattern(ValidatorHelper.emailPattern)]),
          agreements: agreements,
        },
        {}
      );
    } else {
      this.form = new FormGroup(
        {
          email: new FormControl(personalData.email, [Validators.required, Validators.pattern(ValidatorHelper.emailPattern)]),
        },
        {}
      );
    }
  }

  public membershipLogin() {
    const email = this.form.get('email').value;
    const dialogRef = this.dialog.open(MembershipLoginDialogComponent, {
      data: { email: email },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.orderStateService.setPersonalData({ email: result.email, phone: '' });
      }
    });
  }

  protected createPaymentRequest(): OrderPaymentRequestModel {
    const orderPaymentRequest: OrderPaymentRequestModel = new OrderPaymentRequestModel();
    orderPaymentRequest.cinemaId = this.orderState.cinemaId;
    orderPaymentRequest.orderId = this.orderState.orderId;
    orderPaymentRequest.paymentProviderIdentifier = this.getPaymentProvider()?.identifier;

    return orderPaymentRequest;
  }

  closeOrderByPayment() {
    const deletePayments$ = this.orderStateService.hasExternalPayments()
      ? this.orderStateService.clearExternalPayments(this.getPaymentProviderEnum())
      : of(null);

    deletePayments$
      .pipe(
        switchMap(() => {
          return this.orderDataProvider.getOrderPayment(this.createPaymentRequest());
        })
      )
      .subscribe({
        next: (payment) => {
          if (payment.plainPayload) {
            // this.onPaymentProcessChanged(PaymentProcessEnum.END);
            this.paymentProxyComponent.onPostInitPayment(payment, this.getPaymentProvider()?.identifier);
          }
        },
        error: (e) => {
          // this.onPaymentProcessChanged(PaymentProcessEnum.FAILED);
          console.log(e);
        },
      });
  }

  protected afterUpdateBasket() {
    this.loadingService.showLoader();
    this.closeOrderByPayment();
  }
}
