import { AbstractViewModel } from '../../abstract.view.model';
import { TableApiModel } from '../../api-model/table/table.api.model';

export class TableViewModel extends AbstractViewModel<TableApiModel> {
  orderId: string;
  tableId: string;
  tableName: string;
  tableNumber: number;
  hallName: string;

  constructor(apiModel: TableApiModel = new TableApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    if (!this.apiModel) {
      return;
    }

    this.orderId = this.apiModel.orderId;
    this.tableId = this.apiModel.tableId;
    this.tableName = this.apiModel.tableName;
    this.tableNumber = this.apiModel.tableNumber;
    this.hallName = this.apiModel.hallName;
  }

  toApiModel(): TableApiModel {
    return undefined;
  }
}
