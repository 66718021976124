import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CardElementRefService } from '../../../credit-card/card-element-ref.service';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { PaymentViewModel } from '@dinein-lib/restapi-plugin';

@Component({
  selector: 'app-payment-provider-hyperpay-component',
  templateUrl: './hyperpay-payment-provider.component.html',
})
export class HyperpayPaymentProviderComponent implements PaymentProviderComponentInterface {
  @Output()
  public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public returnUrl: string = null;

  public constructor(private elementRef: ElementRef, private cardRefService: CardElementRefService) {}

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    const scriptElement: HTMLElement = document.createElement('script');
    scriptElement['type'] = 'text/javascript';
    scriptElement['src'] = paymentModel.plainPayload;

    this.elementRef.nativeElement.appendChild(scriptElement);

    this.cardRefService.createCardOptions(this.elementRef.nativeElement);
  }

  public onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    return new Observable<PaymentPreInitModel>((subscriber) => {
      this.returnUrl = event['continueUrl'];

      subscriber.next(null);

      subscriber.complete();
    });
  }
}
