export class SeatApiModel {
  id: string;

  symbol: string;

  kind: string;

  colId: string;

  rowId: string;

  groupId: string;

  wheelchairSeat: boolean;
}
