import { TagGroupApiModel } from '../tag-group.api.model';
import { SeatApiModel } from './seat.api.model';

export class ScreeningDetailsApiModel {
  screeningId: string;
  screeningTimeFrom: string;
  screeningTimeTo: string;
  screeningRuntime: number;
  screeningName: string;
  screenName: string;
  screenNumber: number;

  seats: SeatApiModel[];
  posters: string[];
  tagGroups: TagGroupApiModel[];
}
