import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class MobileDeviceService {
  isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
  }

  isAndroid(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('android') > -1;
  }

  isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  isIos(): boolean {
    return /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
  }

  isInStandaloneMode(): boolean {
    return 'standalone' in (window as any).navigator && (window as any).navigator.standalone;
  }
}
