import { AbstractViewModel } from '../../../abstract.view.model';
import { CardItemApiModel } from '../../../api-model/order/card-item/card-item.api.model';
import { CardItemMembershipPriceViewModel } from './card-item-membership-price.view.model';
import { CardItemShippingAddressViewModel } from './card-item-shipping-address.view.model';

export class CardItemViewModel extends AbstractViewModel<CardItemApiModel> {
  id: string;
  cardId: string;
  cardTypeId: string;
  cardBatchId: string;
  cardBatchName: string;
  cardPictureUrl: string;
  name: string;
  batchCardUnlimited: boolean;
  value: number;
  promotionId: string;
  promotionName: string;
  shippingAddress: CardItemShippingAddressViewModel;
  membershipPrices: CardItemMembershipPriceViewModel[] = [];

  quantity = 1;

  constructor(apiModel: CardItemApiModel = new CardItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.cardId = this.apiModel.cardId;
    this.cardTypeId = this.apiModel.cardTypeId;
    this.cardBatchId = this.apiModel.cardBatchId;
    this.cardBatchName = this.apiModel.cardBatchName;
    this.cardPictureUrl = this.apiModel.cardPictureUrl;
    this.name = this.apiModel.name;
    this.batchCardUnlimited = this.apiModel.batchCardUnlimited;
    this.value = this.apiModel.value;
    this.promotionId = this.apiModel.promotionId;
    this.promotionName = this.apiModel.promotionName;
    this.shippingAddress = this.apiModel.shippingAddress ? new CardItemShippingAddressViewModel(this.apiModel.shippingAddress) : null;
    this.membershipPrices = this.apiModel.membershipPrices
      ? this.apiModel.membershipPrices.map((membershipPrice) => new CardItemMembershipPriceViewModel(membershipPrice))
      : [];
  }

  toApiModel(): CardItemApiModel {
    return undefined;
  }
}
