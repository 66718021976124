<ng-container *ngIf="details">
    <div class="details-box d-flex gap-2">
        <ng-container [ngTemplateOutlet]="posterTemplate || customPosterTemplate"
                      [ngTemplateOutletContext]="{ $implicit: details }"></ng-container>
        <ng-container [ngTemplateOutlet]="contentTemplate || customContentTemplate"
                      [ngTemplateOutletContext]="{ $implicit: details, cinema: cinema }"></ng-container>
    </div>
</ng-container>

<ng-template #posterTemplate let-details>
    <ng-container *ngIf="details.posters">
        <div *ngFor="let poster of details.posters" class="poster">
            <img [src]="poster" alt="poster">
        </div>
    </ng-container>
</ng-template>

<ng-template #contentTemplate let-details let-cinema="cinema">
    <div class="d-flex flex-column justify-content-between gap-1">
        <span class="title">{{ details.screeningName }}</span>
        <span class="date">
            {{ details.screeningTimeFrom | dateTime: 'DATE_SHORT_DMY' : 2 }},
            {{ details.screeningTimeFrom | dateTime : '24H'  }}
        </span>
        <span class="d-inline-flex gap-1">{{ cinema?.city }}, {{ details.screenName }}
            <ng-container [ngTemplateOutlet]="seatsTemplate || customSeatsTemplate"
                          [ngTemplateOutletContext]="{ $implicit: details.seats }"></ng-container>
        </span>
    </div>
</ng-template>

<ng-template #seatsTemplate let-details>
    <span *ngFor="let seat of details.seats">
        ( {{seat.rowSymbol}}{{seat.seatSymbol}} )
    </span>
</ng-template>