import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DonePaymentProviderEvent } from '../../event/done-payment-provider.event';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { WorkPaymentProviderEvent } from '../../event/work-payment-provider.event';
import { PaymentMethodEnum } from '../../model/payment-method.enum';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { PaymentConfigViewModel, PaymentViewModel } from '@dinein-lib/restapi-plugin';
import { ENVIRONMENT_TOKEN } from '@dinein-lib/core';

@Component({
  selector: 'app-payment-provider-worldpay-component',
  templateUrl: './worldpay-payment-provider.component.html',
})
export class WorldPayPaymentProviderComponent implements PaymentProviderComponentInterface {
  @Output() public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();
  public paymentMethod = PaymentMethodEnum;
  public readonly paymentProviderIdentifier: string = 'worldpay';
  public paymentProviderConfig: PaymentConfigViewModel | null;
  public resourceUrl: string = null;
  public returnUrl: string = null;
  private readonly paymentChannel: string | null = null;

  public constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {
    this.paymentChannel = environment.payment.channel;
  }

  onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    return new Observable<PaymentPreInitModel>((subscriber) => {
      const preInitPaymentEvent: PaymentPreInitModel = new PaymentPreInitModel();
      preInitPaymentEvent.continueUrl = event['continueUrl'];

      subscriber.next(preInitPaymentEvent);
      subscriber.complete();
    });
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    window.location.href = paymentModel.plainPayload;
    this.events.next(new WorkPaymentProviderEvent());
  }

  onLoadIFrame() {
    this.events.next(new DonePaymentProviderEvent());
  }
}
