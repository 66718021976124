import { Inject, Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS } from '../injection.tokens';
import { IRestApiOptions } from '../interfaces/restapi-options';

@Injectable({
  providedIn: 'root',
})
export class AcceptLanguageInterceptor implements HttpInterceptor {
  constructor(@Inject(POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS) protected options: IRestApiOptions) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const lang = sessionStorage.getItem(this.options.storageKey.lang);

    if (lang !== null) {
      req = req.clone({
        headers: req.headers.set('Accept-Language', lang),
      });
    }

    return next.handle(req);
  }
}
