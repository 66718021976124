import { CinemaHeadApiModel } from './cinema-head.api.model';

export class CinemaApiModel extends CinemaHeadApiModel {
  zipcode: string;

  reservationPhone: string;

  description: string;

  url1: string;

  url2: string;

  numberOfScreens: number;

  numberOfSeats: number;

  numberOfDisabledSeats: number;

  graphics: string[];

  email: string;

  reservationGroupPhone: string;

  fax: string;

  isAnyDreamScreen: boolean;

  timezone: string;
}
