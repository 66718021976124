export class CardTypeMembershipExtensionFeeApiModel {
  feeId: string;

  name: string;

  extensionTime: number;

  extensionType: number;

  feeValue: number;
}
