import { Injectable } from '@angular/core';
import { OrderStateService } from './order.state.service';
import { OrderCateringService, SelectedModifierItemMap } from './order-catering.service';
import { CateringService } from './catering.service';
import { cloneDeep } from 'lodash';
import { CateringAggregationArticleViewModel, CateringAggregationViewModel } from '@dinein-lib/restapi-plugin';
import { LoadingService } from '@dinein-lib/shared';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(
    private orderCateringService: OrderCateringService,
    private cateringService: CateringService,
    private orderStateService: OrderStateService,
    private loadingService: LoadingService
  ) {}

  addCart(item: CateringAggregationArticleViewModel) {
    const requestItem = cloneDeep(item);
    requestItem.basketItemId = requestItem.id;
    requestItem.selectedQuantity = 1;

    this.loadingService.showLoader();
    this.orderCateringService.post(requestItem).subscribe({
      next: (result) => {
        this.loadingService.hideLoader();
      },
      error: (err) => {
        this.orderStateService.onError(err);
      },
    });
  }

  patchQuantity(item: CateringAggregationArticleViewModel, quantity: number = 0): number {
    const requestItem = cloneDeep(item);

    const model = new CateringAggregationViewModel();
    model.articles = [requestItem];

    const combine = this.buildSelectedArticleCombinationList(model).find((o) =>
      requestItem.basketItemId ? o.basketItemId === requestItem.basketItemId : o.id === requestItem.id
    );

    requestItem.selectedQuantity += quantity;
    requestItem.basketItemId = combine?.basketItemId;

    this.loadingService.showLoader();
    const actionSource =
      requestItem.selectedQuantity === 0 ? this.orderCateringService.removeItem(requestItem) : this.orderCateringService.patchQuantityItem(requestItem);

    actionSource.subscribe({
      next: (result) => {
        this.loadingService.hideLoader();
      },
      error: (err) => {
        this.orderStateService.onError(err);
      },
    });

    return requestItem.selectedQuantity;
  }

  private buildSelectedArticleCombinationList(cateringAggregation: CateringAggregationViewModel) {
    const state = this.orderStateService.getState();
    if (!state?.order?.fbItems?.length) {
      return [];
    }

    const selectedMap: SelectedModifierItemMap = this.orderCateringService.buildSelectedMap(cateringAggregation, state.order.fbItems);

    if (!Object.keys(selectedMap).length) {
      return [];
    }

    const articleCombinationList: Array<CateringAggregationArticleViewModel> = [];

    for (const articleId of Object.keys(selectedMap)) {
      for (const mapping of selectedMap[articleId]) {
        const articleCombination: CateringAggregationArticleViewModel = this.cateringService.buildSelectedArticle(
          mapping.article,
          mapping.selectedQuantity,
          mapping.selectedModifierItemMap,
          mapping.selectedSubArticleMap
        );

        if (articleCombination) {
          articleCombinationList.push(articleCombination);
        }
      }
    }

    return articleCombinationList.map((x) => x);
  }
}
