import { FbItemMembershipPriceApiModel } from './fb-item-membership-price.api.model';
import { FbItemModifierItemApiModel } from './fb-item-modifier-item.api.model';
import { FbItemSubArticleApiModel } from './fb-item-sub-article.api.model';

export class FbItemApiModel {
  id: string;

  articleId: string;

  quantity: number;

  voucherNumber: string;

  voucherName: string;

  name: string;

  price: number;

  comboMinPrice: number;

  taxValue: number;

  pickupTime: string;

  defaultPriceLevelPrice: number;

  subArticleList: FbItemSubArticleApiModel[] = [];

  modifierItemList: FbItemModifierItemApiModel[] = [];

  itemEarnedPoints: number;

  itemRedemptionPoints: number;

  promotionId: string;

  promotionName: string;

  membershipPrices: FbItemMembershipPriceApiModel[] = [];

  graphicUrl: string;

  assignedSeatId: string;

  assignedScreeningId: string;
}
