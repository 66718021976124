import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserLoginRequestModel } from '../../../models/request/user-login.request.model';
import { UserLoginResponseModel } from '../../../models/response/user-login.response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingService } from '../../loading/loading.service';
import { OrderHelper } from '../../../helpers/order.helper';
import { ErrorHelper } from '../../../helpers/error-helper';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, tap } from 'rxjs';
import { AuthStateService, MembershipLoginDialogData, UserDataProvider } from '@dinein-lib/restapi-plugin';
import { NotificationService, OrderStateService } from '@dinein-lib/core';

@Component({
  selector: 'app-membership-login-dialog',
  templateUrl: './membership-login-dialog.component.html',
})
export class MembershipLoginDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MembershipLoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MembershipLoginDialogData,
    protected authStateService: AuthStateService,
    protected orderStateService: OrderStateService,
    protected orderHelper: OrderHelper,
    protected userDataProvider: UserDataProvider,
    protected loadingService: LoadingService,
    protected notificationService: NotificationService,
    private translation: TranslateService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  login() {
    this.loadingService.showLoader();

    const loginRequestModel: UserLoginRequestModel = new UserLoginRequestModel();
    loginRequestModel.username = this.data.email;
    loginRequestModel.password = this.data.password;
    loginRequestModel.rememberMe = false;

    this.userDataProvider.login(loginRequestModel).subscribe({
      next: (resultLogin) => this.handleToken(resultLogin),
      error: (error: HttpErrorResponse) => {
        this.notificationService.showError(this.translation.instant('error.' + ErrorHelper.getCode(error)));
        this.loadingService.hideLoader();
      },
    });
  }

  private handleToken(response: UserLoginResponseModel): void {
    this.authStateService
      .handleToken(response)
      .pipe(
        tap((data) => this.orderStateService.setPersonalData({ email: data.email, phone: data.phone })),
        switchMap(() => this.orderHelper.updateOrderMembershipCard())
      )

      .subscribe({
        next: (card) => {
          this.notificationService.show(this.translation.instant('app.components.dialogs.membership-login.success'));
          this.data.completed = true;
          this.data.card = card;

          this.loadingService.hideLoader();
          this.dialogRef.close(this.data);
        },
        error: (error) => {
          this.loadingService.hideLoader();
          this.notificationService.showError(ErrorHelper.getMessage(error));
          this.orderStateService.cleanState();
        },
      });
  }
}
