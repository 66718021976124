import { VoucherItemMembershipPriceApiModel } from './voucher-item-membership-price.api.model';

export class VoucherItemApiModel {
  orderItemId: string;

  id: string;

  itemName: string;

  itemId: string;

  quantity: number;

  itemTaxRate: number;

  itemPrice: number;

  value: number;

  bookingId: string;

  promotionId: string;

  promotionName: string;

  itemRedemptionPoints: number;

  membershipPrices: VoucherItemMembershipPriceApiModel[] = [];
}
