import { AbstractViewModel } from '../abstract.view.model';
import { PaymentProviderPayMethodApiModel } from '../api-model/payment-provider-pay-method.response.model';

export class PaymentProviderPayMethodViewModel extends AbstractViewModel<PaymentProviderPayMethodApiModel> {
  public id: string;
  public type: string;
  public subType: string | null;
  public name: string | null;
  public imageUrl: string | null;
  public minAmount: number | null;
  public maxAmount: number | null;
  public status: string | null;
  public isPreferred: boolean;
  public cardExpirationYear: string | null;
  public cardExpirationMonth: string | null;
  public cardMask: string | null;
  public cardBrand: string | null;
  public canBeShown = true;
  public canBeSaved = true;
  public token: string | null;

  constructor(apiModel: PaymentProviderPayMethodApiModel = new PaymentProviderPayMethodApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.type = this.apiModel.type;
    this.subType = this.apiModel.subType;
    this.name = this.apiModel.name;
    this.imageUrl = this.apiModel.imageUrl;
    this.minAmount = this.apiModel.minAmount;
    this.maxAmount = this.apiModel.maxAmount;
    this.status = this.apiModel.status;
    this.isPreferred = this.apiModel.isPreferred;
    this.cardExpirationYear = this.apiModel.cardExpirationYear;
    this.cardExpirationMonth = this.apiModel.cardExpirationMonth;
    this.cardMask = this.apiModel.cardMask;
    this.cardBrand = this.apiModel.cardBrand;
    this.canBeShown = this.apiModel.canBeShown;
    this.token = this.apiModel.token;
  }

  toApiModel(): PaymentProviderPayMethodApiModel {
    return undefined;
  }
}
