export interface ILoadingConfig {
  backdropBorderRadius?: string;
  backdropBackgroundColour?: string;
  fullScreenBackdrop?: boolean;
  animationType?: string;
  primaryColour?: string;
  secondaryColour?: string;
  tertiaryColour?: string;
  style?: string;
  size?: string;
  [key: string]: string | boolean | undefined;
}

export class LoadingConfig implements ILoadingConfig {
  backdropBorderRadius?: string;
  backdropBackgroundColour?: string;
  fullScreenBackdrop?: boolean;
  animationType?: string;
  primaryColour?: string;
  secondaryColour?: string;
  tertiaryColour?: string;
  style?: string;
  size?: string;
  [key: string]: string | boolean | undefined;

  constructor(config: ILoadingConfig = {}) {
    this.backdropBorderRadius = config.backdropBorderRadius;
    this.backdropBackgroundColour = config.backdropBackgroundColour;
    this.fullScreenBackdrop = config.fullScreenBackdrop;
    this.animationType = config.animationType;
    this.primaryColour = config.primaryColour;
    this.secondaryColour = config.secondaryColour;
    this.tertiaryColour = config.tertiaryColour;
    this.style = config.style;
    this.size = config.size;
  }
}

export const loadingAnimationTypes = {
  chasingDots: 'chasing-dots',
  circle: 'sk-circle',
  circleSwish: 'circleSwish',
  cubeGrid: 'sk-cube-grid',
  doubleBounce: 'double-bounce',
  none: 'none',
  pulse: 'pulse',
  rectangleBounce: 'rectangle-bounce',
  rotatingPlane: 'rotating-plane',
  threeBounce: 'three-bounce',
  wanderingCubes: 'wandering-cubes',
  simple: 'simple',
  custom: 'custom',
};

export const loadingStyle = {
  light: 'light',
  dark: 'dark',
};

export const loadingSize = {
  small: 'small',
  large: 'large',
};

export const defaultLoaderConfig: { [key: string]: LoadingConfig } = {
  blue: {
    animationType: loadingAnimationTypes.threeBounce,
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
    backdropBorderRadius: '0px',
    fullScreenBackdrop: true,
    primaryColour: '#0A71E9',
    secondaryColour: '#D9202E',
    tertiaryColour: '#ffffff',
  },
  helios: {
    animationType: loadingAnimationTypes.pulse,
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
    backdropBorderRadius: '0px',
    fullScreenBackdrop: true,
    primaryColour: '#F4BF02',
    secondaryColour: '#0182FC',
    tertiaryColour: '#ffffff',
  },
  cinestar: {
    animationType: loadingAnimationTypes.pulse,
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
    backdropBorderRadius: '0px',
    fullScreenBackdrop: true,
    primaryColour: '#F4BF02',
    secondaryColour: '#0182FC',
    tertiaryColour: '#ffffff',
  },
  cineland: {
    animationType: loadingAnimationTypes.pulse,
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
    backdropBorderRadius: '0px',
    fullScreenBackdrop: true,
    primaryColour: '#dbbf77',
    secondaryColour: '#830009',
    tertiaryColour: '#141414',
  },
  demo: {
    animationType: loadingAnimationTypes.cubeGrid,
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
    backdropBorderRadius: '0px',
    fullScreenBackdrop: true,
    primaryColour: '#0A71E9',
    secondaryColour: '#D9202E',
    tertiaryColour: '#ffffff',
  },
  default: {
    animationType: loadingAnimationTypes.pulse,
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
    backdropBorderRadius: '0px',
    fullScreenBackdrop: true,
    primaryColour: '#F4BF02',
    secondaryColour: '#0182FC',
    tertiaryColour: '#ffffff',
  },
};
