import { UserCardTypeTemplateApiModel } from './user-card-type-template.api.model';
import { UserCardTypeTypeApiModel } from './user-card-type-type.api.model';

export class UserCardTypeApiModel {
  id: string;

  type: number;

  typeName: string;

  description: string;

  graphic: string;

  multipleUse: boolean;

  rechargeable: boolean;

  expirtationDate: string;

  autoNumber: boolean;

  notLessThan: number;

  maximumBalance: number;

  graphicTemplateList: UserCardTypeTemplateApiModel[];

  types: UserCardTypeTypeApiModel[];
}
