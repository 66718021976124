export class CardApiModel {
  id: string;

  name: string;

  pointBalance: number;

  valueBalance: number;

  statusId: number;

  cardTypeId: string;

  clientId: string;

  status: boolean;

  issueDate: string;

  salesChannelId: string;

  cardBatchId: string;

  flgUseInLocal: number;

  cinemaId: string;

  expireDate: string;

  message: string;

  templateId: string;

  isHidden: boolean;

  type: number;
}
