import { Exclude, Expose } from 'class-transformer';
import { AgreementGroupApiModel } from './agreement-group.api.model';
import { AgreementApiModel } from './agreement.api.model';

@Exclude()
export class AgreementAggregationApiModel {
  marketingAgreements: AgreementApiModel[];

  marketingAgreementGroups: AgreementGroupApiModel[];
}
