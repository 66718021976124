import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { BaseHttpService } from '../http/base.http.service';
import { CinemaApiModel } from '../models/api-model/cinema/cinema.api.model';
import { ScreeningApiModel } from '../models/api-model/screening/screening.api.model';
import { ScreeningRequestModel } from '../models/request/screening.request.model';
import { CinemaViewModel } from '../models/view-model/cinema/cinema.view.model';
import { MovieViewModel } from '../models/view-model/movie/movie.view.model';
import { ScreenViewModel } from '../models/view-model/screen/screen.view.model';
import { ScreeningViewModel } from '../models/view-model/screening/screening.view.model';
import { TableViewModel } from '../models/view-model/table/table.view.model';

@Injectable({
  providedIn: 'root',
})
export class BaseDataProvider {
  constructor(private httpService: BaseHttpService) {}

  public getCinemas() {
    return this.httpService.getCinemas().pipe(map((models: CinemaApiModel[]) => models.map((model: CinemaApiModel) => new CinemaViewModel(model))));
  }

  getCinema(id: string): Observable<CinemaViewModel> {
    return this.httpService.getCinema(id).pipe(map((res) => new CinemaViewModel(res)));
  }

  getMovie(id: string) {
    return this.httpService.getMovie(id).pipe(map((result) => new MovieViewModel(result)));
  }

  getScreen(cinemaId: string, screenId: string): Observable<ScreenViewModel> {
    return this.httpService.getScreen(cinemaId, screenId).pipe(map((result) => new ScreenViewModel(result)));
  }

  getTable(cinemaId: string, number: string) {
    return this.httpService.getTable(cinemaId, number).pipe(map((result) => new TableViewModel(result)));
  }

  getScreenings(screeningRequestModel: ScreeningRequestModel) {
    return this.httpService
      .getScreenings(screeningRequestModel)
      .pipe(map((models: ScreeningApiModel[]) => models.map((model: ScreeningApiModel) => new ScreeningViewModel(model))));
  }

  public findScreening(cinemaId: string, seatId: string) {
    const dt = DateTime.local();
    const screeningRequestModel = new ScreeningRequestModel().deserialize(cinemaId, dt);
    return this.getScreenings(screeningRequestModel).pipe(
      mergeMap((screenings) => {
        return screenings.filter((o) => {
          return o.screeningTimeFrom <= dt && o.screeningTimeTo > dt;
        });
      }),
      mergeMap((screening) => {
        return forkJoin({
          screening: of(screening),
          screen: this.getScreen(cinemaId, screening.screenId),
          movie: this.getMovie(screening.movieId),
        });
      }),
      toArray(),
      map((groups) => {
        const group = groups.find((a) => a.screen.seats.some((seat) => seat.id === seatId));
        if (group) {
          const seat = group.screen.seats.find((o) => o.id === seatId);
          const row = group.screen.rows.find((r) => r.id === seat.rowId);
          const col = group.screen.cols.find((c) => c.id === seat.colId);

          return {
            movieTitle: group.movie.title,
            screenName: group.screen.screenName,
            seat: row.toString() + col.toString(),
            screeningId: group.screening.id,
            screeningTime: group.screening.screeningTimeFrom.toFormat('HH:mm'),
          };
        } else {
          return null;
        }
      })
    );
  }
}
