import { AppConfigService, AppInstallUpdateService } from '@dinein-lib/core';
import { environment } from '../environments/environment';

export function preloadConfigServiceFactory(configService: AppConfigService) {
  return () => configService.load(environment);
}

export function preloadInstallUpdateServiceFactory(installService: AppInstallUpdateService) {
  return () => installService.init();
}
