import { Component, Inject, Input } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '@dinein-lib/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
})
export class LogoComponent {
  @Input() hide = false;

  public logo: string;
  public sublogo: string;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {
    this.logo = environment.brand.fullLogo;
    this.sublogo = environment.brand.subLogo;
  }
}
