import { GenreApiModel } from '../genre/genre.api.model';
import { BannerApiModel } from '../banner.api.model';
import { RatingApiModel } from '../rating.api.model';
import { TagGroupApiModel } from '../tag-group.api.model';
import { BrightcoveVideoApiModel } from './brightcove-video.api.model';

export class MovieApiModel {
  id: string;

  duration: number;

  description: string;

  ageLongDescription: string;

  releaseDate: string;

  distributor: string;

  distributorId: string;

  genres: GenreApiModel[];

  trailers: string[];

  posters: string[];

  pictures: string[];

  posPosters: string[];

  banners: BannerApiModel[];

  ratings: RatingApiModel[];

  title: string;

  shortTitle: string;

  originalTitle: string;

  onlineTitle: string;

  filmCast: string;

  scenario: string;

  isForChildren: boolean;

  yearOfProduction: string;

  director: string;

  shortDescription: string;

  premiereDate: string;

  country: string;

  originalLanguage: string;

  originalTitleLanguage: string;

  imdbId: string;

  imdbRating: string;

  type: string;

  worldPremiereDate: string;

  tagGroups: TagGroupApiModel[];

  brightcoveVideos: BrightcoveVideoApiModel[];
}
