import { AbstractViewModel } from '../../../abstract.view.model';
import { DefaultExtraFeeMembershipPriceApiModel } from '../../../api-model/order/default-extra-fee/default-extra-fee-membership-price.api.model';

export class DefaultExtraFeeMembershipPriceViewModel extends AbstractViewModel<DefaultExtraFeeMembershipPriceApiModel> {
  public isAppliedOnOrder: boolean;
  public membershipLevel: number;
  public membershipCardTypeId: string;
  public membershipDefaultExtraFeePrice: number;

  constructor(apiModel: DefaultExtraFeeMembershipPriceApiModel = new DefaultExtraFeeMembershipPriceApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.isAppliedOnOrder = this.apiModel.isAppliedOnOrder;
    this.membershipLevel = this.apiModel.membershipLevel;
    this.membershipCardTypeId = this.apiModel.membershipCardTypeId;
    this.membershipDefaultExtraFeePrice = this.apiModel.membershipDefaultExtraFeePrice;
  }

  toApiModel(): DefaultExtraFeeMembershipPriceApiModel {
    return undefined;
  }
}
