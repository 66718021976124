export class CardTypeItemMembershipPriceApiModel {
  isAppliedOnOrder: boolean;

  membershipLevel: number;

  membershipCardTypeId: string;

  membershipItemPrice: number;

  membershipChargeValue: number;
}
