<div>
  <app-logo></app-logo>
</div>

<mat-card class="my-5 mx-2">
  <mat-card-content>
    <h5 class="group-label" *ngIf="title; else defaultTitleTemplate">
      {{title}}
    </h5>

    <div class="px-0 py-3">
      {{content}}
    </div>

    <div class="row">
      <div class="col-12">
        <button class="action-button" mat-raised-button color="primary" (click)="goAction()">
          {{ 'button.back' | translate }}
        </button>
      </div>
    </div>

  </mat-card-content>
</mat-card>

<ng-template #defaultTitleTemplate>
  <h5 class="group-label">{{ 'error.default.title' | translate }}</h5>
</ng-template>