import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InitialParams, OrderStateService } from '../../services/order.state.service';
import { ErrorHelper, LoadingService } from '@dinein-lib/shared';
import { storageKey } from '../../app.consts';
import { BaseDataProvider } from '@dinein-lib/restapi-plugin';
import { iif, map, of, switchMap, tap } from 'rxjs';
import { NavigationService } from '../../services/navigation/navigation.service';

@Component({
  template: '',
})
export class RoutingHubComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private orderStateService: OrderStateService,
    private loadingServide: LoadingService,
    private baseDataProvider: BaseDataProvider,
    private navigationService: NavigationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const params = this.route.snapshot.params;
    const queryParams = this.route.snapshot.queryParams;
    const initialParams = new InitialParams(params['cinemaId']).set(queryParams);
    if (initialParams.cinemaId) {
      this.loadingServide.showLoader();

      this.orderStateService.removeSeat();
      this.orderStateService.removeScreening();

      iif(
        () => initialParams.cinemaId === 'first',
        this.baseDataProvider.getCinemas().pipe(
          map((cinemas) => {
            if (cinemas?.length) {
              initialParams.cinemaId = cinemas[0].id;
            }

            return initialParams;
          })
        ),
        of(initialParams)
      )
        .pipe(
          tap(() => sessionStorage.setItem(storageKey.welcomePage, 'true')),
          switchMap(() => this.orderStateService.initByParams(initialParams))
        )
        .subscribe({
          next: () => {
            this.router.navigate(['offer']);
          },
          error: (err) => {
            const error = ErrorHelper.getError(err);
            this.navigationService.goToMessagePage(error.code);
          },
        });
      return;
    }

    if (initialParams.hostedPaymentStatus) {
      if (window.parent) {
        window.parent.location.href = `/payment/${initialParams.hostedPaymentStatus}`;
      } else {
        window.location.href = `/payment/${initialParams.hostedPaymentStatus}`;
      }
      return;
    }

    this.router.navigate(['offer']);
  }
}
