import { DateTime } from 'luxon';
import { GenreViewModel } from '../genre/genre.view.model';
import { BannerViewModel } from '../banner.view.model';
import { TagGroupViewModel } from '../tag-group.view.model';
import { RatingViewModel } from '../rating.view.model';
import { AbstractViewModel } from '../../abstract.view.model';
import { MovieApiModel } from '../../api-model/movie/movie.api.model';

export class MovieViewModel extends AbstractViewModel<MovieApiModel> {
  id: string;
  duration: number;
  description: string;
  ageLongDescription: string;
  releaseDate: DateTime;
  distributorId: string;
  distributor: string;
  genres: GenreViewModel[];
  trailers: string[];
  posters: string[];
  pictures: string[];
  posPosters: string[];
  banners: BannerViewModel[];
  ratings: RatingViewModel[];
  title: string;
  shortTitle: string;
  originalTitle: string;
  onlineTitle: string;
  filmCast: string;
  scenario: string;
  isForChildren: boolean;
  yearOfProduction: string;
  director: string;
  shortDescription: string;
  premiereDate: DateTime;
  country: string;
  originalLanguage: string;
  originalTitleLanguage: string;
  imdbId: string;
  imdbRating: string;
  type: string;
  worldPremiereDate: DateTime;
  tagGroups: TagGroupViewModel[];

  constructor(apiModel: MovieApiModel = new MovieApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.duration = this.apiModel.duration;
    this.description = this.apiModel.description;
    this.ageLongDescription = this.apiModel.ageLongDescription;
    this.releaseDate = this.apiModel.releaseDate ? DateTime.fromISO(this.apiModel.releaseDate) : null;
    this.distributorId = this.apiModel.distributorId;
    this.distributor = this.apiModel.distributor;
    this.genres = this.apiModel.genres ? this.apiModel.genres.map((genre) => new GenreViewModel(genre)) : [];
    this.trailers = this.apiModel.trailers ?? [];
    this.posters = this.apiModel.posters ?? [];
    this.pictures = this.apiModel.pictures ?? [];
    this.posPosters = this.apiModel.posPosters;
    this.banners = this.apiModel.banners ? this.apiModel.banners.map((banner) => new BannerViewModel(banner)) : [];
    this.ratings = this.apiModel.ratings ? this.apiModel.ratings.map((rating) => new RatingViewModel(rating)) : [];
    this.title = this.apiModel.title;
    this.shortTitle = this.apiModel.shortTitle;
    this.originalTitle = this.apiModel.originalTitle;
    this.onlineTitle = this.apiModel.onlineTitle;
    this.filmCast = this.apiModel.filmCast;
    this.scenario = this.apiModel.scenario;
    this.isForChildren = this.apiModel.isForChildren;
    this.yearOfProduction = this.apiModel.yearOfProduction;
    this.director = this.apiModel.director;
    this.shortDescription = this.apiModel.shortDescription;
    this.premiereDate = this.apiModel.premiereDate ? DateTime.fromISO(this.apiModel.premiereDate) : null;
    this.country = this.apiModel.country;
    this.originalLanguage = this.apiModel.originalLanguage;
    this.originalTitleLanguage = this.apiModel.originalTitleLanguage;
    this.imdbId = this.apiModel.imdbId;
    this.imdbRating = this.apiModel.imdbRating;
    this.type = this.apiModel.type;
    this.worldPremiereDate = this.apiModel.premiereDate ? DateTime.fromISO(this.apiModel.worldPremiereDate) : null;
    this.tagGroups = this.apiModel.tagGroups ? this.apiModel.tagGroups.map((tagGroup) => new TagGroupViewModel(tagGroup)) : [];
  }

  toApiModel(): MovieApiModel {
    return undefined;
  }

  get isEvent(): boolean {
    return false;
  }

  get firstPosterOrDefault(): string {
    return this.posters.length ? this.posters[0] : null;
  }

  get postersOrDefaults(): string[] {
    return this.posters.length ? this.posters : [];
  }

  get ageRestriction(): string {
    if (!this.ratings || this.ratings.length === 0) {
      return '-';
    }

    return this.ratings.map((o) => o.value).join('/');
  }

  get slug(): string {
    if (!this.shortTitle) {
      return '';
    }

    const searchRegExp = /\s/g;
    return this.shortTitle.toLowerCase().replace(searchRegExp, '-');
  }

  get countryYear(): string {
    return this.country + ' ' + DateTime.fromISO(this.yearOfProduction).toFormat('yyyy');
  }

  get genreNames(): string {
    return this.genres.map((o) => o.name).join(', ');
  }
}
