import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DonePaymentProviderEvent } from '../../event/done-payment-provider.event';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { WorkPaymentProviderEvent } from '../../event/work-payment-provider.event';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { PaymentConfigViewModel, PaymentViewModel } from '@dinein-lib/restapi-plugin';
import { ENVIRONMENT_TOKEN } from '@dinein-lib/core';

@Component({
  selector: 'app-payment-provider-corvuspay-component',
  templateUrl: './corvuspay-payment-provider.component.html',
})
export class CorvusPayPaymentProviderComponent implements PaymentProviderComponentInterface {
  @Output() public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public readonly paymentProviderIdentifier: string = 'corvuspay';
  public paymentProviderConfig: PaymentConfigViewModel | null;
  public resourceUrl: string = null;
  public returnUrl: string = null;
  private readonly paymentChannel: string | null = null;

  public constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {
    this.paymentChannel = environment.payment?.channel;
  }

  onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    return new Observable<PaymentPreInitModel>((subscriber) => {
      const preInitPaymentEvent: PaymentPreInitModel = new PaymentPreInitModel();
      subscriber.next(preInitPaymentEvent);
      subscriber.complete();
    });
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    this.resourceUrl = paymentModel.plainPayload;
    this.events.next(new WorkPaymentProviderEvent());

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', paymentModel.plainPayload);
    form.setAttribute('target', '_top');
    document.body.appendChild(form);
    form.submit();
  }

  public onLoad() {
    this.events.next(new DonePaymentProviderEvent());
  }
}
