import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ScreeningDetailsApiModel } from '../models/api-model/screening-details/screening-details.api.model';
import { POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS } from '../injection.tokens';
import { IRestApiOptions } from '../interfaces/restapi-options';

@Injectable({
  providedIn: 'root',
})
export class DineinHttpService {
  constructor(@Inject(POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS) protected options: IRestApiOptions, private http: HttpClient) {}

  getCinemaSeatScreeningDetails(cinemaId: string, seatId: string): Observable<ScreeningDetailsApiModel> {
    return this.http.get<ScreeningDetailsApiModel>(`/dinein/cinema/${cinemaId}/seat/${seatId}/screeningDetails`, {
      headers: new HttpHeaders({
        Authorization: this.options.environment.api.securityKey,
      }),
    });
  }
}
