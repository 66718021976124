export class UserApiModel {
  public id?: string;

  public email: string;

  public login: string;

  public firstName: string;

  public lastName: string;

  public street: string;

  public streetNumber: string;

  public postalCode: string;

  public city: string;

  public phone: string;

  public nick: string;

  public birthday: string;

  public plainPassword: string;

  public agreements: string[] = new Array<string>();

  public gender: string;

  public avatar: string;

  public twoFactorAuthCode: string;
}
