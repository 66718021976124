import { AbstractViewModel } from '../../abstract.view.model';
import { GenreApiModel } from '../../api-model/genre/genre.api.model';

export class GenreViewModel extends AbstractViewModel<GenreApiModel> {
  public id: string = null;
  public name: string = null;
  public description: string = null;

  constructor(apiModel: GenreApiModel = new GenreApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
  }

  toApiModel(): GenreApiModel {
    return undefined;
  }
}
