import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS } from '../injection.tokens';
import { IRestApiOptions } from '../interfaces/restapi-options';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(@Inject(POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS) protected options: IRestApiOptions) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('.json') === -1) {
      req = req.clone({ url: req.url.startsWith('http') ? req.url : this.options.environment.api.url + req.url });
    }

    if (!req.headers.has('no-content-type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json'),
      });
    }

    return next.handle(req);
  }
}
