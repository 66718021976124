import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { CustomState, SearcherService } from '@dinein-lib/core';
import { CateringAggregationArticleGroupViewModel, CateringAggregationArticleViewModel } from '@dinein-lib/restapi-plugin';

@Component({
  template: '',
})
export class SearchResultComponent implements OnInit {
  @Input() public groups: CateringAggregationArticleGroupViewModel[] = [];
  @Input() public orderItems: CateringAggregationArticleViewModel[] = [];
  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(public searcherService: SearcherService) {}

  ngOnInit(): void {
    if (!this.searcherService.properties?.length) {
      this.searcherService.properties = [
        ...this.groups.map((group) => {
          return new CustomState(group.id);
        }),
      ];
    }
  }

  updatedArticle(article: CateringAggregationArticleViewModel) {
    return this.orderItems.find((o) => o.id === article.id && o.parentGroup?.id === article.parentGroup?.id) ?? article;
  }

  propertyChange(id: string) {
    this.searcherService.properties.forEach((state) => {
      if (state.id === id) {
        state.expand = state.expand ? false : true;
      }
    });
  }

  public isExp(groupId: string) {
    const state = this.searcherService.properties.find((state) => state.id === groupId);
    return state?.expand;
  }

  public expandAll() {
    this.accordion?.openAll();
  }
  public collapsAll() {
    this.accordion?.closeAll();
  }
  public hasAnyItems() {
    return this.groups?.some((g) => g.articles?.length > 0);
  }
}
