import { AbstractViewModel } from '../abstract.view.model';
import { TagApiModel } from '../api-model/tag.api.model';

export class TagViewModel extends AbstractViewModel<TagApiModel> {
  orderNo: number;
  symbol: string;
  description: string;

  constructor(apiModel: TagApiModel = new TagApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.orderNo = this.apiModel.orderNo;
    this.symbol = this.apiModel.symbol;
    this.description = this.apiModel.description;
  }

  toApiModel(): TagApiModel {
    return undefined;
  }
}
