import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  back(): void {
    window.history.back();
  }

  locationHref(href: string = '/'): void {
    window.location.href = href;
  }

  goToMessagePage(errorCode: number) {
    this.locationHref(`/message/${errorCode}`);
  }
}
