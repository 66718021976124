import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { ENVIRONMENT_TOKEN } from '@dinein-lib/core';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, @Inject(LOCALE_ID) private _localeId: string) {}

  transform(value: number, currencyCode: string = this.environment.globalize.currency, digitsInfo?: string): string | null {
    return formatCurrency(value, this._localeId, getCurrencySymbol(currencyCode, 'wide') + ' ', currencyCode, digitsInfo);
  }
}
