import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IosInstallComponent, ScannerNoAccessComponent, UpdateDialogComponent } from '@dinein-lib/shared';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  private _configState: Subscription;

  constructor(private toast: MatSnackBar, public dialog: MatDialog) {}

  ngOnDestroy() {
    this._configState.unsubscribe();
  }

  show(message: string, buttonLabel: string = 'OK', config: MatSnackBarConfig = null): MatSnackBarRef<any> {
    if (!config) {
      config = {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 5000,
        panelClass: ['success-snackbar'],
      };
    }
    return this.toast.open(message, buttonLabel, config);
  }

  showError(message: string) {
    return this.toast.open(message, null, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 8000,
      panelClass: ['error-snackbar'],
    });
  }

  showIosInstall() {
    this.toast.openFromComponent(IosInstallComponent, {
      duration: 8000,
      horizontalPosition: 'start',
      panelClass: ['mat-elevation-z3'],
    });
  }

  showScannerNoAccess() {
    this.toast.openFromComponent(ScannerNoAccessComponent, {
      duration: 8000,
      horizontalPosition: 'start',
      panelClass: ['mat-elevation-z3'],
    });
  }

  showWithAction(message: string): MatSnackBarRef<any> {
    return this.show(message, 'OK', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['info-snackbar'],
    });
  }

  openDialog(reason: string, trigger: any): void {
    const dialogRef = this.dialog.open(UpdateDialogComponent, { data: { reason: reason } });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result.completed, trigger);
      if (result.completed && trigger) {
        trigger();
      }
    });
  }
}
