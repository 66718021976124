import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserCardTypeApiModel } from '../../../api-model/user-history/card-type/user-card-type.api.model';
import { UserCardTypeTemplateViewModel } from './user-card-type-template.view.model';
import { UserCardTypeTypeViewModel } from './user-card-type-type.view.model';

export class UserCardTypeViewModel extends AbstractViewModel<UserCardTypeApiModel> {
  public id: string = null;
  public type: number = null;
  public typeName: string = null;
  public description: string = null;
  public graphic: string = null;
  public multipleUse: boolean = null;
  public rechargeable: boolean = null;
  public expirtationDate: DateTime = null;
  public autoNumber: boolean = null;
  public notLessThan: number = null;
  public maximumBalance: number = null;
  public graphicTemplateList: UserCardTypeTemplateViewModel[] = [];
  public types: UserCardTypeTypeViewModel[] = [];

  public canBeIssued: boolean = null;

  constructor(apiModel: UserCardTypeApiModel = new UserCardTypeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.type = this.apiModel.type;
    this.typeName = this.apiModel.typeName;
    this.description = this.apiModel.description;
    this.graphic = this.apiModel.graphic;
    this.multipleUse = this.apiModel.multipleUse;
    this.rechargeable = this.apiModel.rechargeable;
    this.expirtationDate = this.apiModel.expirtationDate ? DateTime.fromISO(this.apiModel.expirtationDate) : null;
    this.autoNumber = this.apiModel.autoNumber;
    this.notLessThan = this.apiModel.notLessThan;
    this.maximumBalance = this.apiModel.maximumBalance;
    this.graphicTemplateList = this.apiModel.graphicTemplateList
      ? this.apiModel.graphicTemplateList.map((graphicTemplate) => new UserCardTypeTemplateViewModel(graphicTemplate))
      : [];
    this.types = this.apiModel.types ? this.apiModel.types.map((type) => new UserCardTypeTypeViewModel(type)) : [];
  }

  toApiModel(): UserCardTypeApiModel {
    return undefined;
  }
}
