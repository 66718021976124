<div class="row">
  <div class="col-12 my-3 d-flex flex-column gap-1">
    <div *ngIf="value.length>1" class="form-group align-items-center mb-3">
      <div class="checkbox">
        <label [for]="checkAll.id">
          <input type="checkbox" class="form-checkbox form-radio" [id]="checkAll.id" [(ngModel)]="checkAll.checked"
                 (change)="setAll()" [disabled]="disabled">
          <span [className]="checkAll.checked ? 'checkbox-active cr' : 'cr'">
            <i class="cr-icon fa fa-check"></i>
          </span>
          <span class="label-content-big">{{'app.page.order.agreements.checkAll' | translate}}</span>
        </label>
      </div>
    </div>
    <div class="form-group align-items-center" *ngFor="let item of value">
      <div class="checkbox">
        <label [for]="item.id">
          <input type="checkbox" class="form-checkbox form-radio" [id]="item.id" [(ngModel)]="item.checked"
                 (ngModelChange)="updateCheckAll()" [disabled]="disabled">
          <span [className]="item.checked ? 'checkbox-active cr' : 'cr'">
            <i class="cr-icon fa fa-check"></i>
          </span>
          <span class="d-flex align-items-center label-content">
            <span *ngIf="item.required" class="required">*&nbsp;</span>
            <span class="label-content" [innerHTML]="item.content"></span>
          </span>
        </label>
      </div>
    </div>
  </div>
</div>