import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { ScreeningDetailsApiModel } from '../../api-model/screening-details/screening-details.api.model';
import { TagGroupViewModel } from '../tag-group.view.model';
import { SeatViewModel } from './seat.view.model';

export class ScreeningDetailsViewModel extends AbstractViewModel<ScreeningDetailsApiModel> {
  screeningId: string;
  screeningTimeFrom: DateTime;
  screeningTimeTo: DateTime;
  screeningRuntime: number;
  screeningName: string;
  screenName: string;
  screenNumber: number;

  seats: SeatViewModel[];
  posters: string[];
  tagGroups: TagGroupViewModel[];

  constructor(apiModel: ScreeningDetailsApiModel = new ScreeningDetailsApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    if (!this.apiModel) {
      return;
    }

    this.screeningId = this.apiModel.screeningId;
    this.screeningTimeFrom = this.apiModel.screeningTimeFrom ? DateTime.fromISO(this.apiModel.screeningTimeFrom, { setZone: true }) : null;
    this.screeningTimeTo = this.apiModel.screeningTimeTo ? DateTime.fromISO(this.apiModel.screeningTimeTo, { setZone: true }) : null;
    this.screeningRuntime = this.apiModel.screeningRuntime;
    this.screeningName = this.apiModel.screeningName;
    this.screenName = this.apiModel.screenName;
    this.screenNumber = this.apiModel.screenNumber;

    this.seats = this.apiModel.seats.map((model) => new SeatViewModel(model));
    this.posters = this.apiModel.posters;
    this.tagGroups = this.apiModel.tagGroups.map((model) => new TagGroupViewModel(model));
  }

  toApiModel(): ScreeningDetailsApiModel {
    return undefined;
  }
}
