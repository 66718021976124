export class ScreeningApiModel {
  id: string;

  cinemaId: string;

  screenId: string;

  moviePrintId: string;

  movieExternalId: string;

  moviePrintExternalId: string;

  screeningTimeFrom: string;

  screeningTimeTo: string;

  screeningDuration: number;

  audience: number;

  generalAdmission: boolean;

  saleTimeTo: string;

  reservationTimeTo: string;

  isScreenSwapping: boolean;

  availabilityStatus: number;

  maxOccupancy: number;

  movieId: string;

  printType: string;

  speakingType: string;

  language: string;

  subtitles: string;

  subtitles2: string;

  soundType: string;

  release: string;

  format: string;

  resolution: string;

  frameRate: string;
}
