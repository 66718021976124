import * as _ from 'lodash';
import { CateringArticleModel } from './catering.model';
import { ModifierTypeEnum } from './modifier-type.enum';
import { NutritionalInfoModel } from './nutritional-info.response.model';

export class CateringAggregationViewModel {
  groups: CateringAggregationArticleGroupViewModel[] = [];
  articles: CateringAggregationArticleViewModel[] = [];
}

export class CateringAggregationArticleGroupViewModel {
  id: string;
  name?: string;
  description?: string;
  parentGroup?: CateringAggregationArticleGroupViewModel;
  graphicUrl?: string;

  hasOrderBox = false;
  articles: CateringAggregationArticleViewModel[] = [];
}

export class CateringAggregationArticleViewModel {
  id: string;
  basketItemId?: string;
  parentGroup?: CateringAggregationArticleGroupViewModel;
  name?: string;
  comboMinPrice?: number;
  defaultPrice?: number;
  price: number;
  taxRate: number;
  description?: string;
  voucherName?: string;
  voucherNumber?: string;
  isLocked: boolean;
  graphicUrl?: string;
  graphicIsLoaded = false;
  subArticleList: CateringAggregationArticleViewModel[] = [];
  replacementList: CateringAggregationArticleViewModel[] = [];
  modifierArticleList: CateringAggregationArticleModifierViewModel[] = [];
  selectedQuantity: number | null = null;
  selectedCombinationHash: string | null = null;
  nutritionalInfo?: NutritionalInfoModel;

  origin: CateringArticleModel;
  orderArticles: CateringAggregationArticleViewModel[] = [];

  public get calculatedPrice() {
    let price = this.price || 0;
    this.subArticleList?.forEach((subArticle) => {
      price += subArticle.price;
    });
    return price;
  }

  public get discountPrice() {
    if (this.defaultPrice !== null && this.defaultPrice !== undefined) {
      return this.price !== this.defaultPrice ? { before: this.defaultPrice, after: this.price } : null;
    }
    if (this.orderArticles?.length) {
      const price = _.sum(this.orderArticles.map((i) => i.price * i.selectedQuantity));
      const defaultPrice = _.sum(this.orderArticles.map((i) => i.defaultPrice * i.selectedQuantity));
      return price !== defaultPrice ? { before: defaultPrice, after: price } : null;
    }
    return null;
  }
}

export class CateringAggregationArticleModifierViewModel {
  id: string;
  type?: string;
  name?: string;
  isRequired: boolean;
  multiChoice: boolean;
  multiChoiceMax: number;
  multiChoiceMin: number;
  separateItem: boolean;
  itemCollection: CateringAggregationArticleModifierItemViewModel[] = [];
}

export class CateringAggregationArticleModifierItemViewModel {
  id: string;
  name?: string;
  description?: string;
  price: number;
  type?: string;
  quantity: number;
  relatedModifiers: CateringAggregationArticleModifierViewModel[] = [];

  isNoTypeModifierItem(): boolean {
    return this.type === ModifierTypeEnum.NO_TYPE;
  }
}
