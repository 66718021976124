<h2 mat-dialog-title>{{'app.components.dialogs.membership-login.enter-password' | translate }}</h2>
<mat-dialog-content class="mat-typography">
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'app.components.dialogs.membership-login.password' | translate }}</mat-label>
        <input type="password" matInput [(ngModel)]="data.password" autocomplete="off">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-raised-button color="accent"
            (click)="onNoClick()">{{'button.no-thanks' | translate }}</button>
    <button mat-button mat-raised-button color="primary" (click)="login()"
            cdkFocusInitial>{{'button.login' | translate }}</button>
</mat-dialog-actions>