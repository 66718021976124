import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrderPaymentRequestModel } from '../models/order-payment.request.model';
import { OrderStateModel } from '../models/order.state.model';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { OrderDataProvider, PaymentViewModel } from '@dinein-lib/restapi-plugin';
import { AppHelper, PaymentHelperService } from '@dinein-lib/shared';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private embeddedContent: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public embeddedContent$: Observable<string | null>;
  public env;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private paymentHelperService: PaymentHelperService,
    private orderDataProvider: OrderDataProvider
  ) {
    this.env = environment;
    this.embeddedContent$ = this.embeddedContent.asObservable();
  }

  public getOrderPayment(orderState: OrderStateModel) {
    return this.orderDataProvider.getOrderPayment(this.createPaymentRequest(orderState.cinemaId, orderState.orderId)).pipe(
      tap((res: PaymentViewModel) => {
        this.paymentHelperService.setEmbededPaymentUrl(res.plainPayload);
        this.setEmbeddedContent(res.plainPayload);
      })
    );
  }

  public createPaymentRequest(cinemaId: string, orderId: string): OrderPaymentRequestModel {
    const orderPaymentRequest: OrderPaymentRequestModel = new OrderPaymentRequestModel();
    orderPaymentRequest.cinemaId = cinemaId;
    orderPaymentRequest.orderId = orderId;
    orderPaymentRequest.paymentChannel = this.env.payment.channel;
    orderPaymentRequest.paymentProviderIdentifier = this.env.payment.provider;

    if (orderPaymentRequest.paymentProviderIdentifier === 'fake') {
      orderPaymentRequest.intPayMethodType = 'card';
      orderPaymentRequest.intPayMethodValue = 'NDExMTExMTExMTExMTExMTsxMi8yMzsxMjM7YXNkYXNkbm1rb3BuaW9oaGlvaGlvMDs2MDAwMDthc2Q=';
      orderPaymentRequest.paymentProviderIdentifier = 'basys';
    }

    //orderPaymentRequest.paymentData = preInitPaymentEvent.paymentData;
    orderPaymentRequest.continueUrl = AppHelper.generateContinueUrl;

    return orderPaymentRequest;
  }

  public setEmbeddedContent(url: string | null = null) {
    this.embeddedContent.next(url ?? this.paymentHelperService.getEmbededPaymentUrl());
  }
}
