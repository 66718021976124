export class TableApiModel {
  public orderId: string;

  public tableId: string;

  public tableName: string;

  public tableNumber: number;

  public hallName: string;
}
