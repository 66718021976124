import { CardItemMembershipPriceApiModel } from './card-item-membership-price.api.model';
import { CardItemShippingAddressApiModel } from './card-item-shipping-address.api.model';

export class CardItemApiModel {
  id: string;

  cardId: string;

  cardTypeId: string;

  cardBatchId: string;

  cardBatchName: string;

  cardPictureUrl: string;

  name: string;

  batchCardUnlimited: boolean;

  value: number;

  promotionId: string;

  promotionName: string;

  shippingAddress: CardItemShippingAddressApiModel;

  membershipPrices: CardItemMembershipPriceApiModel[] = [];
}
