import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthStateService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class JwtTokenInterceptor implements HttpInterceptor {
  constructor(private authStateService: AuthStateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.authStateService.getToken();
    if (token !== null) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token),
      });
    }

    return next.handle(req);
  }
}
