import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderStateService } from '../../services/order.state.service';
import { PaymentStatusTypes } from '../../models/payment-status-type.model';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { blockBack } from '../../app.functions';
import { AuthStateService, FbItemViewModel, OrderDataProvider, OrderHttpService, OrderStatus } from '@dinein-lib/restapi-plugin';
import { LoadingService } from '@dinein-lib/shared';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment.component.html',
})
export class PaymentPageComponent implements OnInit, OnDestroy {
  public currentTry = 0;
  public maxTriesAmount = 6;

  public status = '_';
  private queryParamsSubscription: Subscription = Subscription.EMPTY;
  public paymentStatusTypes: typeof PaymentStatusTypes = PaymentStatusTypes;

  public cinemaId: string = null;
  public orderId: string = null;
  public orderNumber: string = null;

  public items: FbItemViewModel[] = [];

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected orderStateService: OrderStateService,
    private authStateService: AuthStateService,
    private loadingServide: LoadingService,
    private orderDataProvider: OrderDataProvider,
    private route: ActivatedRoute,
    protected router: Router
  ) {}

  ngOnInit(): void {
    blockBack();
    this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
      if (params['cinemaId'] && params['orderId']) {
        this.cinemaId = params['cinemaId'];
        this.orderId = params['orderId'];
      } else if (params['params']) {
        const queryParams = params['params'].split(',');
        if (queryParams.length < 2) {
          return;
        }

        this.orderId = queryParams[0];
        this.cinemaId = queryParams[1];
      }

      this.checkOrderStatus();
    });
  }

  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  public checkOrderStatus(): void {
    if (!this.cinemaId || !this.orderId) {
      console.error('Invalid data');
      this.orderStateService.cleanState(true);
      return;
    }

    this.loadingServide.showLoader();
    OrderHttpService.cacheBuster$.next();

    const token = this.authStateService.getToken();
    console.log(token);

    setTimeout(() => {
      this.orderDataProvider.getOrder(this.cinemaId, this.orderId).subscribe((orderSimple) => {
        this.currentTry++;

        switch (orderSimple.status) {
          case OrderStatus.FINISHED:
            this.orderStateService.cleanState(true);
            this.status = PaymentStatusTypes.success;
            this.orderNumber = orderSimple.orderNumber;
            this.items = orderSimple.fbItems;
            this.loadingServide.hideLoader();
            break;
          case OrderStatus.CANCELLED:
            this.status = PaymentStatusTypes.cancel;
            this.orderStateService.cleanState(true);
            this.loadingServide.hideLoader();
            break;
          default:
            this.status = PaymentStatusTypes.pending;
            if (this.currentTry < this.maxTriesAmount) {
              setTimeout(() => this.checkOrderStatus(), 5000);
            } else {
              this.orderStateService.cleanState(true);
              this.loadingServide.hideLoader();
            }
            break;
        }

        console.log(this.status);
      });
    }, 1);
  }
}
