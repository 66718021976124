<div class="header header-fixed rollup">
  <mat-toolbar>
    <ng-container>
      <button mat-button [routerLink]="['/offer']">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div class="title" [style.width.%]="100">
        {{ 'app.page.order.your-order' | translate }}
      </div>
    </ng-container>
  </mat-toolbar>

  <ng-template #newOrderButtonTemplate>
    <button mat-button [routerLink]="['/']">
      <mat-icon>chevron_left</mat-icon>
      <span class="title px-3">{{ 'button.new-order' | translate }}</span>
    </button>
  </ng-template>
</div>

<div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutAlign="flex-start"
     class="container watermark" [class.watermark]="step !== 2">
  <ng-container>
    <ng-container *ngIf="articles">
      <app-article-card *ngFor="let article of articles" [item]="article" [mode]="'order'"
                        [disabled]="isPaymentProcess">
      </app-article-card>
    </ng-container>

    <div class="row my-3">
      <div class="col-12 box d-flex">
        <div class="box-left">
          <app-screening-details></app-screening-details>
        </div>
        <div class="box-right">
          <div class="counter">
            {{ 'app.page.order.total-items' | translate : { totalItems: totalItems } }}
          </div>

          <ng-container *ngIf="orderState && orderState.order && orderState.order.defaultExtraFees">
            <div *ngFor="let fee of orderState.order.defaultExtraFees">
              <span>{{ fee.defaultExtraFeeName }}: </span>
              <span class="price-value">
                {{ fee.defaultExtraFeePrice | money }}
              </span>
            </div>
          </ng-container>

          <div class="price">
            <span>{{ 'app.page.order.to-pay' | translate }}</span>
            <span class="price-value">
              {{ totalValue | money }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row my-3" *ngIf="voucherService.voucher">
      <div class="col-12 box d-flex">
        <div class="box-left">
          <h2>
            {{ 'app.page.order.voucher' | translate : { name: voucherService.voucher.voucherName } }}
          </h2>
          <p>
            {{ voucherService.voucher.voucherDescription }} <br />
            {{ 'app.page.order.voucher-valid' | translate : { date: voucherService.voucher.voucherExpiryDate | dateTime : 'DATE_SHORT_DMY' } }}
          </p>
        </div>
        <div class="box-right">
          <!-- <img
            mat-card-sm-image
            *ngIf="foundVoucher?.picture"
            [src]="foundVoucher.picture"
          /> -->
        </div>
      </div>
    </div>

    <mat-card>
      <mat-card-content>
        <app-tip [max]="getTipMaxValue()" [disabled]="isPaymentProcess">
          <div tip-title>
            <h5 class="group-label">{{ 'label.tip' | translate }}</h5>
          </div>
        </app-tip>

        <h5 class="group-label">{{ 'label.additional-info' | translate }}</h5>
        <ng-container *ngIf="!voucherService.voucher">
          <app-promo-code [cinema]="orderState.cinema" [order]="orderState.order" [readonly]="isPaymentProcess"
                          (stateChanged)="discountGiftStateChanged($event)"></app-promo-code>
        </ng-container>

        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'label.email' | translate }}</mat-label>
                  <input type="email" matInput formControlName="email"
                         [placeholder]="'label.ex' | translate : { value: 'john@world.com' }" autocomplete="off"
                         required [readonly]="isPaymentProcess" />
                  <mat-error *ngIf="
                    form.get('email').hasError('email') &&
                    !form.get('email').hasError('required')
                  " [innerHtml]="'app.page.order.email-valid' | translate"></mat-error>
                  <mat-error *ngIf="form.get('email').hasError('required')"
                             [innerHtml]="'app.page.order.email-required' | translate"></mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'label.phone' | translate }}</mat-label>
                  <input type="tel" matInput formControlName="phone" autocomplete="off" required
                         [readonly]="isPaymentProcess" mask="000 000 000"
                         [placeholder]="'label.ex' | translate : { value: '(123 456 789)' }" />
                  <mat-error *ngIf="
                    form.get('phone').hasError('phone') &&
                    !form.get('phone').hasError('required')
                  " [innerHtml]="'app.page.order.phone-valid' | translate"></mat-error>
                  <mat-error *ngIf="form.get('phone').hasError('required')"
                             [innerHtml]="'app.page.order.phone-required' | translate"></mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </ng-container>

      </mat-card-content>
    </mat-card>

    <ng-container *ngIf="isPaymentProcess">
      <ng-container *ngIf="env.payment.provider && env.payment.provider.length">
        <app-payment #appPayment [cinemaId]="orderState.cinema.id" [orderId]="orderState.order.id"
                     (paymentProcessChanged)="onPaymentProcessChanged($event)"></app-payment>
      </ng-container>
    </ng-container>

  </ng-container>
</div>

<div class="footer footer-extra row" *ngIf="!isPaymentProcess">
  <div class="col-12">
    <button *ngIf="articles?.length" class="action-button" id="payment-trigger" mat-raised-button color="primary"
            (click)="updateBasket()" [disabled]="!form?.valid || !articles?.length">
      {{ 'button.pay' | translate }}
    </button>
    <button *ngIf="!articles?.length" class="action-button" mat-raised-button color="primary" [routerLink]="['/']">
      {{ 'button.back' | translate }}
    </button>
  </div>
</div>

<div class="footer footer-extra row" *ngIf="isPaymentProcess">
  <div class="col-12">
    <button class="action-button" id="payment-trigger" mat-raised-button color="primary" (click)="startPaymentProcess()"
            [disabled]="!form?.valid || !articles?.length">
      {{ 'button.pay' | translate }}
    </button>
  </div>
</div>