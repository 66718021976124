import { CinemaViewModel, OrderViewModel } from '@dinein-lib/restapi-plugin';

export class OrderStateModel {
  constructor(public cinema: CinemaViewModel = null, public order: OrderViewModel = null) {}

  public get readyForUse() {
    return this.cinema !== null && this.order != null;
  }

  public get cinemaId() {
    return this.cinema ? this.cinema.id : null;
  }

  public get orderId() {
    return this.order ? this.order.id : null;
  }
}
