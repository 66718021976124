export class ScreeningItemMembershipPriceApiModel {
  isAppliedOnOrder: boolean;

  membershipLevel: number;

  membershipCardTypeId: string;

  membershipTicketPrice: number;

  membershipPrice: number;
}
