import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { OrderStateService } from './order.state.service';
import { OrderDataProvider, OrderViewModel, VoucherDataProvider, VoucherViewModel } from '@dinein-lib/restapi-plugin';

export interface PatchVoucherItemOptions {
  createNewOrder: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  public voucher: VoucherViewModel = null;

  constructor(private orderDataProvider: OrderDataProvider, private voucherDataProvider: VoucherDataProvider, private orderStateService: OrderStateService) {}

  public assignVoucherToOrder(cinemaId: string, orderId: string, voucherNumber: string): Observable<OrderViewModel> {
    return this.putVoucherToOrder(cinemaId, orderId, voucherNumber).pipe(
      concatMap((res) => {
        return this.orderDataProvider.getOrder(cinemaId, orderId).pipe(
          map((order: OrderViewModel) => {
            this.orderStateService.setOrder(order);
            this.orderStateService.setVoucher(voucherNumber);
            return order;
          })
        );
      })
    );
  }

  public putVoucherToOrder(cinemaId: string, orderId: string, voucherNumber: string) {
    return this.voucherDataProvider.putVoucherToOrder(cinemaId, orderId, voucherNumber);
  }

  removeVoucherFromOrder(cinemaId: string, orderId: string, voucherNumber: string) {
    return this.voucherDataProvider.removeVoucherFromOrder(cinemaId, orderId, voucherNumber).pipe(
      concatMap(() => {
        return this.orderDataProvider.getOrder(cinemaId, orderId).pipe(
          map((order: OrderViewModel) => {
            this.orderStateService.setOrder(order);
            this.orderStateService.removeVoucher(voucherNumber);
            return order.screeningItems.find((it) => it.hasVoucher());
          })
        );
      })
    );
  }

  removeVoucherFromOrderItem(cinemaId, orderId, itemId, voucherNumber): Observable<OrderViewModel> {
    return this.voucherDataProvider
      .removeVoucherFromOrderItem(cinemaId, orderId, itemId, voucherNumber)
      .pipe(concatMap((res) => this.orderDataProvider.getOrder(cinemaId, orderId)));
  }

  assignVoucherToOrderItem(cinemaId, orderId, itemId, voucherNumber) {
    return this.voucherDataProvider.assignVoucherToOrderItem(cinemaId, orderId, itemId, voucherNumber).pipe(
      concatMap(() =>
        this.orderDataProvider.getOrder(cinemaId, orderId).pipe(
          map((order) => {
            this.orderStateService.setOrder(order);
            this.orderStateService.setVoucher(voucherNumber);
            return order.screeningItems.find((it) => it.hasVoucher());
          })
        )
      )
    );
  }

  assignVoucherToOrderViaApiModel(cinemaId: string, orderId: string, voucherNumber: string): Observable<OrderViewModel> {
    return this.putVoucherToOrder(cinemaId, orderId, voucherNumber).pipe(mergeMap(() => this.orderDataProvider.getOrder(cinemaId, orderId)));
  }

  public getVoucherInfo(requestData: { cinemaId: string; voucher: string }) {
    return this.voucherDataProvider.getInfo(requestData['cinemaId'], requestData['voucher']).pipe(tap((voucher) => (this.voucher = voucher)));
  }

  public useVoucher(cinemaId, orderId, voucherNumber) {
    const requestData = Object.assign({ cinemaId: cinemaId }, voucherNumber);

    return this.getVoucherInfo(requestData).pipe(
      switchMap((voucher: VoucherViewModel) =>
        forkJoin({
          voucher: of(voucher),
          order: this.assignVoucherToOrder(cinemaId, orderId, requestData['voucher']),
        })
      )
    );
  }
}
