import { CardTypeBatchApiModel } from './card-type-batch.api.model';
import { CardTypeGraphicTemplateApiModel } from './card-type-graphic-template.api.model';
import { CardTypeMembershipExtensionFeeApiModel } from './card-type-membership-extension-fee.api.model';
import { CardTypeMembershipUpgradeFeeApiModel } from './card-type-membership-upgrade-fee.api.model';
import { CardTypeTypeApiModel } from './card-type-type.api.model';
import { CardTypeValueApiModel } from './card-type-value.api.model';

export class CardTypeApiModel {
  id: string;

  name: string;

  issueFee: number;

  graphic: string;

  canBeIssued: boolean;

  membershipNewFee: number;

  reservationsAllowed: boolean;

  types: CardTypeTypeApiModel[];

  graphicTemplateList: CardTypeGraphicTemplateApiModel[];

  valueList: CardTypeValueApiModel[];

  membershipExtensionFeeList: CardTypeMembershipExtensionFeeApiModel[];

  membershipUpgradeFeeList: CardTypeMembershipUpgradeFeeApiModel[];

  batchList: CardTypeBatchApiModel[];
}
