import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@dinein-lib/shared';

@Component({
  selector: 'app-page-message',
  templateUrl: './message.component.html',
})
export class MessagePageComponent implements OnInit {
  public id: string = null;
  public title: string = null;
  public content: string = null;

  constructor(private loadingServide: LoadingService, private route: ActivatedRoute, private translateService: TranslateService) {}

  ngOnInit() {
    this.loadingServide.hideLoader();

    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.prepareMessage(this.id);
    });
  }

  private prepareMessage(id: string) {
    this.translateService.get(`error.${id}`).subscribe({
      next: (res: object) => {
        if (typeof res === 'string') {
          this.content = res;
          return;
        }

        this.content = res['content'];
        this.title = res['title'];
      },
      error: (err) => this.prepareMessage('default'),
    });
  }

  goAction() {
    window.location.href = '/';
  }
}
