import { AbstractViewModel } from '../../abstract.view.model';
import { AgreementGroupApiModel } from '../../api-model/agreement/agreement-group.api.model';

export class AgreementGroupViewModel extends AbstractViewModel<AgreementGroupApiModel> {
  public id: string = null;
  public name: string = null;

  constructor(apiModel: AgreementGroupApiModel = new AgreementGroupApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
  }

  toApiModel(): AgreementGroupApiModel {
    return undefined;
  }
}
