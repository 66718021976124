import { CUSTOM_ELEMENTS_SCHEMA, Injector, ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './components/loading/loading.component';
import { LogoComponent } from './components/logo/logo.component';
import { ScannerNoAccessComponent } from './components/notification-components/scanner-no-access/scanner-no-access.component';
import { MessageComponent } from './components/message/message.component';
import { LoadingService } from './components/loading/loading.service';
import { MessageService } from './components/message/service/message.service';
import { IosInstallComponent } from './components/ios-install/ios-install.component';
import { ImageLazyDirective } from './directives/image-lazy.directive';
import { DigitDirective } from './directives/digit-only.directive';
import { IframeTrackerDirective } from './directives/iframe-tracker.directive';
import { DateTimePipe } from './pipes/date-time.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { QuantityPipe } from './pipes/quantity.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TranslateSelectorPipe } from './pipes/translate-selector.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PayuPaymentProviderComponent } from './components/payment/proxy/provider/payu/payu-payment-provider.component';
import { BasysPaymentProviderComponent } from './components/payment/proxy/provider/basys/basys-payment-provider.component';
import { ConotoxiaPaymentProviderComponent } from './components/payment/proxy/provider/conotoxia/conotoxia-payment-provider.component';
import { HyperpayPaymentProviderComponent } from './components/payment/proxy/provider/hyperpay/hyperpay-payment-provider.component';
import { IntercardPaymentProviderComponent } from './components/payment/proxy/provider/intercard/intercard-payment-provider.component';
import { PayuBlikPaymentProviderComponent } from './components/payment/proxy/provider/payu-blik/payu-blik-payment-provider.component';
import { SandboxPaymentProviderComponent } from './components/payment/proxy/provider/sandbox/sandbox-payment-provider.component';
import { WorldPayPaymentProviderComponent } from './components/payment/proxy/provider/worldpay/worldpay-payment-provider.component';
import { DatatransPaymentProviderComponent } from './components/payment/proxy/provider/datatrans/datatrans-payment-provider.component';

import { ArticleBuilderComponent } from './components/article-builder/article-builder.component';
import { ArticleCardComponent } from './components/article-card/article-card.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentMethodComponent } from './components/payment/proxy/component/payment-method/payment-method.component';
import { PaymentProxyComponent } from './components/payment/proxy/payment-proxy.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { TipComponent } from './components/tip/tip.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaskModule } from 'ngx-mask';
import { ProviderContainerComponent } from './components/payment/proxy/provider-container/provider-container.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { MembershipLoginDialogComponent } from './components/dialogs/membership-login-dialog/membership-login-dialog.component';
import { PromoCodeComponent } from './components/promo-code/promo-code.component';
import { HideMissingDirective } from './directives/image-hide-missing.directive';
import { ChecklistComponent } from './components/ui/checklist/checklist.component';
import { TimeParsePipe } from './pipes/time-parse.pipe';
import { UpdateDialogComponent } from './components/dialogs/update-dialog/update-dialog.component';
import { VarDirective } from './directives/ng-var.directive';

import { MaterialModule } from './modules/material/material.module';
import { NgxBootstrapModule } from './modules/ngx-bootstrap/ngx-bootstrap.module';

@NgModule({
  declarations: [
    //components
    LoadingComponent,
    LogoComponent,
    ScannerNoAccessComponent,
    MessageComponent,
    IosInstallComponent,

    ArticleBuilderComponent,

    ArticleCardComponent,
    SearchResultComponent,
    SearchFormComponent,

    TipComponent,
    PaymentComponent,
    PaymentMethodComponent,
    PayuPaymentProviderComponent,
    PayuBlikPaymentProviderComponent,
    SandboxPaymentProviderComponent,
    WorldPayPaymentProviderComponent,
    DatatransPaymentProviderComponent,
    ConotoxiaPaymentProviderComponent,
    IntercardPaymentProviderComponent,
    BasysPaymentProviderComponent,
    HyperpayPaymentProviderComponent,
    PaymentProxyComponent,
    ProviderContainerComponent,
    MembershipLoginDialogComponent,
    PromoCodeComponent,
    ChecklistComponent,
    UpdateDialogComponent,
    //directives
    HideMissingDirective,
    ImageLazyDirective,
    IframeTrackerDirective,
    DigitDirective,
    VarDirective,
    //pipes
    MoneyPipe,
    QuantityPipe,
    DateTimePipe,
    TranslateSelectorPipe,
    SafePipe,
    TimeParsePipe,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(),
    NgxMaskModule.forRoot(),
    ModalModule.forRoot(),
    MaterialModule,
    NgxBootstrapModule,
  ],
  exports: [
    MaterialModule,
    NgxBootstrapModule,
    LoadingComponent,
    LogoComponent,
    MessageComponent,
    IosInstallComponent,
    ArticleBuilderComponent,
    ArticleCardComponent,
    SearchResultComponent,
    SearchFormComponent,
    TipComponent,
    PaymentComponent,
    PaymentMethodComponent,
    PaymentProxyComponent,
    PromoCodeComponent,
    ChecklistComponent,

    //directives
    HideMissingDirective,
    ImageLazyDirective,
    IframeTrackerDirective,
    DigitDirective,
    VarDirective,
    //pipes
    MoneyPipe,
    QuantityPipe,
    DateTimePipe,
    TranslateSelectorPipe,
    SafePipe,
    TimeParsePipe,
    //dialogs
    MembershipLoginDialogComponent,
    UpdateDialogComponent,
  ],
  entryComponents: [
    PayuPaymentProviderComponent,
    HyperpayPaymentProviderComponent,
    PayuBlikPaymentProviderComponent,
    BasysPaymentProviderComponent,
    ConotoxiaPaymentProviderComponent,
    IntercardPaymentProviderComponent,
    SandboxPaymentProviderComponent,
    WorldPayPaymentProviderComponent,
    DatatransPaymentProviderComponent,
  ],
  providers: [LoadingService, MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(@Optional() @SkipSelf() parentModule: SharedModule, private injector: Injector) {
    if (parentModule) {
      throw new Error('SharedModule is already loaded. Import it in the AppModule only');
    }
  }
}
