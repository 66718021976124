import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from '../../loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateDialogData } from '@dinein-lib/restapi-plugin';
import { NotificationService } from '@dinein-lib/core';

@Component({
  selector: 'app-update-dialog',
  templateUrl: './update-dialog.component.html',
})
export class UpdateDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateDialogData,
    protected loadingService: LoadingService,
    protected notificationService: NotificationService,
    private translation: TranslateService
  ) {}

  onOkClick(): void {
    this.dialogRef.close({ completed: true });
  }
}
