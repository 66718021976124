import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import { CateringService } from '../../services/catering.service';
import { OrderCateringService, SelectedModifierItemMap } from '../../services/order-catering.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrderStateModel } from '../../models/order.state.model';
import { CartService } from '../../services/cart.service';
import { OrderStateService } from '../../services/order.state.service';
import { SearcherService } from '../../services/searcher.service';
import { CateringAggregationArticleViewModel, CateringAggregationViewModel, FbItemViewModel, OrderViewModel } from '@dinein-lib/restapi-plugin';

import { AddFormEvent, FormEvent, LoadingService } from '@dinein-lib/shared';

@Component({
  selector: 'app-product-details-page',
  templateUrl: './product-details.component.html',
})
export class ProductDetailsPageComponent implements AfterViewInit {
  public article: CateringAggregationArticleViewModel | null = null;

  public orderState: OrderStateModel | null = null;
  public order: OrderViewModel = new OrderViewModel();

  public availableCatering: CateringAggregationViewModel | null = null;
  public selectedArticleCombinationList: CateringAggregationArticleViewModel[] | null = null;

  constructor(
    private searchService: SearcherService,
    private orderStateService: OrderStateService,
    public cartService: CartService,
    private cateringService: CateringService,
    private orderCateringService: OrderCateringService,
    private loadingService: LoadingService,
    public bsModalRef: BsModalRef
  ) {}

  ngAfterViewInit(): void {
    if (this.article) {
      this.searchService
        .getArticle(this.article.id)
        .subscribe((cateringAggregationViewModel: CateringAggregationViewModel) => (this.availableCatering = cateringAggregationViewModel));
    }
  }

  navigateBack() {
    this.bsModalRef.hide();
  }

  addToOrder(article: CateringAggregationArticleViewModel) {
    this.cartService.addCart(article);
  }

  public onAddedArticle(formEvent: FormEvent): void {
    if (formEvent instanceof AddFormEvent) {
      const selectedModifierItemMap: Map<string, Map<string, boolean>> = formEvent.selectedModifierItemMap;
      const selectedSubArticleMap: Map<number, Map<string, Map<string, boolean>>> = formEvent.selectedSubArticleMap;
      const article: CateringAggregationArticleViewModel | undefined = this.availableCatering?.articles.find(
        (x) => x.id === formEvent.articleId && x.parentGroup?.id === formEvent.parentGroupId
      );

      console.log(selectedSubArticleMap);

      if (article) {
        const articleCombination: CateringAggregationArticleViewModel = this.cateringService.buildSelectedArticle(
          article,
          formEvent.quantity,
          selectedModifierItemMap,
          selectedSubArticleMap
        );

        article.subArticleList
          .filter((replacer) => replacer.replacementList.length === 0)
          .forEach((ingredient) => {
            articleCombination.subArticleList.push(ingredient);
          });

        this.cateringService.recalculateArticleCombinationHash(articleCombination);

        //TODO update price !!!!
        const existingArticleCombination = this.selectedArticleCombinationList.find(
          (element) => element.selectedCombinationHash === articleCombination.selectedCombinationHash
        );

        if (existingArticleCombination) {
          existingArticleCombination.selectedQuantity += articleCombination.selectedQuantity;
          this.modifyItemInBasket(existingArticleCombination);
        } else {
          this.selectedArticleCombinationList.push(articleCombination);
          this.addItemToBasket(articleCombination);
        }
      }
    }
  }

  private addItemToBasket(item: CateringAggregationArticleViewModel): void {
    this.loadingService.showLoader();
    //this.messageService.clear();

    this.orderCateringService.post(item).subscribe(
      (order) => {
        this.order = order;
        this.recalculateItems();

        this.loadingService.hideLoader();
        this.navigateBack();
      },
      (err: HttpErrorResponse) => {
        this.orderStateService.onError(err);

        if (err.status === 400) {
          this.recalculateItems();
          //this.messageService.add(new MessageModel(MessageType.danger, this.translate.instant('error.220')));
        }

        this.loadingService.hideLoader();
      }
    );
  }

  private modifyItemInBasket(item: CateringAggregationArticleViewModel): void {
    this.loadingService.showLoader();
    //this.messageService.clear();

    const calculateQuantity = (arr: FbItemViewModel[], articleId: string) => {
      return arr
        .filter((orderedItem) => {
          return orderedItem.articleId === articleId;
        })
        .reduce((prev, curr) => {
          prev += curr.quantity;
          return prev;
        }, 0);
    };
    const sumOfQuantityFromRequest = calculateQuantity(this.order.fbItems, item.id);

    this.orderCateringService.patchQuantityItem(item).subscribe(
      (order) => {
        if (sumOfQuantityFromRequest === calculateQuantity(order.fbItems, item.id)) {
          //this.messageService.add(new MessageModel(MessageType.danger, this.translate.instant('error.220')));
        }

        this.order = order;
        this.recalculateItems();

        this.loadingService.hideLoader();
        this.navigateBack();
      },
      (err: HttpErrorResponse) => {
        this.orderStateService.onError(err);
      }
    );
  }

  private recalculateItems(): void {
    this.orderStateService.setOrder(this.order);
    this.orderState = this.orderStateService.getState();

    this.buildSelectedArticleCombinationList();
  }

  private buildSelectedArticleCombinationList(): void {
    const selectedMap: SelectedModifierItemMap = this.orderCateringService.buildSelectedMap(this.availableCatering, this.orderState.order.fbItems);

    if (Object.keys(selectedMap).length > 0) {
      const articleCombinationList: Array<CateringAggregationArticleViewModel> = [];

      for (const articleId of Object.keys(selectedMap)) {
        for (const mapping of selectedMap[articleId]) {
          const articleCombination: CateringAggregationArticleViewModel = this.cateringService.buildSelectedArticle(
            mapping.article,
            mapping.selectedQuantity,
            mapping.selectedModifierItemMap,
            mapping.selectedSubArticleMap
          );

          if (articleCombination) {
            articleCombinationList.push(articleCombination);
          }
        }
      }

      this.selectedArticleCombinationList = this.orderCateringService.selectedOrderCatering = articleCombinationList.map((x) => x);
    } else {
      this.selectedArticleCombinationList = this.orderCateringService.selectedOrderCatering = [];
    }
  }
}
