export class UserCardApiModel {
  id: string;

  name: string;

  pointBalance: number;

  valueBalance: number;

  issueDate: string;

  expiryDate: string;

  layout: string;

  typeId: string;

  message: string;

  templateId: string;

  number: string;

  status: number;

  hidden: boolean;

  graphic: string;
}
