import { AbstractViewModel } from '../../abstract.view.model';
import { CardTypeMembershipUpgradeFeeApiModel } from '../../api-model/card/card-type-membership-upgrade-fee.api.model';

export class CardTypeMembershipUpgradeFeeViewModel extends AbstractViewModel<CardTypeMembershipUpgradeFeeApiModel> {
  public feeId: string = null;
  public name: string = null;
  public extensionTime: number = null;
  public extensionType: number = null;
  public feeValue: number = null;
  public upgradeToCardTypeId: string = null;
  public upgradeToCardTypeName: string = null;

  public membershipTotalValue = 0.0;

  constructor(apiModel: CardTypeMembershipUpgradeFeeApiModel = new CardTypeMembershipUpgradeFeeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.feeId = this.apiModel.feeId;
    this.name = this.apiModel.name;
    this.extensionTime = this.apiModel.extensionTime;
    this.extensionType = this.apiModel.extensionType;
    this.feeValue = this.apiModel.feeValue;
    this.upgradeToCardTypeId = this.apiModel.upgradeToCardTypeId;
    this.upgradeToCardTypeName = this.apiModel.upgradeToCardTypeName;
  }

  toApiModel(): CardTypeMembershipUpgradeFeeApiModel {
    return undefined;
  }
}
