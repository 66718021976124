import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CreatePaymentRedirectUrlDto } from './dto/create-payment-redirect-url.dto';
import { PaymentMethodViewModel } from '@dinein-lib/restapi-plugin';
import { ENVIRONMENT_TOKEN, OrderStateService } from '@dinein-lib/core';

@Injectable({
  providedIn: 'root',
})
export class PaymentHelperService {
  private paymentRedirectUrlPattern: string;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private router: Router, private orderStateService: OrderStateService) {
    this.paymentRedirectUrlPattern = environment.payment.paymentRedirectUrl;
  }

  /**
   * Creates an payment redirect URI
   * @param request
   */
  public createPaymentRedirectUrl(request: CreatePaymentRedirectUrlDto, target: string = null): string {
    const state = this.getState();

    return this.paymentRedirectUrlPattern
      .replace('{cinemaId}', state.cinemaId)
      .replace('{orderId}', state.orderId)
      .replace('{target}', target ? encodeURIComponent(target) : this.createTarget(request))
      .replace('{paymentChannel}', request.paymentChannel);
  }

  public createTarget(request: CreatePaymentRedirectUrlDto): string {
    const state = this.getState();

    return encodeURIComponent(
      window.location.origin +
        (window.location.origin.endsWith('/') ? '' : '/') +
        this.router
          .createUrlTree(['payment'], {
            queryParams: {
              orderId: state.orderId,
              cinemaId: state.cinemaId,
            },
          })
          .toString()
    );
  }

  public getState() {
    return this.orderStateService.getState();
  }
  public setPaymentMethod(selectedPaymentMethod: PaymentMethodViewModel) {
    this.orderStateService.setPaymentMethod(selectedPaymentMethod);
  }
  public setEmbededPaymentUrl(plainPayload) {
    this.orderStateService.setEmbededPaymentUrl(plainPayload);
  }
  public getEmbededPaymentUrl() {
    return this.orderStateService.getEmbededPaymentUrl();
  }
}
