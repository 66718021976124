export class PaymentMethodApiModel {
  id: string;

  name: string;

  identifier: string;

  cardId: string;

  3;
  value: number;
}
