export class PaymentConfigApiModel {
  salesPointId: string;

  postBackUrl: string;

  environment: string;

  merchantId: string;

  googleMerchantId: string;

  merchantName: string;
}
