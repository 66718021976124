import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CardDataProvider, CardTypeViewModel, CardViewModel, UserDataProvider } from '@dinein-lib/restapi-plugin';

@Injectable({
  providedIn: 'root',
})
export class CardHelper {
  public static membershipKey = 'Membership';
  public static loyaltyKey = 'Loyalty';

  constructor(protected userDataProvider: UserDataProvider, protected cardDataProvider: CardDataProvider) {}

  public findMembership(cinemaId?: string): Observable<MembershipCardInterface> {
    return forkJoin({
      accountItems: this.userDataProvider.getAccountItemsViaApiModel(),
      cardTypes: cinemaId ? this.cardDataProvider.types(cinemaId) : of(null),
    }).pipe(
      mergeMap((response) => {
        let cardType: CardTypeViewModel;

        const card: CardViewModel = response.accountItems.cards.find(
          (x) => x.typeDescriptions.find((y) => y === CardHelper.membershipKey) && x.typeDescriptions.find((y) => y === CardHelper.loyaltyKey)
        );

        if (card && response.cardTypes) {
          cardType = response.cardTypes.find((x) => x.id === card.cardTypeId);
        }

        return of({
          card: card ?? null,
          cardType: cardType ?? null,
          canReserve: cardType ? cardType.reservationsAllowed : false,
        });
      })
    );
  }
}

export interface CardInterface {
  cardType: CardTypeViewModel;
  card: CardViewModel;
}

export interface MembershipCardInterface extends CardInterface, CanReserveCardInterface {
  cardType: CardTypeViewModel;
  card: CardViewModel;
  canReserve: boolean;
}

export interface LoyaltyCardInterface extends CardInterface {
  cardType: CardTypeViewModel;
  card: CardViewModel;
}

export interface CanReserveCardInterface {
  canReserve: boolean;
}
