import { AbstractViewModel } from '../../abstract.view.model';
import { CardTypeBatchApiModel } from '../../api-model/card/card-type-batch.api.model';

export class CardTypeBatchViewModel extends AbstractViewModel<CardTypeBatchApiModel> {
  public id: string = null;
  public name: string = null;
  public graphic: string = null;
  public unlimited: boolean = null;
  public canBeIssued: boolean = null;
  public paymentValue: number = null;

  constructor(apiModel: CardTypeBatchApiModel = new CardTypeBatchApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.graphic = this.apiModel.graphic;
    this.unlimited = this.apiModel.unlimited;
    this.canBeIssued = this.apiModel.canBeIssued;
    this.paymentValue = this.apiModel.paymentValue;
  }

  toApiModel(): CardTypeBatchApiModel {
    return undefined;
  }
}
