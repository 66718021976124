'use strict';

export const sep = '/';
export const version: string = '1.0.0';

export const storageKey = {
  cinemaId: 'pc_cid',
  orderId: 'pc_oid',
  seatId: 'pc_sid',
  tableId: 'pc_tid',
  selectedPaymentMethod: 'pc_sel_pay_met',
  vouchers: 'pc_vchers',
  personalData: 'pc_per',
  screening: 'pc_s',
  cinema: 'pc_c',
  lang: 'pc_lng',
  embededPaymentUrl: 'pc_emb_pay_url',
  cateringEnabled: 'pc_cat_ebled',
  welcomePage: 'pc_wel_pge',
  groupsProperties: 'pc_g_prop',
  theme: 'pc_theme',
  token: 'pc_t',
  table: 'pc_tab',
};
