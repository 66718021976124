export class CateringResponseModel {
  groupList: Array<CateringArticleGroupResponseModel> = new Array<CateringArticleGroupResponseModel>();
  articleList: Array<CateringArticleResponseModel> = new Array<CateringArticleResponseModel>();
}

export class CateringArticleGroupResponseModel {
  id: string;
  name?: string;
  description?: string;
  parentGroupId?: string;
  graphicUrl?: string;
}

export class CateringArticleResponseModel {
  id: string;
  groupId?: string;
  name?: string;
  price: number;
  defaultPriceLevelPrice?: number;
  comboMinPrice?: number;
  taxRate: number;
  description?: string;
  isLocked: boolean;
  graphicUrl?: string;
  subArticleList: Array<CateringArticleResponseModel> = new Array<CateringArticleResponseModel>();
  replacementList: Array<CateringArticleResponseModel> = new Array<CateringArticleResponseModel>();
  modifierList: Array<CateringArticleModifierResponseModel> = new Array<CateringArticleModifierResponseModel>();
}

export class CateringArticleModifierResponseModel {
  id: string;
  type?: string;
  name?: string;
  isRequired: boolean;
  multiChoice: boolean;
  multiChoiceMax: number;
  multiChoiceMin: number;
  separateItem: boolean;
  itemList: Array<CateringArticleItemModifierResponseModel> = new Array<CateringArticleItemModifierResponseModel>();
}

export class CateringArticleItemModifierResponseModel {
  id: string;
  name?: string;
  description?: string;
  price: number;
  quantity: number;
  relatedItemList: Array<string> = new Array<string>();
}
