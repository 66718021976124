import { AbstractViewModel } from '../../../abstract.view.model';
import { UserCardTypeTypeApiModel } from '../../../api-model/user-history/card-type/user-card-type-type.api.model';

export class UserCardTypeTypeViewModel extends AbstractViewModel<UserCardTypeTypeApiModel> {
  /**
   1 - loyalty
   2 - discount
   4 - prepaid
   16- membership
   32 - gift
   */
  public number: number = null;
  public name: string = null;
  public description: string = null;

  constructor(apiModel: UserCardTypeTypeApiModel = new UserCardTypeTypeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.number = this.apiModel.number;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
  }

  toApiModel(): UserCardTypeTypeApiModel {
    return undefined;
  }
}
