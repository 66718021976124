import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderPaymentRequestModel } from '../../models/order-payment.request.model';
import { OrderStateModel } from '../../models/order.state.model';
import { DonePaymentProviderEvent } from './proxy/event/done-payment-provider.event';
import { ErrorPaymentProviderEvent } from './proxy/event/error-payment-provider.event';
import { NotSelectedPaymentProviderEvent } from './proxy/event/not-selected-payment-provider.event';
import { PaymentProviderEvent } from './proxy/event/payment-provider.event';
import { WaitPaymentProviderEvent } from './proxy/event/wait-payment-provider.event';
import { WorkPaymentProviderEvent } from './proxy/event/work-payment-provider.event';
import { PaymentPreInitModel } from './proxy/model/payment-pre-init.model';
import { PaymentProxyComponent } from './proxy/payment-proxy.component';
import { PaymentProviderStateService } from './service/payment-provider-state.service';
import { LoadingStatus } from '../loading/model/loading-status.enum';
import { LoadingService } from '../loading/loading.service';
import { AppHelper } from '../../helpers/app-helper';
import { PaymentHelperService } from './service/payment-helper.service';
import { PaymentProcessEnum } from '../../enums/payment-process.enum';
import { ProviderEnum } from '../../enums/payment-provider.enum';
import { OrderDataProvider, PaymentMethodViewModel, PaymentProviderPayMethodViewModel, PaymentViewModel } from '@dinein-lib/restapi-plugin';
import { ENVIRONMENT_TOKEN, PaymentService } from '@dinein-lib/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
})
export class PaymentComponent implements OnInit {
  @Output() public paymentProcessChanged = new EventEmitter<PaymentProcessEnum>();

  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  @Input() cinemaId: string;
  @Input() orderId: string;
  @Input() embeddedContent: string = null;
  @ViewChild(PaymentProxyComponent)
  public paymentProxyComponent: PaymentProxyComponent;
  @ViewChild('buttonElement')
  public submitButton: ElementRef<HTMLButtonElement>;

  public env;
  public form: FormGroup = null;

  public isPaymentInitiating = false;
  public orderState: OrderStateModel = null;
  public paymentLoadingStatus = LoadingStatus.pending;
  /**
   * The list of available payment method for order
   */
  public paymentMethodList: PaymentMethodViewModel[] = [];

  /**
   * The payment data received from payment provider after init
   */
  public paymentModel: PaymentViewModel = null;

  /**
   * Selected payment method
   */
  public selectedPaymentMethod: PaymentMethodViewModel = null;
  public selectedPaymentProviderPayMethod: PaymentProviderPayMethodViewModel = null;
  /**
   * The default payment method to auto select
   */
  private readonly autoSelectedPaymentMethod: string | null = null;

  /**
   * The list of allowed payment methods. If null the constraint is disable
   */
  private readonly allowedPaymentMethodList: Array<string> | null;
  private paymentChannel;

  private orderStateSubscription: Subscription = Subscription.EMPTY;
  private paymentMethodListSubscription: Subscription = Subscription.EMPTY;
  private paymentInitSubscription: Subscription = Subscription.EMPTY;
  public providerEnum: typeof ProviderEnum = ProviderEnum;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected router: Router,
    protected route: ActivatedRoute,
    protected orderDataProvider: OrderDataProvider,
    private paymentHelperService: PaymentHelperService,
    private paymentService: PaymentService,
    private loadingService: LoadingService,
    private selectedPaymentTypeService: PaymentProviderStateService
  ) {
    this.env = environment;
    this.allowedPaymentMethodList = environment.payment.allowedPaymentMethod;
    this.autoSelectedPaymentMethod = environment.payment.autoSelectedPaymentMethod;
    this.paymentChannel = environment.payment.channel || 'web';
  }

  ngOnInit(): void {
    this.loadingService.showLoader();

    this.paymentService.embeddedContent$.subscribe((url) => {
      this.onOpenIFrame(url);
    });

    if (this.env.payment.provider === 'fake') {
      this.form = new FormGroup(
        {
          creditCard: new FormControl('4556775268595771', [Validators.required]),
          creditCardExpireDate: new FormControl('0224', [Validators.required]),
          creditCardSecure: new FormControl('715', [Validators.required]),
        },
        {}
      );

      //fake data disabled
      this.form.get('creditCard').disable();
      this.form.get('creditCardExpireDate').disable();
      this.form.get('creditCardSecure').disable();
    } else {
      this.orderState = this.paymentHelperService.getState();
      if (this.paymentMethodList?.length === 0) {
        this.loadAvailablePaymentMethodList();
      }

      this.selectedPaymentTypeService.state$.subscribe((state) => {
        if (state) {
          this.selectedPaymentMethod = this.paymentMethodList.find((y) => y.identifier === state.provider);
        }
      });
    }

    this.loadingService.hideLoader();
  }

  onOpenIFrame(url: string) {
    this.embeddedContent = url ? url : this.paymentHelperService.getEmbededPaymentUrl();
  }

  onLoadIFrame() {
    this.loadingService.hideLoader();
    if (this.iframe) {
      const doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
      if (doc) {
        const divProgress = doc.getElementById('divProgress') as HTMLDivElement;
        // divProgress.style.display = "none";
        const progImage = doc.getElementById('progImage') as HTMLImageElement;
        // progImage.style.display = "none";
        progImage.src = AppHelper.progressImgUrl;
      }
    }
  }

  public onPaymentProviderEvent(event: PaymentProviderEvent): void {
    if (event instanceof WaitPaymentProviderEvent) {
      //  this.loadingService.showLoader(LoaderEnum.MAIN);
      this.paymentLoadingStatus = LoadingStatus.pending;
    }

    if (event instanceof WorkPaymentProviderEvent) {
      // this.loadingService.showLoader(LoaderEnum.MAIN);
      this.paymentLoadingStatus = LoadingStatus.pending;
    }

    if (event instanceof DonePaymentProviderEvent) {
      //  this.loadingService.hideLoader(LoaderEnum.MAIN);
      this.paymentLoadingStatus = LoadingStatus.success;
    }

    if (event instanceof ErrorPaymentProviderEvent) {
      //  this.loadingService.hideLoader(LoaderEnum.MAIN);
      this.paymentLoadingStatus = LoadingStatus.success;
      //  this.errorHandler(event.originError as HttpErrorResponse);
    }

    if (event instanceof NotSelectedPaymentProviderEvent) {
      //  this.loadingService.hideLoader(LoaderEnum.MAIN);
      this.paymentLoadingStatus = LoadingStatus.success;
      //  this.showPaymentInformation();
    }
  }

  private loadAvailablePaymentMethodList(): void {
    this.paymentMethodListSubscription = this.orderDataProvider.getOrderPaymentMethod(this.orderState.cinema.id, this.orderState.order.id).subscribe({
      next: (paymentMethodList: Array<PaymentMethodViewModel>) => {
        this.paymentMethodList = this.filterPaymentMethodList(paymentMethodList);

        this.selectDefaultPaymentMethod();
        if (this.autoSelectedPaymentMethod !== null && this.autoSelectedPaymentMethod !== ProviderEnum.WORLDPAY) {
          const preInitPaymentEvent: PaymentPreInitModel = new PaymentPreInitModel();
          preInitPaymentEvent.continueUrl = this.generateContinueUrl();

          console.log('initPayment', this.autoSelectedPaymentMethod);
          this.initPayment(this.autoSelectedPaymentMethod, preInitPaymentEvent);
        }
      },
      error: (e) => this.errorHandler(e),
      complete: () => (this.isPaymentInitiating = false),
    });
  }

  private initPayment(paymentProviderIdentifier: string, preInitPaymentEvent: PaymentPreInitModel): void {
    this.paymentProcessChanged.emit(PaymentProcessEnum.BEGIN);
    this.isPaymentInitiating = true;

    const request = this.createPaymentRequest(paymentProviderIdentifier, preInitPaymentEvent);
    console.log('request:', request);
    this.paymentInitSubscription = this.orderDataProvider.getOrderPayment(request).subscribe({
      next: (paymentModel: PaymentViewModel) => {
        this.paymentModel = paymentModel;
        this.paymentProxyComponent.onPostInitPayment(this.paymentModel, paymentProviderIdentifier);
        this.paymentProcessChanged.emit(PaymentProcessEnum.END);
      },
      error: (e) => {
        this.errorHandler(e);
        this.paymentProcessChanged.emit(PaymentProcessEnum.FAILED);
      },
      complete: () => (this.isPaymentInitiating = false),
    });
  }

  private createPaymentRequest(paymentProviderIdentifier: string, preInitPaymentEvent: PaymentPreInitModel): OrderPaymentRequestModel {
    const orderPaymentRequest: OrderPaymentRequestModel = new OrderPaymentRequestModel();
    orderPaymentRequest.cinemaId = this.orderState.cinema.id;
    orderPaymentRequest.orderId = this.orderState.order.id;
    orderPaymentRequest.paymentChannel = this.paymentChannel;
    orderPaymentRequest.paymentProviderIdentifier = paymentProviderIdentifier;
    orderPaymentRequest.continueUrl = preInitPaymentEvent.continueUrl;
    orderPaymentRequest.paymentData = preInitPaymentEvent.paymentData;
    orderPaymentRequest.intPayMethodType = preInitPaymentEvent.intPayMethodType;
    orderPaymentRequest.intPayMethodValue = preInitPaymentEvent.intPayMethodValue;

    return orderPaymentRequest;
  }

  private generateContinueUrl(): string {
    const urlTree = this.router
      .createUrlTree(['payment'], {
        queryParams: {
          orderId: this.orderState.order.id,
          cinemaId: this.orderState.cinema.id,
        },
      })
      .toString();

    return `${window.location.origin}${urlTree}`;
  }

  private filterPaymentMethodList(paymentMethodList: Array<PaymentMethodViewModel>): Array<PaymentMethodViewModel> {
    if (this.allowedPaymentMethodList === null) {
      return paymentMethodList;
    }

    return paymentMethodList
      .filter((paymentMethod: PaymentMethodViewModel) =>
        paymentMethod.identifier ? this.allowedPaymentMethodList.indexOf(paymentMethod.identifier.toLowerCase()) >= 0 : false
      )
      .map((o) => {
        o.disabled = this.selectedPaymentMethod ? this.selectedPaymentMethod.identifier === o.identifier : false;
        return o;
      })
      .sort((a, b) => {
        return a.identifier
          ? this.allowedPaymentMethodList.indexOf(a.identifier.toLowerCase()) < this.allowedPaymentMethodList.indexOf(b.identifier.toLowerCase())
            ? -1
            : 1
          : 1;
      });
  }

  private selectDefaultPaymentMethod(): void {
    if (!this.selectedPaymentMethod) {
      this.selectedPaymentMethod = this.paymentMethodList.find((o) => o.identifier === this.autoSelectedPaymentMethod);
    }
  }

  private errorHandler(err: HttpErrorResponse): void {
    const errStatus: any = err && err.status ? err.status : null;
    console.log('err', errStatus);
    // if (errStatus === 500) {
    //   this.navigationHelperService.redirectToError(ErrorType.unexpectedErrorOccurred);
    // } else if (errStatus === 400) {
    //   const isHandled: boolean = this.handleValidationError(err);

    //   if (isHandled === false) {
    //     this.loadingService.hideLoader(LoaderEnum.MAIN);
    //     this.paymentLoadingStatus = LoadingStatus.success;
    //     this.isPaymentInitiating = false;
    //     return this.responseValidatorService.viewErrors(err);
    //   }
    // } else if (errStatus === 404) {
    //   this.navigationHelperService.redirectToError(ErrorType.paymentAlreadyStarted);
    // }

    // if (!this.appService.isProject(appProjectName.SCHULMAN, appProjectName.VIOLET_CROWN, appProjectName.LANDMARK, appProjectName.ONEIL)) {
    //   this.showError();
    // }

    // this.responseValidatorService.viewErrors(err);
    // this.loadingService.hideLoader(LoaderEnum.MAIN);
    // this.paymentLoadingStatus = LoadingStatus.success;
    // this.isPaymentInitiating = false;
  }

  public payButtonClicked(): void {
    const identity = this.selectedPaymentMethod.identifier;
    if (!identity) {
      return;
    }

    this.isPaymentInitiating = true;
    const preInitPaymentEvent: PaymentPreInitModel = new PaymentPreInitModel();
    preInitPaymentEvent.continueUrl = this.generateContinueUrl();

    this.paymentProxyComponent.onPreInitPayment(preInitPaymentEvent, this.selectedPaymentMethod.identifier).subscribe((x) => {
      if (x.abort === true) {
        this.isPaymentInitiating = false;
        return;
      }

      this.paymentHelperService.setPaymentMethod(this.selectedPaymentMethod);
      // this.googleTagManagerService.addPaymentInfo(this.selectedPaymentMethod.name);
      console.log('initPayment', identity);
      this.initPayment(identity, x);
    });
  }
}
