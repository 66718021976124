import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { UseGiftCardRequestModel } from '../models/use-gift-card.request.model';
import { CardApiModel } from '../models/api-model/card/card.api.model';
import { CardTypeApiModel } from '../models/api-model/card/card-type.api.model';
import { OrderApiModel } from '../models/api-model/order/order.api.model';

@Injectable({
  providedIn: 'root',
})
export class CardHttpService {
  constructor(private http: HttpClient) {}

  public info(searchValue: string): Observable<CardApiModel> {
    return this.http.get<CardApiModel>('/card/' + searchValue);
  }

  public useGiftCard(cinemaId: string, orderId: string, request: UseGiftCardRequestModel[]): Observable<any> {
    return this.http.patch<CardTypeApiModel[]>(`/cinema/${cinemaId}/order/${orderId}/internalPayment`, request);
  }

  public types(cinemaId: string = null): Observable<CardTypeApiModel[]> {
    return this.http.get<CardTypeApiModel[]>('/card/types', { params: { cinemaId: cinemaId } });
  }

  public assignCardToOrder(cinemaId: string, orderId: string, cardId: string): Observable<OrderApiModel> {
    return this.http.patch<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/card/${cardId}`, null);
    //.pipe(tap((res) => OrderHttpService.cacheModify(res.id, res)));
  }

  public deleteCardFromOrder(cinemaId: string, orderId: string): Observable<OrderApiModel> {
    return this.http.delete<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/card`);
    //.pipe(tap((res) => OrderHttpService.cacheModify(res.id, res)));
  }
}
