import { AbstractViewModel } from '../../../abstract.view.model';
import { FbItemModifierItemApiModel } from '../../../api-model/order/fb-item/fb-item-modifier-item.api.model';

export class FbItemModifierItemViewModel extends AbstractViewModel<FbItemModifierItemApiModel> {
  modifierId: string;
  name: string;
  isSeparate: boolean;
  modifierName: string;
  modifierItemId: string;
  modifierItemName: string;
  modifierItemDescription: string;
  quantity: number;
  taxRate: number;
  price: number;
  primaryModifierItemId: string;
  defaultPriceLevelPrice: number;

  constructor(apiModel: FbItemModifierItemApiModel = new FbItemModifierItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.modifierId = this.apiModel.modifierId;
    this.name = this.apiModel.name;
    this.isSeparate = this.apiModel.isSeparate;
    this.modifierName = this.apiModel.modifierName;
    this.modifierItemId = this.apiModel.modifierItemId;
    this.modifierItemName = this.apiModel.modifierItemName;
    this.modifierItemDescription = this.apiModel.modifierItemDescription;
    this.quantity = this.apiModel.quantity;
    this.taxRate = this.apiModel.taxRate;
    this.price = this.apiModel.price;
    this.primaryModifierItemId = this.apiModel.primaryModifierItemId;
    this.defaultPriceLevelPrice = this.apiModel.defaultPriceLevelPrice;
  }

  toApiModel(): FbItemModifierItemApiModel {
    return Object.assign(this.apiModel, {
      modifierItemId: this.modifierItemId,
    });
  }
}
