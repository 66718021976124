import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLocaleData } from '@angular/common';

import localeSgExtra from '@angular/common/locales/extra/sg';
import sq from '@angular/common/locales/sg';
import en from '@angular/common/locales/en';

import { AppModule } from './app/app.module';
import { AppConfigService } from '@dinein-lib/core';
import { environment } from './environments/environment';

registerLocaleData(sq, 'sq', localeSgExtra);
registerLocaleData(en, 'en');

if (environment.production) {
  enableProdMode();
}

const APP_CONFIG_KEY = 'app_conf';
const APP_CONFIG_LASTUPDATEKEY = 'app_conf_lupd';
const startApp = (config) => {
  AppConfigService.init(environment, config);
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {})
    .then(() => {
      if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('ngsw-worker.js');
      }
    })
    .catch((err) => console.log(err));
};

const sd = sessionStorage.getItem(APP_CONFIG_KEY);
const sd_lastupdate = parseInt(sessionStorage.getItem(APP_CONFIG_LASTUPDATEKEY) ?? '0', 10);
const t = Math.floor(new Date().getTime() / 3600000);
if (!!sd && sd_lastupdate === t) {
  startApp(JSON.parse(sd));
} else {
  fetch('./assets/config.json?t=' + t)
    .then((response: Response) => response.json())
    .then((config) => {
      sessionStorage.setItem(APP_CONFIG_KEY, JSON.stringify(config));
      sessionStorage.setItem(APP_CONFIG_LASTUPDATEKEY, t.toString());
      startApp(config);
    });
}
