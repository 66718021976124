import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AppConfig } from './app-config.model';
import { APP_CONFIG_KEY, APP_CONFIG_LASTUPDATEKEY } from '../app.consts';
import { of } from 'rxjs';
import { enableProdMode } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(private httpClient: HttpClient) {}

  public static init(environment: any, config: AppConfig): void {
    if (!config) return;

    environment.api.url = config.apiUrl || environment.api.url;
    environment.api.securityKey = config.apiSecurityKey || environment.api.securityKey;

    environment.rulesUrl = config.rulesUrl || environment.rulesUrl;
    environment.baseImgOnError = config.baseImgOnError || environment.baseImgOnError;
    if (config.globalize) {
      environment.globalize.currency = config.globalize.currency || environment.globalize.currency;
      if (config.globalize.language) {
        environment.globalize.language.default = config.globalize.language.default ?? environment.globalize.language.default;
        environment.globalize.language.available = config.globalize.language.available ?? environment.globalize.language.available;
        environment.globalize.language.cultureMap = config.globalize.language.cultureMap ?? environment.globalize.language.cultureMap;
      }
    }

    if (config.app) {
      environment.app.showUpdatePrompt = config.app.showUpdatePrompt ?? environment.app.showUpdatePrompt;
      environment.app.showInstallPrompt = config.app.showInstallPrompt ?? environment.app.showInstallPrompt;
      environment.app.checkNewVersionInterval = config.app.checkNewVersionInterval ?? environment.app.checkNewVersionInterval;
      environment.app.displayWelcomePage = config.app.displayWelcomePage ?? environment.app.displayWelcomePage;
    }

    environment.tips = config.tips || environment.tips;
    if (config.brand) {
      environment.brand.fullLogo = config.brand.fullLogo || environment.brand.fullLogo;
      environment.brand.subLogo = config.brand.subLogo || environment.brand.subLogo;
    }
    if (config.payment) {
      environment.payment.provider = config.payment.provider ?? environment.payment.provider;
      environment.payment.giftcard = config.payment.giftcard ?? environment.payment.giftcard;
      environment.payment.channel = config.payment.channel ?? environment.payment.channel;
      environment.payment.allowedPaymentMethod = config.payment.allowedPaymentMethod ?? environment.payment.allowedPaymentMethod;
      environment.payment.paymentRedirectUrl = config.payment.paymentRedirectUrl ?? environment.payment.paymentRedirectUrl;
      environment.payment.autoSelectedPaymentMethod = config.payment.autoSelectedPaymentMethod ?? environment.payment.autoSelectedPaymentMethod ?? null;

      if (config.payment.giftCardPaymentTypeId) {
        environment.payment.giftCardPaymentTypeId = config.payment.giftCardPaymentTypeId.toLowerCase();
      }
    }
    if (config.features) {
      environment.features = Object.assign(environment.features, config.features);
    }

    if (config.theme) {
      environment.theme = config.theme ?? environment.theme;
    }

    if (config.order?.orderAgreementGroupId) {
      environment.order.orderAgreementGroupId = config.order.orderAgreementGroupId.toLowerCase();
    }

    if (environment.production) {
      enableProdMode();
      if (!environment.activeLogger) {
        // disable any console.log debugging statements in production mode
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        window.console.log = function () {};
        // disable any console.warn debugging statements in production mode
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        window.console.warn = function () {};
        // window.console.error = function () { };
      }
    }
  }

  load(environment: any) {
    const sd = sessionStorage.getItem(APP_CONFIG_KEY);
    const sd_lastupdate = parseInt(sessionStorage.getItem(APP_CONFIG_LASTUPDATEKEY) ?? '0', 10);
    const t = Math.floor(new Date().getTime() / 3600000);
    if (!!sd && sd_lastupdate === t) {
      return of(JSON.parse(sd)).pipe(tap((config: AppConfig) => AppConfigService.init(environment, config)));
    } else {
      return this.httpClient.get<AppConfig>('./assets/config.json').pipe(
        tap((config: AppConfig) => {
          sessionStorage.setItem(APP_CONFIG_KEY, JSON.stringify(config));
          sessionStorage.setItem(APP_CONFIG_LASTUPDATEKEY, t.toString());
          return AppConfigService.init(environment, config);
        })
      );
    }
  }
}
