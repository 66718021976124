export class CinemaHeadApiModel {
  id: string;

  name: string;

  groupId: string;

  city: string;

  province: string;

  street: string;

  latitude: number;

  longitude: number;

  phone: string;
}
