import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserLoginRequestModel } from '../models/request/user-login.request.model';
import { UserLoginResponseModel } from '../models/response/user-login.response.model';
import { UserTokenRequestModel } from '../models/request/user-token.request.model';
import { UserTokenResponseModel } from '../models/response/user-token.response.model';
import { UserAuthRequestModel } from '../models/request/user-auth.request.model';
import { UserApiModel } from '../models/api-model/user/user.api.model';
import { UserSetpasswordRequestModel } from '../models/request/user-setpassword.request.model';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { ErrorHelper } from '@dinein-lib/shared';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  public static cacheBuster$ = new Subject<void>();
  public static cacheModifier$ = new Subject<any>();

  constructor(private http: HttpClient) {}

  public login(request: UserLoginRequestModel): Observable<UserLoginResponseModel> {
    return this.http.post<UserLoginResponseModel>('/user/login', request).pipe(
      catchError((error) => {
        throw ErrorHelper.getError(error);
      }),
      map((result) => result as UserLoginResponseModel)
    );
  }

  public loginByToken(request: UserTokenRequestModel): Observable<UserTokenResponseModel> {
    return this.http.post<UserTokenResponseModel>('/user/token', request);
  }

  public doAuth(authProvider: string, request: UserAuthRequestModel): Observable<UserLoginResponseModel> {
    return this.http.post<UserLoginResponseModel>('/user/auth/' + authProvider, request);
  }

  public doAuthByToken(authProvider: string, token: string, state: string = null): Observable<UserLoginResponseModel> {
    const provider = authProvider.toLowerCase();
    const body = {
      token: token,
      state: state,
    };

    return this.http.post<UserLoginResponseModel>('/user/auth/' + provider, body);
  }

  public getAuthRedirect(authProvider: string, redirectUrl: string): Observable<string> {
    const options = {};
    if (redirectUrl) {
      options['params'] = {};
      options['params']['redirectUrl'] = redirectUrl;
    }

    return this.http.get('/user/auth/' + authProvider, options).pipe(map((result) => result['redirectUrl']));
  }

  public remindPassword(email: string): Observable<any> {
    return this.http.post(`/user/passwordtoken`, { email: email });
  }

  public setPassword(request: UserSetpasswordRequestModel): Observable<any> {
    return this.http.put(`/user/password`, request);
  }

  public getCurrent(token: string): Observable<UserApiModel> {
    const params = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.http.get<UserApiModel>('/user', params);
  }

  public authMethod(): Observable<string[]> {
    return this.http.get<string[]>('/user/auth/method');
  }

  @Cacheable({
    cacheBusterObserver: UserHttpService.cacheBuster$,
    storageStrategy: LocalStorageStrategy,
    maxAge: 15000,
  })
  public getAccountItemsViaApiModel(): Observable<any> {
    return this.http.get('/user/accountItems');
  }
}
