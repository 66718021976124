<ng-container *ngIf="display === 'offer' && readyToUse">
  <div class="header header-rollup header-fixed" [class.with-query]="hasQuery">
    <nav class="navbar navbar-toggleable-md navbar-light bg-faded">
      <app-logo class="navbar-brand" [hide]="hasQuery"></app-logo>
      <button mat-raised-button color="primary" (click)="openModal(callForServiceTemplate)"
              *ngIf="env.features.callForService">
        {{ "button.call-for-service" | translate }}
      </button>
    </nav>

    <mat-toolbar>
      <ng-container *ngIf="!hasQuery; else back">
        <button mat-icon-button [routerLink]="['/scanner']">
          <mat-icon>qr_code</mat-icon>
        </button>
      </ng-container>
      <div [style.width.%]="100">
        <app-search-form fxFlex [query]="queryChanges | async"
                         (search)="searchService.search($event.query)"></app-search-form>
      </div>
    </mat-toolbar>

    <ng-template #back>
      <button mat-icon-button (click)="searchService.search('')">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </ng-template>
  </div>

  <div class="container">
    <ng-container *ngIf="groupsChanges | async as agregation">
      <app-search-result #searchResult fxFlex [groups]="agregation.groups"
                         [orderItems]="orderItems"></app-search-result>
    </ng-container>
  </div>

  <div *ngIf="totalItems > 0" class="footer footer-extra row">
    <div class="col-12">
      <button class="action-button mx-0" mat-raised-button color="primary" [routerLink]="['/order']">
        <span class="d-flex flex-row justify-content-between">
          <span class="width:70%; ml-3">
            {{
              "app.page.order.items"
                | translate : { count: totalItems }
                | translateSelector : totalItems
            }}
          </span>
          <span class="title-price mr-3">
            {{ totalValue | money }}
          </span>
        </span>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="display === 'scaner'">
  <div (click)="runScanner()">
    <div class="qr-scan-header">
      <app-logo></app-logo>
      <div class="qr-code"></div>
      <p>{{ "app.page.scanner.placeholder" | translate }}</p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="display === 'welcome'">
  <div>
    <div class="qr-scan-header">
      <app-logo></app-logo>

      <p class="welcome" *ngIf="cinema"
         innerHTML="{{ 'app.page.product.welcome-in' | translate : { cinema_name: cinema.name } }}">

      </p>
    </div>
    <div class="row footer footer-extra">
      <div class="col-6">
        <button type="button" class="action-button mx-0" mat-raised-button (click)="runScanner()">
          {{ "button.scan-qr-code" | translate }} <mat-icon>qr_code</mat-icon>
        </button>
      </div>
      <div class="col-6">
        <button type="button" class="action-button mx-0" mat-raised-button color="primary" (click)="goNext()">
          {{ "button.confirm" | translate }} <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #callForServiceTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ "app.components.call-for-service.title" | translate }}
    </h4>
    <button type="button" mat-icon-button (click)="modalRef?.hide()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-body">
    {{ "app.components.call-for-service.message" | translate }}
  </div>
</ng-template>