import { AbstractViewModel } from '../../abstract.view.model';
import { CardTypeApiModel } from '../../api-model/card/card-type.api.model';
import { UserCardTypeViewModel } from '../user-history/card-type/user-card-type.view.model';
import { CardTypeBatchViewModel } from './card-type-batch.view.model';
import { CardTypeGraphicTemplateViewModel } from './card-type-graphic-template.view.model';
import { CardTypeMembershipExtensionFeeViewModel } from './card-type-membership-extension-fee.view.model';
import { CardTypeMembershipUpgradeFeeViewModel } from './card-type-membership-upgrade-fee.view.model';
import { CardTypeTypeViewModel } from './card-type-type.view.model';
import { CardTypeValueViewModel } from './card-type-value.view.model';

export class CardTypeViewModel extends AbstractViewModel<CardTypeApiModel> {
  public id: string = null;
  public name: string = null;
  public issueFee: number = null;
  public graphic: string = null;
  public canBeIssued: boolean = null;
  public membershipNewFee: number = null;
  public reservationsAllowed: boolean = null;
  public types: CardTypeTypeViewModel[];
  public graphicTemplateList: CardTypeGraphicTemplateViewModel[];
  public membershipExtensionFeeList: CardTypeMembershipExtensionFeeViewModel[];
  public membershipUpgradeFeeList: CardTypeMembershipUpgradeFeeViewModel[];
  public valueList: CardTypeValueViewModel[];
  public batchList: CardTypeBatchViewModel[];

  constructor(apiModel: CardTypeApiModel = new CardTypeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.issueFee = this.apiModel.issueFee;
    this.graphic = this.apiModel.graphic;
    this.canBeIssued = this.apiModel.canBeIssued;
    this.membershipNewFee = this.apiModel.membershipNewFee;
    this.reservationsAllowed = this.apiModel.reservationsAllowed;
    this.types = this.apiModel.types ? this.apiModel.types.map((type) => new CardTypeTypeViewModel(type)) : [];
    this.graphicTemplateList = this.apiModel.graphicTemplateList
      ? this.apiModel.graphicTemplateList.map((graphicTemplate) => new CardTypeGraphicTemplateViewModel(graphicTemplate))
      : [];
    this.membershipExtensionFeeList = this.apiModel.membershipExtensionFeeList
      ? this.apiModel.membershipExtensionFeeList.map((extension) => new CardTypeMembershipExtensionFeeViewModel(extension))
      : [];
    this.membershipUpgradeFeeList = this.apiModel.membershipUpgradeFeeList
      ? this.apiModel.membershipUpgradeFeeList.map((upgrade) => new CardTypeMembershipUpgradeFeeViewModel(upgrade))
      : [];
    this.valueList = this.apiModel.valueList ? this.apiModel.valueList.map((value) => new CardTypeValueViewModel(value)) : [];
    this.batchList = this.apiModel.batchList ? this.apiModel.batchList.map((batch) => new CardTypeBatchViewModel(batch)) : [];
  }

  toApiModel(): CardTypeApiModel {
    return undefined;
  }

  toUserCardTypeViewModel(extra?: UserCardTypeViewModel): UserCardTypeViewModel {
    const model = Object.assign(extra ?? new UserCardTypeViewModel(), {
      id: this.id,
      typeName: this.name,
      typeId: this.id,
      graphic: this.graphic,
      graphicTemplateList: this.graphicTemplateList.map((item) => item.toUserCardTypeTemplateViewModel()),
      types: this.types.map((item) => item.toUserCardTypeTypeViewModel()),
      canBeIssued: this.batchList.some((item) => item.canBeIssued),
    });
    return model;
  }
}
