import { CardItemApiModel } from './card-item/card-item.api.model';
import { CardTypeItemApiModel } from './card-type-item/card-type-item.api.model';
import { DefaultExtraFeeApiModel } from './default-extra-fee/default-extra-fee.api.model';
import { FbItemApiModel } from './fb-item/fb-item.api.model';
import { OrderMembershipPriceApiModel } from './order-membership-price.api.model';
import { PaymentMethodApiModel } from './payment-method/payment-method.api.model';
import { ScreeningItemApiModel } from './screening-item/screening-item.api.model';
import { VoucherItemApiModel } from './voucher-item/voucher-item.api.model';

export class OrderApiModel {
  id: string;

  bookingId: string;

  dateEntry: string;

  dateTimeToLive: string;

  status: number;

  userFirstName: string;

  userLastName: string;

  userPhone: string;

  userEmail: string;

  screeningItems: ScreeningItemApiModel[] = [];

  cardTypeItems: CardTypeItemApiModel[] = [];

  voucherItems: VoucherItemApiModel[] = [];

  fbItems: FbItemApiModel[] = [];

  cardItems: CardItemApiModel[] = [];

  externalUserId: string;

  cardId: string;

  paymentMethods: PaymentMethodApiModel[] = [];

  valueToPay: number;

  totalValue: number;

  totalNetValue: number;

  totalTaxValue: number;

  defaultPriceLevelTotalValue: number;

  taxId: string;

  orderNumber: string;

  totalEarnedPoints: number;

  totalRedemptionPoints: number;

  defaultExtraFees: DefaultExtraFeeApiModel[] = [];

  membershipPrices: OrderMembershipPriceApiModel[] = [];
}
