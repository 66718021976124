export function navbarScroll() {
  const el = document.getElementsByClassName('header-rollup');
  if (el) {
    const header = el[0];
    if (header) {
      if (window.scrollY > 10) {
        header.classList.add('rollup');
      } else {
        header.classList.remove('rollup');
      }
    }
  }
}

export function blockBack() {
  history.pushState(null, '', location.href);
  window.history.replaceState({}, '', '/');
  window.onpopstate = function () {
    history.go(1);
  };
}
