import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppComponent, slider } from '@dinein-lib/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slider],
})
export class CustomAppComponent extends AppComponent implements OnInit, OnDestroy {}
