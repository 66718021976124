import { AbstractViewModel } from '../../abstract.view.model';
import { ScreenColApiModel } from '../../api-model/screen/screen-col.api.model';
import { IScreenColModel } from '../../interfaces';

export class ScreenColViewModel extends AbstractViewModel<ScreenColApiModel> implements IScreenColModel {
  id: string;
  coordinate: number;

  constructor(apiModel: ScreenColApiModel = new ScreenColApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.coordinate = this.apiModel.coordinate;
  }

  toApiModel(): ScreenColApiModel {
    return undefined;
  }

  override toString(): string {
    return this.coordinate.toString();
  }
}
