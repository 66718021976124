export class VoucherPositionApiModel {
  ticketId: string;

  ticketName: string;

  articleId: string;

  articleName: string;

  price: number;

  newPrice: number;

  discount: number;
}
