import { AbstractViewModel } from './abstract.view.model';
import {
  CateringArticleGroupResponseModel,
  CateringArticleItemModifierResponseModel,
  CateringArticleModifierResponseModel,
  CateringArticleResponseModel,
  CateringResponseModel,
} from './catering.response.model';
import { NutritionalInfoModel } from './nutritional-info.response.model';

export class CateringModel extends AbstractViewModel<CateringResponseModel> {
  public groups: Array<CateringArticleGroupModel> = new Array<CateringArticleGroupModel>();
  public articles: Array<CateringArticleModel> = new Array<CateringArticleModel>();

  constructor(apiModel: CateringResponseModel = new CateringResponseModel(), nutritionals?: NutritionalInfoModel[]) {
    super(apiModel);
    this.fromApiModel();
    this.mapNutritionals(nutritionals);
  }

  protected fromApiModel(): void {
    this.groups = this.apiModel.groupList ? this.apiModel.groupList.map((model) => new CateringArticleGroupModel(model)) : [];
    this.articles = this.apiModel.articleList ? this.apiModel.articleList.map((model) => new CateringArticleModel(model)) : [];
  }

  protected mapNutritionals(nutritionals?: NutritionalInfoModel[]): void {
    if (!nutritionals) {
      return;
    }

    this.articles.forEach((article) => {
      article.nutritionalInfo = nutritionals.find((item) => item.id === article.id);
    });
  }

  toApiModel(): CateringResponseModel {
    return undefined;
  }
}

export class CateringArticleGroupModel extends AbstractViewModel<CateringArticleGroupResponseModel> {
  id: string;
  name?: string;
  description?: string;
  parentGroupId?: string;
  graphicUrl?: string;

  constructor(apiModel: CateringArticleGroupResponseModel = new CateringArticleGroupResponseModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.parentGroupId = this.apiModel.parentGroupId;
    this.graphicUrl = this.apiModel.graphicUrl;
  }

  toApiModel(): CateringArticleGroupResponseModel {
    return undefined;
  }
}

export class CateringArticleModel extends AbstractViewModel<CateringArticleResponseModel> {
  id: string;
  parentGroupId?: string;
  name?: string;
  price: number;
  defaultPriceLevelPrice?: number;
  comboMinPrice?: number;
  pickupTime: number;
  taxRate: number;
  description?: string;
  isLocked: boolean;
  graphicUrl?: string;
  subArticleList: Array<CateringArticleModel> = new Array<CateringArticleModel>();
  replacementList: Array<CateringArticleModel> = new Array<CateringArticleModel>();
  modifierCollectionList: Array<CateringArticleModifierModel> = new Array<CateringArticleModifierModel>();

  nutritionalInfo?: NutritionalInfoModel;

  constructor(apiModel: CateringArticleResponseModel = new CateringArticleResponseModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.parentGroupId = this.apiModel.groupId;
    this.graphicUrl = this.apiModel.graphicUrl;
    this.price = this.apiModel.price;
    this.defaultPriceLevelPrice = this.apiModel.defaultPriceLevelPrice;
    this.comboMinPrice = this.apiModel.comboMinPrice;
    this.taxRate = this.apiModel.taxRate;
    this.isLocked = this.apiModel.isLocked;

    this.subArticleList = this.apiModel.subArticleList ? this.apiModel.subArticleList.map((model) => new CateringArticleModel(model)) : [];
    this.replacementList = this.apiModel.replacementList ? this.apiModel.replacementList.map((model) => new CateringArticleModel(model)) : [];
    this.modifierCollectionList = this.apiModel.modifierList ? this.apiModel.modifierList.map((model) => new CateringArticleModifierModel(model)) : [];
  }

  toApiModel(): CateringArticleResponseModel {
    return undefined;
  }
}

export class CateringArticleModifierModel extends AbstractViewModel<CateringArticleModifierResponseModel> {
  id: string;
  type?: string;
  name?: string;
  isRequired: boolean;
  multiChoice: boolean;
  multiChoiceMax: number;
  multiChoiceMin: number;
  separateItem: boolean;
  itemCollection: Array<CateringArticleItemModifierModel> = new Array<CateringArticleItemModifierModel>();

  constructor(apiModel: CateringArticleModifierResponseModel = new CateringArticleModifierResponseModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.type = this.apiModel.type;
    this.isRequired = this.apiModel.isRequired;
    this.multiChoice = this.apiModel.multiChoice;
    this.multiChoiceMax = this.apiModel.multiChoiceMax;
    this.multiChoiceMin = this.apiModel.multiChoiceMin;
    this.separateItem = this.apiModel.separateItem;

    this.itemCollection = this.apiModel.itemList.map((model) => new CateringArticleItemModifierModel(model));
  }

  toApiModel(): CateringArticleModifierResponseModel {
    return undefined;
  }
}

export class CateringArticleItemModifierModel extends AbstractViewModel<CateringArticleItemModifierResponseModel> {
  id: string;
  name?: string;
  description?: string;
  price: number;
  quantity: number;
  relatedItemList: Array<string> = new Array<string>();

  constructor(apiModel: CateringArticleItemModifierResponseModel = new CateringArticleItemModifierResponseModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.price = this.apiModel.price;
    this.quantity = this.apiModel.quantity;
    this.relatedItemList = this.apiModel.relatedItemList;
  }

  toApiModel(): CateringArticleItemModifierResponseModel {
    return undefined;
  }
}
