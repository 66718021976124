<div [formGroup]="searchForm">
  <mat-form-field appearance="standard" [style.width.%]="100">
    <mat-label>{{ "label.search" | translate }}</mat-label>
    <input matInput formControlName="query" placeholder="{{ 'label.ex' | translate : {value:'popcorn'} }}"
           (keyup.enter)="submitSearch()" autocomplete="off" />
    <mat-icon aria-hidden="false" aria-label="search icon" fontIcon="search" class="mx-3"></mat-icon>
  </mat-form-field>
  <!-- 
    <button mat-raised-button color="primary" fxFlex="0 0 100px" fxFlexAlign="center" style="margin-left: 16px;"
        (click)="submitSearch()">
        Search
    </button> -->
</div>