import { Component, Inject, Input } from '@angular/core';
import { TipType } from './tip-type.enum';
import { Tip } from './tip.model';
import { ENVIRONMENT_TOKEN } from '@dinein-lib/core';

@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
})
export class TipComponent {
  @Input() mode = 'input';
  @Input() min = 1;
  @Input() max = 100;
  @Input() disabled = false;

  tips: Tip[];
  public tipType: typeof TipType = TipType;

  selectedTip: Tip = null;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {
    this.tips = environment.tips.map((t) => new Tip(TipType[t.type], t.value));
  }

  onMove(event) {
    this.selectedTip.value = event.value;
  }
}
