import { AbstractViewModel } from '../../../abstract.view.model';
import { DefaultExtraFeeApiModel } from '../../../api-model/order/default-extra-fee/default-extra-fee.api.model';
import { DefaultExtraFeeMembershipPriceViewModel } from './default-extra-fee-membership-price.view.model';

export class DefaultExtraFeeViewModel extends AbstractViewModel<DefaultExtraFeeApiModel> {
  basketItemId: string;
  defaultExtraFeeId: string;
  defaultExtraFeeName: string;
  defaultExtraFeePrice: number;
  defaultExtraFeeEarnedPoints: number;
  defaultExtraFeeRedemptionPoints: number;
  membershipPrices: DefaultExtraFeeMembershipPriceViewModel[] = [];

  constructor(apiModel: DefaultExtraFeeApiModel = new DefaultExtraFeeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.basketItemId = this.apiModel.basketItemId;
    this.defaultExtraFeeId = this.apiModel.defaultExtraFeeId;
    this.defaultExtraFeeName = this.apiModel.defaultExtraFeeName;
    this.defaultExtraFeePrice = this.apiModel.defaultExtraFeePrice;
    this.defaultExtraFeeEarnedPoints = this.apiModel.defaultExtraFeeEarnedPoints;
    this.defaultExtraFeeRedemptionPoints = this.apiModel.defaultExtraFeeRedemptionPoints;
    this.membershipPrices = this.apiModel.membershipPrices
      ? this.apiModel.membershipPrices.map((membershipPrice) => new DefaultExtraFeeMembershipPriceViewModel(membershipPrice))
      : [];
  }

  toApiModel(): DefaultExtraFeeApiModel {
    return undefined;
  }
}
