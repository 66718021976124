export class CardApiModel {
  id: string;

  cardTypeId: string;

  name: string;

  description: string;

  message: string;

  picture: string;

  number: string;

  flgSubscription: boolean;

  type: number;

  valueBalance: number;

  pointsBalance: number;

  expiryDate: string;

  typeDescriptions: string[];

  nextPointsToExpire: number;

  nextPointsExpiryDate: string;

  collectedPoints: number;
}
