import { AfterViewInit, Component, Input, TemplateRef } from '@angular/core';
import { CinemaViewModel, ScreeningDetailsViewModel, TableViewModel } from '@dinein-lib/restapi-plugin';
import { OrderStateService } from '../../services/order.state.service';

@Component({
  selector: 'app-screening-details',
  templateUrl: './screening-details.component.html',
  styleUrls: ['./screening-details.component.scss'],
})
export class ScreeningDetailsComponent implements AfterViewInit {
  @Input() public customPosterTemplate!: TemplateRef<any>;
  @Input() public customContentTemplate!: TemplateRef<any>;
  @Input() public customSeatsTemplate!: TemplateRef<any>;

  public details: ScreeningDetailsViewModel;
  public cinema: CinemaViewModel;
  public table: TableViewModel;

  constructor(private orderStateService: OrderStateService) {}

  ngAfterViewInit(): void {
    this.details = this.orderStateService.getScreeningInfo();
    this.cinema = this.orderStateService.getCinema();
    this.table = this.orderStateService.getTable();
  }
}
