import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  CateringAggregationArticleModifierItemViewModel,
  CateringAggregationArticleModifierViewModel,
  CateringAggregationArticleViewModel,
} from '@dinein-lib/restapi-plugin';

@Injectable({
  providedIn: 'root',
})
export class ArticleBuilderService {
  private selectedModifierItemList: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
  private selectedModifierItemListSubject = new ReplaySubject<Map<string, Map<string, boolean>>>(1);
  public selectedModifierItem$ = this.selectedModifierItemListSubject.asObservable();
  private selectedSubArticleList: Map<number, Map<string, Map<string, boolean>>> = new Map<number, Map<string, Map<string, boolean>>>();
  private selectedSubArticleListSubject = new ReplaySubject<Map<number, Map<string, Map<string, boolean>>>>(1);
  public selectedSubArticleList$ = this.selectedSubArticleListSubject.asObservable();

  public initialize(article: CateringAggregationArticleViewModel): void {
    article.modifierArticleList.forEach((modifier) => {
      this.resetSelectedModifierItem(modifier.id, modifier.itemCollection);
    });

    article.subArticleList.forEach((subArticle, index) => {
      this.resetSelectedSubArticle(subArticle.id, subArticle.replacementList, index);
    });

    this.selectedSubArticleListSubject.next(this.selectedSubArticleList);
    this.selectedModifierItemListSubject.next(this.selectedModifierItemList);
  }

  /**
   * Marks all modifier items as not selected
   */
  public resetSelectedModifierItem(modifierId: string, modifierItemList: Array<CateringAggregationArticleModifierItemViewModel>): void {
    const selectedModifierItemMap = new Map<string, boolean>();

    modifierItemList.forEach((item) => {
      selectedModifierItemMap.set(item.id, false);
    });

    this.selectedModifierItemList.set(modifierId, selectedModifierItemMap);
  }

  public resetSelectedSubArticle(subArticleId: string, replacementList: Array<CateringAggregationArticleViewModel>, index: number): void {
    if (!this.isBaseIngredient(replacementList)) {
      const replacementArticleMap = new Map<string, boolean>();
      replacementList.forEach((item) => {
        replacementArticleMap.set(item.id, false);
      });

      const subArticleMap = new Map<string, Map<string, boolean>>().set(subArticleId, replacementArticleMap);
      this.selectedSubArticleList.set(index, subArticleMap);
    }
  }

  private isBaseIngredient(replacementList: Array<CateringAggregationArticleViewModel>): boolean {
    return replacementList.length === 0;
  }

  public switchModifierItemSelectionState(
    modifier: CateringAggregationArticleModifierViewModel,
    modifierItem: CateringAggregationArticleModifierItemViewModel,
    fc?: FormControl
  ): void {
    const selectedModifier = this.selectedModifierItemList.get(modifier.id);
    const selectedModifierItem = selectedModifier?.has(modifierItem.id) ? selectedModifier.get(modifierItem.id) : false;

    if (!modifier.multiChoice && !selectedModifierItem) {
      this.resetSelectedModifierItem(modifier.id, modifier.itemCollection);
    }

    if (this.selectedModifierItemList.get(modifier.id) && this.selectedModifierItemList.get(modifier?.id).has(modifierItem.id)) {
      const selectedCount = Array.from(this.selectedModifierItemList.get(modifier?.id).entries())
        .map(([key, value]) => value)
        .filter((v) => v);
      const isSelectedMaximumItems = modifier.multiChoiceMax === selectedCount.length;

      if (!modifier.multiChoice) {
        if (selectedModifierItem) {
          if (!modifier.isRequired) {
            this.selectedModifierItemList.get(modifier?.id).set(modifierItem.id, false);
            fc?.setValue(false);
          }
        } else {
          this.selectedModifierItemList.get(modifier?.id).set(modifierItem.id, true);
        }
      } else {
        if (selectedModifierItem) {
          this.selectedModifierItemList.get(modifier?.id).set(modifierItem.id, false);
        } else if (isSelectedMaximumItems) {
          fc?.setValue(false);
        } else {
          this.selectedModifierItemList.get(modifier.id).set(modifierItem.id, true);
        }
      }
    } else {
      this.selectedModifierItemList.get(modifier?.id).set(modifierItem.id, true);
    }

    this.selectedModifierItemListSubject.next(this.selectedModifierItemList);
  }

  public switchSubArticleSelectionState(
    subArticle: CateringAggregationArticleViewModel,
    subArticleReplacer: CateringAggregationArticleViewModel,
    index?: number
  ): void {
    console.log('selected item:', subArticleReplacer, index);

    this.resetSelectedSubArticle(subArticle.id, subArticle.replacementList, index);

    console.log(subArticle.id, subArticleReplacer.id);
    if (subArticle.id !== subArticleReplacer.id) {
      this.selectedSubArticleList.get(index).get(subArticle.id).set(subArticleReplacer.id, true);
    }

    console.log(this.selectedSubArticleList);
    this.selectedSubArticleListSubject.next(this.selectedSubArticleList);
  }
}
