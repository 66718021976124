import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, iif, map, noop, of, switchMap, tap } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDataProvider } from '../data-providers/user.data-provider';
import { TokenStorageService } from './token-storage.service';
import { AuthStateModel } from '../models/auth.state.model';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { UserTokenResponseModel } from '../models/response/user-token.response.model';
import { UserApiModel } from '../models/api-model/user/user.api.model';
import { UserLoginRequestModel } from '../models/request/user-login.request.model';
import { UserLoginResponseModel } from '../models/response/user-login.response.model';
import { SocialAuthService } from '../modules/auth/socialauth.service';
import { POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS } from '../injection.tokens';
import { IRestApiOptions } from '../interfaces/restapi-options';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  public state$: Observable<AuthStateModel>;
  public state = new BehaviorSubject<AuthStateModel>(null);
  private jwtHelperService: JwtHelperService;

  constructor(
    @Inject(POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS) protected options: IRestApiOptions,
    protected userDataProvider: UserDataProvider,
    protected tokenStorageService: TokenStorageService,
    protected socialAuthService: SocialAuthService
  ) {
    this.jwtHelperService = new JwtHelperService();
    this.state$ = this.state.asObservable();
  }

  retrieveUserToken() {
    if (localStorage.getItem(this.options.storageKey.token)) {
      this.setState(JSON.parse(localStorage.getItem(this.options.storageKey.token)) as AuthStateModel);
    }
  }

  public setState(state: AuthStateModel) {
    if (state !== null) {
      localStorage.setItem(this.options.storageKey.token, JSON.stringify(instanceToPlain(state)));
      this.state.next(state);
    }
  }

  public getToken(): string {
    return this.state.value?.token && !this.jwtHelperService.isTokenExpired(this.state.value.token) ? this.state.value?.token : null;
  }

  public userIsLoggedAndTokenIsValid(): boolean {
    return this.isLogged() && !this.jwtHelperService.isTokenExpired(this.state.value.token);
  }

  public refreshToken(): Observable<UserTokenResponseModel> {
    const user = this.state.value.user;
    return this.userDataProvider
      .loginByToken({
        client_id: user.id,
        refresh_token: this.state.value.refreshToken,
        grant_type: 'refresh_token',
      })
      .pipe(
        tap((response) => {
          this.setState(this.makeAuthStateModel(user, response));
        })
      );
  }

  public getUser(): UserApiModel {
    return this.state.value !== null ? this.state.value.user : null;
  }

  public setUser(user: UserApiModel) {
    this.state?.value ? (this.state.value.user = user) : noop();
    this.state.next(this.state.value);
  }

  public isLogged(): boolean {
    return this.state.value !== null;
  }

  isLogged$(): Observable<boolean> {
    return this.state$.pipe(
      map((m) => {
        return m && !this.jwtHelperService.isTokenExpired(m.token);
      })
    );
  }

  public login(requestModel: UserLoginRequestModel) {
    return this.userDataProvider.login(requestModel).pipe(switchMap((data) => iif(() => data !== null, this.handleToken(data), of(null))));
  }

  public logout() {
    this.state.next(null);
    this.socialAuthService.signOut();
  }

  public handleToken(response: UserLoginResponseModel) {
    return this.userDataProvider.get(response.token).pipe(
      tap((user) => {
        this.setState(this.makeAuthStateModel(user, response));
      })
    );
  }

  private makeAuthStateModel(user, response) {
    return Object.create({
      token: response.token,
      refreshToken: response.refresh_token,
      user: instanceToPlain(user),
    }) as AuthStateModel;
  }
}
