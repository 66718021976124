import { Injectable } from '@angular/core';
import { Observable, Subscription, map, mergeMap, of } from 'rxjs';
import { OrderStateModel } from '../models/order.state.model';
import { CardHelper } from './card.helper';
import { CardDataProvider, AuthStateService, CardViewModel } from '@dinein-lib/restapi-plugin';
import { OrderStateService } from '@dinein-lib/core';

@Injectable({
  providedIn: 'root',
})
export class OrderHelper {
  private orderState: OrderStateModel;
  private orderStateSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private cardDataProvider: CardDataProvider,
    private cardHelper: CardHelper,
    private authStateService: AuthStateService,
    private orderStateService: OrderStateService
  ) {
    this.orderStateSubscription = this.orderStateService.state$.subscribe((orderState) => {
      this.orderState = orderState;
    });
  }

  public updateOrderMembershipCard(): Observable<CardViewModel> {
    if (this.authStateService.userIsLoggedAndTokenIsValid()) {
      return this.cardHelper.findMembership(this.orderState.cinemaId).pipe(
        mergeMap((membership) => {
          if (this.orderState?.order && membership !== null && membership.card !== null) {
            return this.cardDataProvider.assignCardToOrder(this.orderState.cinemaId, this.orderState.order.id, membership.card.id).pipe(
              map((res) => {
                this.orderStateService.setOrder(res);
                this.orderStateService.setMembershipCard(membership.card);
                return membership.card;
              })
            );
          } else {
            return of(null);
          }
        })
      );
    }

    return of(null);
  }

  ngOnDestroy(): void {
    if (this.orderStateSubscription !== Subscription.EMPTY) {
      this.orderStateSubscription.unsubscribe();
      this.orderStateSubscription = Subscription.EMPTY;
    }
  }
}
