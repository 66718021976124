export class SeatGroupApiModel {
  seatIds: string[];

  id: string;

  ico: string;

  color: string;

  name: string;

  description: string;

  type: string;

  displayMode: number;

  salesMode: number;
}
