import { AbstractViewModel } from '../../../abstract.view.model';
import { SeatApiModel } from '../../../api-model/screen/seat/seat.api.model';
import { ISeatModel } from '../../../interfaces';

export class SeatViewModel extends AbstractViewModel<SeatApiModel> implements ISeatModel {
  id: string;
  symbol: string;
  kind: string;
  colId: string;
  rowId: string;
  groupId: string;

  rowNumber: string;

  constructor(apiModel: SeatApiModel = new SeatApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.symbol = this.apiModel.symbol;
    this.kind = this.apiModel.kind;
    this.colId = this.apiModel.colId;
    this.rowId = this.apiModel.rowId;
    this.groupId = this.apiModel.groupId;
  }

  toApiModel(): SeatApiModel {
    return undefined;
  }

  override toString() {
    return this.symbol;
  }
}
