import { AbstractViewModel } from '../../abstract.view.model';
import { CardTypeMembershipExtensionFeeApiModel } from '../../api-model/card/card-type-membership-extension-fee.api.model';

export class CardTypeMembershipExtensionFeeViewModel extends AbstractViewModel<CardTypeMembershipExtensionFeeApiModel> {
  public feeId: string = null;
  public name: string = null;
  public extensionTime: number = null;
  public extensionType: number = null;
  public feeValue: number = null;

  constructor(apiModel: CardTypeMembershipExtensionFeeApiModel = new CardTypeMembershipExtensionFeeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.feeId = this.apiModel.feeId;
    this.name = this.apiModel.name;
    this.extensionTime = this.apiModel.extensionTime;
    this.extensionType = this.apiModel.extensionType;
    this.feeValue = this.apiModel.feeValue;
  }

  toApiModel(): CardTypeMembershipExtensionFeeApiModel {
    return undefined;
  }
}
